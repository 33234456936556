import React from 'react'
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import { useMarketContext } from 'contexts/MarketContext'
import { i18n, useTranslation } from 'lib/i18n'
import dynamic from 'next/dynamic'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'
import LogoImage from 'components/Logotype/LogoImage'
import MarketSwitcher from 'components/MarketSwitcher/MarketSwitcher'
import WaveWrapper from 'components/WaveWrapper/WaveWrapper'
import Wrapper from 'components/Wrapper/Wrapper'
import useLanguage from 'hooks/useLanguage'

import styles from './Footer.module.css'

const NewsletterSignup = dynamic(
  () => import('components/NewsletterSignup/NewsletterSignup'),
)

type FooterProps = {
  hideNewsletterSignup?: boolean
}

const Footer: React.FC<FooterProps> = ({ hideNewsletterSignup = false }) => {
  const { t } = useTranslation()
  const { market, detectedMarket } = useMarketContext()
  const language = useLanguage()

  const pickedMarket = detectedMarket
    ? `
      Market: ${detectedMarket.market},
      Lang: ${detectedMarket.language},
      i18n: ${i18n.language}
      Detected by: ${detectedMarket.detectedBy},
      Suggested by: ${detectedMarket.suggestedBy}
    `
    : null

  const marketId = market.selectedMarket.id
  const { instagram, facebook, businessPage, blogPage } =
    market.selectedMarket?.config || {}
  const businessPageLink =
    businessPage && (businessPage as string)[language.code]
  const blogPageLink = blogPage && (blogPage as string)[language.code]

  return (
    <footer className={styles.base}>
      <WaveWrapper top>
        <Wrapper>
          <div className={styles.wrapper}>
            <Row justify="space-between">
              <Col xs={24} md={10} lg={8}>
                {!hideNewsletterSignup && (
                  <section className={styles.sectionNewsletter}>
                    <NewsletterSignup />
                  </section>
                )}
              </Col>

              <Col xs={24} md={10} lg={8}>
                <section className={styles.footerSectionEnroll}>
                  <Link href="/enroll" prefetch={false}>
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <CtaButton ghost block size="large" type="secondary">
                        {t('footer.enroll', 'Enroll as Profile')}
                      </CtaButton>
                    }
                  </Link>
                </section>

                <section className={styles.footerSection}>
                  <nav>
                    {businessPageLink && (
                      <Link href={businessPageLink} prefetch={false}>
                        <a className={styles.navLink}>
                          {t('sideMenu.businessPage', 'For business')}
                        </a>
                      </Link>
                    )}
                    <Link href="/about" prefetch={false}>
                      <a className={styles.navLink}>
                        {t('footer.aboutUs', 'About Us')}
                      </a>
                    </Link>
                    <Link href="/values" prefetch={false}>
                      <a className={styles.navLink}>
                        {t('footer.values', 'Our Values')}
                      </a>
                    </Link>
                    {marketId === 'de' && (
                      <Link href="/impressum" prefetch={false}>
                        <a className={styles.navLink}>Impressum</a>
                      </Link>
                    )}
                    {blogPageLink && (
                      <Link href={blogPageLink} prefetch={false}>
                        <a className={styles.navLink}>
                          {t('footer.blog', 'Blog')}
                        </a>
                      </Link>
                    )}
                    <Link href="/faq" prefetch={false}>
                      <a className={styles.navLink}>
                        {t('footer.helpAndContact', 'Help & Contact')}
                      </a>
                    </Link>
                  </nav>
                </section>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              className={styles.lineItem}
            >
              <Col span={4} xs={12}>
                <nav>
                  <a
                    className={styles.socialLink}
                    href={`https://www.instagram.com/${instagram}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    className={styles.socialLink}
                    href={`https://www.facebook.com/${facebook}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </nav>
              </Col>
              <Col span={4} xs={12}>
                <MarketSwitcher showText />
              </Col>
            </Row>
            <Row
              align="middle"
              justify="space-between"
              className={styles.lineItem}
            >
              <Col span={4} xs={9}>
                <Link href="/">
                  <a>
                    <LogoImage alt="memmo logotype" size="xs" variant="light" />
                  </a>
                </Link>
              </Col>
              <Col span={4} xs={15}>
                <div className={styles.copyright}>
                  <span title={pickedMarket}>&copy;</span> 2019 -{' '}
                  {new Date().getFullYear()} Meetly AB
                </div>
              </Col>
            </Row>

            <Row className={styles.lineItem}>
              <nav className={styles.miscLinks}>
                <Link href="/privacy-policy">
                  <a>{t('footer.privacyPolicy', 'Privacy Policy')}</a>
                </Link>
                <Link href="/terms-of-service">
                  <a>{t('footer.termsOfService', 'Terms of Service')}</a>
                </Link>
              </nav>
            </Row>
          </div>
        </Wrapper>
      </WaveWrapper>
    </footer>
  )
}

export default Footer
