import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconHeartCircleCheck = createIcon({
  path: (
    <g>
      <path
        d="M13.3594 4.25781L11.2891 6.5625L9.10156 4.25781C7.77344 2.89062 5.66406 2.8125 4.41406 3.86719L4.375 3.90625C2.77344 5.27344 2.69531 7.77344 4.14062 9.29688L11.25 16.6016L11.5234 16.3281C11.7188 16.9922 11.9922 17.6172 12.3438 18.1641L12.1484 18.3984C11.6406 18.9062 10.8203 18.9062 10.3516 18.3984L2.77344 10.5859C0.585938 8.32031 0.703125 4.57031 3.16406 2.46094C5.07812 0.78125 7.92969 0.9375 9.92188 2.46094C10.1172 2.61719 10.2734 2.8125 10.4688 2.96875L11.25 3.78906L11.9922 2.96875C12.1875 2.77344 12.3828 2.61719 12.5781 2.46094C14.5312 0.9375 17.3438 0.820312 19.3359 2.46094C21.0156 3.94531 21.5625 6.17188 21.0547 8.16406C20.4688 7.89062 19.8828 7.73438 19.2578 7.61719C19.5703 6.28906 19.1797 4.84375 18.125 3.90625C16.7578 2.77344 14.6875 2.92969 13.3594 4.25781ZM23.75 14.375C23.75 17.5 21.2109 20 18.125 20C15 20 12.5 17.5 12.5 14.375C12.5 11.2891 15 8.75 18.125 8.75C21.2109 8.75 23.75 11.2891 23.75 14.375ZM19.8438 12.6953L17.5 15.0781L16.3672 13.9453C16.1328 13.7109 15.7031 13.7109 15.4688 13.9453C15.2344 14.1797 15.2344 14.6094 15.4688 14.8438L17.0312 16.4062C17.2656 16.6406 17.6953 16.6406 17.9297 16.4062L20.7422 13.5938C20.9766 13.3594 20.9766 12.9297 20.7422 12.6953C20.5078 12.4609 20.0781 12.4609 19.8438 12.6953Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 24 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconHeartCircleCheck
