import React, { forwardRef } from 'react'
import { useMarketContext } from 'contexts/MarketContext'
import { currentLanguageCodeVar, currentMarketIdVar } from 'graphql/reactive'
import NextLink from 'next/link'

import usePath from 'hooks/usePath'

const isAbsolute = (href: string) => /^(http|https):\/\//.test(href)

type ForwardedLinkProps = {
  children: any
  href?: any
  as?: string
  scroll?: boolean
  passHref?: boolean
  prefetch?: boolean
}

const ForwardedLink = (props: ForwardedLinkProps, ref: any) => {
  const path = usePath()
  // eslint-disable-next-line prefer-const
  let { children, href, as, scroll = true, passHref, ...nextProps } = props
  const { detectedMarket } = useMarketContext()

  const hrefValue = typeof href === 'string' ? href : href.pathname

  if (isAbsolute(hrefValue)) {
    if (children?.type !== 'a') {
      return <a href={href}>{children}</a>
    }

    return React.cloneElement(children, { href })
  }

  if (!/^\//.test(hrefValue) && !['/', ''].includes(hrefValue)) {
    return new Error(
      `Invalid prop ${href} supplied to ForwardedLink. hrefValue should always have a leading slash.`,
    )
  }

  const marketId = currentMarketIdVar()
  const languageId = currentLanguageCodeVar()

  const localizedHref = () => {
    if (path.isLocalized || !detectedMarket) {
      if (typeof href === 'string') {
        return { href: `/${marketId}/${languageId}${href}` }
      }
      return {
        href: {
          ...href,
          pathname: !as
            ? `/${marketId}/${languageId}${href.pathname}`
            : `/[market]/[language]${href.pathname}`,
        },
      }
    }

    return { href }
  }

  const localizedAs = () => {
    if (as) {
      if (path.isLocalized || !detectedMarket) {
        return { as: `/${marketId}/${languageId}${as}` }
      }
      return { as }
    }

    return {}
  }

  return (
    <NextLink
      {...localizedHref()}
      {...localizedAs()}
      {...nextProps}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      scroll={scroll}
      passHref={passHref}
      shallow
    >
      {children}
    </NextLink>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Link = forwardRef(ForwardedLink)

export default Link
