import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRocket = createIcon({
  path: (
    <g>
      <path
        d="M16.9375 6.625C16.9375 7.52344 16.2344 8.1875 15.375 8.1875C14.4766 8.1875 13.8125 7.52344 13.8125 6.625C13.8125 5.76562 14.4766 5.0625 15.375 5.0625C16.2344 5.0625 16.9375 5.76562 16.9375 6.625ZM1.89844 12.25C1.58594 12.25 1.27344 12.0938 1.11719 11.7812C0.921875 11.5078 0.921875 11.1562 1.11719 10.8438L3.14844 7.40625C3.65625 6.54688 4.59375 6 5.57031 6H8.77344C8.89062 5.84375 8.96875 5.72656 9.08594 5.57031C12.2891 0.84375 17.0547 0.6875 19.8672 1.23438C20.3359 1.3125 20.6875 1.66406 20.7656 2.13281C21.3125 4.94531 21.1562 9.71094 16.4297 12.9141C16.2734 13.0312 16.1172 13.1094 16 13.2266V16.4297C16 17.4062 15.4531 18.3438 14.5938 18.8516L11.1562 20.8828C10.8438 21.0391 10.4922 21.0781 10.2188 20.8828C9.90625 20.7266 9.71094 20.4141 9.71094 20.0625V16.3125C9.39844 16.4688 9.08594 16.5859 8.8125 16.7031C8.38281 16.8984 7.83594 16.7812 7.48438 16.4297L5.49219 14.4766C5.14062 14.0859 5.02344 13.5781 5.25781 13.1094C5.33594 12.875 5.45312 12.5625 5.60938 12.25H1.89844ZM19.0078 2.99219C16.5469 2.64062 13.0703 3.03125 10.6094 6.625C9.4375 8.38281 7.95312 11.625 7.13281 13.4609L8.5 14.8281C10.3359 14.0469 13.6172 12.5625 15.375 11.3516C18.9688 8.92969 19.3594 5.45312 19.0078 2.99219Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 22',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconRocket
