import { gql } from '@apollo/client'

const meetingCardFragment = gql`
  fragment meetingCardFragment on PublicCmsItemMeetingCard {
    id
    title
    description
    buttonText
    linkUrl
  }
`

export default meetingCardFragment
