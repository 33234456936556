import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconInfo = createIcon({
  path: (
    <path
      d="M20 0C8.972 0 0 8.972 0 20C0 31.028 8.972 40 20 40C31.028 40 40 31.028 40 20C40 8.972 31.028 0 20 0ZM21.5 29.5C21.5 30.328 20.828 31 20 31C19.172 31 18.5 30.328 18.5 29.5V18.5C18.5 17.672 19.172 17 20 17C20.828 17 21.5 17.672 21.5 18.5V29.5ZM20 14C18.895 14 18 13.105 18 12C18 10.895 18.895 10 20 10C21.105 10 22 10.895 22 12C22 13.105 21.105 14 20 14Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 40 40',
})

export default IconInfo
