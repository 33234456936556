import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCreditCard = createIcon({
  path: (
    <path
      d="M8.5 9C4.92806 9 2 11.9281 2 15.5V32.5C2 36.0719 4.92806 39 8.5 39H39.5C43.0719 39 46 36.0719 46 32.5V15.5C46 11.9281 43.0719 9 39.5 9H8.5ZM8.5 12H39.5C41.4501 12 43 13.5499 43 15.5V17H5V15.5C5 13.5499 6.54994 12 8.5 12ZM5 21H43V32.5C43 34.4501 41.4501 36 39.5 36H8.5C6.54994 36 5 34.4501 5 32.5V21ZM31.5 29C31.3012 28.9972 31.1039 29.0339 30.9194 29.108C30.735 29.1822 30.5671 29.2922 30.4255 29.4318C30.284 29.5713 30.1716 29.7376 30.0949 29.921C30.0181 30.1044 29.9786 30.3012 29.9786 30.5C29.9786 30.6988 30.0181 30.8956 30.0949 31.079C30.1716 31.2624 30.284 31.4287 30.4255 31.5682C30.5671 31.7078 30.735 31.8178 30.9194 31.892C31.1039 31.9661 31.3012 32.0028 31.5 32H37.5C37.6988 32.0028 37.8961 31.9661 38.0806 31.892C38.265 31.8178 38.4329 31.7078 38.5745 31.5682C38.716 31.4287 38.8284 31.2624 38.9051 31.079C38.9819 30.8956 39.0214 30.6988 39.0214 30.5C39.0214 30.3012 38.9819 30.1044 38.9051 29.921C38.8284 29.7376 38.716 29.5713 38.5745 29.4318C38.4329 29.2922 38.265 29.1822 38.0806 29.108C37.8961 29.0339 37.6988 28.9972 37.5 29H31.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 48 48',
})

export default IconCreditCard
