import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTicket = createIcon({
  path: (
    <g>
      <path
        d="M5.25 7.5C5.25 6.83594 5.79688 6.25 6.5 6.25H16.5C17.1641 6.25 17.75 6.83594 17.75 7.5V12.5C17.75 13.2031 17.1641 13.75 16.5 13.75H6.5C5.79688 13.75 5.25 13.2031 5.25 12.5V7.5ZM15.875 8.125H7.125V11.875H15.875V8.125ZM22.75 5V8.125C21.6953 8.125 20.875 8.98438 20.875 10C20.875 11.0547 21.6953 11.875 22.75 11.875V15C22.75 16.4062 21.6172 17.5 20.25 17.5H2.75C1.34375 17.5 0.25 16.4062 0.25 15V11.875C1.26562 11.875 2.125 11.0547 2.125 10C2.125 8.98438 1.26562 8.125 0.25 8.125V5C0.25 3.63281 1.34375 2.5 2.75 2.5H20.25C21.6172 2.5 22.75 3.63281 22.75 5ZM2.125 6.75781C3.21875 7.42188 4 8.63281 4 10C4 11.4062 3.21875 12.6172 2.125 13.2812V15C2.125 15.3516 2.39844 15.625 2.75 15.625H20.25C20.5625 15.625 20.875 15.3516 20.875 15V13.2812C19.7422 12.6172 19 11.4062 19 10C19 8.63281 19.7422 7.42188 20.875 6.75781V5C20.875 4.6875 20.5625 4.375 20.25 4.375H2.75C2.39844 4.375 2.125 4.6875 2.125 5V6.75781Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 23 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconTicket
