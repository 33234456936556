import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMustache = createIcon({
  path: (
    <g>
      <path
        d="M9.10156 4.375C7.8125 4.375 6.60156 4.92188 5.66406 5.82031C4.57031 6.91406 2.77344 6.99219 1.60156 5.9375L1.52344 5.89844C1.44531 5.78125 1.32812 5.70312 1.17188 5.70312C1.13281 5.66406 1.09375 5.66406 1.01562 5.66406C0.976562 5.66406 0.9375 5.625 0.898438 5.625C0.742188 5.66406 0.625 5.66406 0.507812 5.74219C0.429688 5.78125 0.351562 5.82031 0.273438 5.89844C0.078125 6.05469 0 6.32812 0 6.5625V7.89062C0 12.1875 3.4375 15.625 7.73438 15.625C9.375 15.625 11.0156 15.1172 12.3438 14.1016L12.5 13.9844L12.6172 14.1016C13.9453 15.1172 15.5859 15.625 17.2656 15.625C21.5234 15.625 25 12.1875 25 7.89062V6.5625C25 6.32812 24.8828 6.05469 24.6875 5.89844C24.5312 5.74219 24.2969 5.66406 24.0625 5.625H24.0234C23.9062 5.66406 23.8281 5.66406 23.75 5.70312C23.6328 5.74219 23.5156 5.78125 23.4375 5.85938L23.3594 5.9375C22.1875 6.95312 20.3906 6.91406 19.2969 5.82031C18.3984 4.92188 17.1484 4.375 15.8594 4.375H15.8203C14.6484 4.375 13.5156 4.80469 12.6562 5.54688L12.5 5.66406L12.3438 5.54688C11.4453 4.80469 10.3125 4.375 9.14062 4.375H9.10156ZM23.0859 8.24219C22.9297 11.3281 20.3516 13.75 17.2656 13.75C15.9766 13.75 14.7656 13.3594 13.75 12.5781L13.0469 12.0703C12.6953 11.8359 12.2656 11.8359 11.9141 12.0703L11.25 12.5781C10.2344 13.3594 8.98438 13.75 7.73438 13.75C4.60938 13.75 2.03125 11.3281 1.875 8.24219C3.59375 8.90625 5.625 8.51562 6.99219 7.14844C7.53906 6.5625 8.32031 6.25 9.10156 6.25H9.14062C9.88281 6.25 10.5859 6.52344 11.1328 6.99219L11.875 7.61719C12.2266 7.89062 12.7344 7.89062 13.0859 7.61719L13.8281 6.99219C14.375 6.52344 15.0781 6.25 15.8203 6.25H15.8594C16.6406 6.25 17.3828 6.5625 17.9688 7.14844C19.3359 8.51562 21.3672 8.90625 23.0859 8.24219Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconMustache
