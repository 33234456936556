import type { Event } from '@bugsnag/js'

import getMarketTrackingProperties from './utils/getMarketTrackingProperties'

const trackSystemErrorEncountered = async (
  bugsnagReport: Event,
): Promise<void> => {
  const { systemErrorEncountered } = await import('./sdk')
  const { errors } = bugsnagReport

  errors.forEach((error) => {
    systemErrorEncountered({
      ...getMarketTrackingProperties(),
      bugsnagSessionId: '',
      errorPage: bugsnagReport.context,
      errorName: `${error.errorClass} - ${error.errorMessage}`,
      bugsnagId: null,
      errorId: null,
    })
  })
}

export default trackSystemErrorEncountered
