import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconDumbbell = createIcon({
  path: (
    <g>
      <path
        d="M23.75 8.75V5.9375C23.75 4.76562 22.7344 3.75 21.5625 3.75H20.3125C20.1953 3.75 20.0781 3.78906 20 3.78906V3.4375C20 2.26562 18.9844 1.25 17.8125 1.25H16.5625C15.3516 1.25 14.375 2.26562 14.375 3.4375V8.75H10.625V3.4375C10.625 2.26562 9.60938 1.25 8.4375 1.25H7.1875C5.97656 1.25 5 2.26562 5 3.4375V3.78906C4.88281 3.78906 4.76562 3.75 4.6875 3.75H3.4375C2.22656 3.75 1.25 4.76562 1.25 5.9375V8.75C0.546875 8.75 0 9.33594 0 10C0 10.7031 0.546875 11.25 1.21094 11.25L1.25 14.0625C1.25 15.2734 2.22656 16.25 3.4375 16.25H4.6875C4.76562 16.25 4.88281 16.25 5 16.25V16.5625C5 17.7734 5.97656 18.75 7.1875 18.75H8.4375C9.60938 18.75 10.625 17.7734 10.625 16.5625V11.25H14.375V16.5625C14.375 17.7734 15.3516 18.75 16.5625 18.75H17.8125C18.9844 18.75 20 17.7734 20 16.5625V16.25C20.0781 16.25 20.1953 16.25 20.3125 16.25H21.5625C22.7344 16.25 23.75 15.2734 23.75 14.0625V11.25C24.4141 11.25 25 10.7031 25 10C25 9.33594 24.4141 8.75 23.75 8.75ZM4.6875 14.375H3.4375C3.24219 14.375 3.125 14.2578 3.125 14.0625V5.9375C3.125 5.78125 3.24219 5.625 3.4375 5.625H4.6875C4.84375 5.625 5 5.78125 5 5.9375V14.0625C5 14.2578 4.84375 14.375 4.6875 14.375ZM8.75 16.5625C8.75 16.7578 8.59375 16.875 8.4375 16.875H7.1875C6.99219 16.875 6.875 16.7578 6.875 16.5625V3.4375C6.875 3.28125 6.99219 3.125 7.1875 3.125H8.4375C8.59375 3.125 8.75 3.28125 8.75 3.4375V16.5625ZM18.125 16.5625C18.125 16.7578 17.9688 16.875 17.8125 16.875H16.5625C16.3672 16.875 16.25 16.7578 16.25 16.5625V3.4375C16.25 3.28125 16.3672 3.125 16.5625 3.125H17.8125C17.9688 3.125 18.125 3.28125 18.125 3.4375V16.5625ZM21.875 14.0625C21.875 14.2578 21.7188 14.375 21.5625 14.375H20.3125C20.1172 14.375 20 14.2578 20 14.0625V5.9375C20 5.78125 20.1172 5.625 20.3125 5.625H21.5625C21.7188 5.625 21.875 5.78125 21.875 5.9375V14.0625Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconDumbbell
