import React, { useState } from 'react'
import { CirclePlusIcon } from '@fluentui/react-icons-mdl2'
import cn from 'classnames'

import styles from './ModalCard.module.css'

export type ModalCardProps = {
  onClose?: () => void
  removeCloseButton?: boolean
  noRender?: boolean
  disablePageScroll?: boolean
}

const ModalCard: React.FC<ModalCardProps> = ({
  onClose,
  children,
  removeCloseButton,
  noRender,
  disablePageScroll,
}) => {
  const [animateOnClose, setAnimateOnClose] = useState(false)

  if (noRender) return null

  const onClick = () => {
    setAnimateOnClose(true)
    setTimeout(() => {
      if (onClose) onClose()
      setAnimateOnClose(false)
    }, 250)
  }

  return (
    <div
      className={cn(styles.modal, {
        [styles.modalAnimateOnClose]: animateOnClose,
        [styles.modalHidden]: animateOnClose,
      })}
    >
      {disablePageScroll && (
        <style global jsx>{`
          body {
            overflow: hidden;
          }
        `}</style>
      )}

      <button
        data-cy="modalCardCloseButton"
        className={styles.modalClose}
        type="button"
        onClick={onClick}
        aria-label="close"
      />

      <div
        className={cn(styles.container, {
          [styles.containerAnimateOnClose]: animateOnClose,
        })}
      >
        {!removeCloseButton && (
          <div
            className={styles.cardClose}
            onClick={onClick}
            role="button"
            data-cy="modalCloseButton"
            tabIndex={0}
          >
            <CirclePlusIcon />
          </div>
        )}

        {children}
      </div>
    </div>
  )
}

export { ModalCard }
