import React from 'react'
import cn from 'classnames'

import styles from './LogoImage.module.css'

export type LogoVariants = 'primary' | 'light' | 'dark'

export type LogoImageProps = {
  variant?: LogoVariants
  size?: 'xs' | 'sm' | 'md'
  alt?: string
}

const LogoImage: React.FC<LogoImageProps> = ({
  variant = 'primary',
  size = 'md',
  alt,
}) => (
  <div className={cn(styles[variant], styles[size])}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1089.01 134.75"
      preserveAspectRatio="xMidYMid meet"
    >
      {alt && <title>{alt}</title>}
      <path
        fill="currentColor"
        d="M167.76,17.32c-9-9.3-21-14.88-35.53-16.21-16.39-1.32-30,4-41,15.65-10.93-11.67-24.59-17-41-15.65C35.8,2.44,23.78,8,14.76,17.32,4.92,27.42,0,40.7,0,56.91v75.45H32.52V52.48a21.3,21.3,0,0,1,42.57-1c0,.12.07,80.87.07,80.87h32.2s0-80.75.06-80.87a21.3,21.3,0,0,1,42.57,1v79.88h32.53V56.91C182.52,40.7,177.6,27.42,167.76,17.32Z"
      />
      <path
        fill="currentColor"
        d="M503.23,17.32c-9-9.3-21.05-14.88-35.53-16.21-16.39-1.32-30.05,4-41,15.65-10.93-11.67-24.59-17-41-15.65C371.27,2.44,359.25,8,350.23,17.32c-9.84,10.1-14.76,23.38-14.76,39.59v75.45H368V52.48a21.3,21.3,0,0,1,42.57-1c0,.12.07,80.87.07,80.87h32.19s0-80.75.07-80.87a21.3,21.3,0,0,1,42.57,1v79.88H518V56.91C518,40.7,513.06,27.42,503.23,17.32Z"
      />
      <path
        fill="currentColor"
        d="M697.14,17.32c-9-9.3-21-14.88-35.53-16.21-16.39-1.32-30.05,4-41,15.65-10.93-11.67-24.59-17-41-15.65C565.18,2.44,553.16,8,544.14,17.32c-9.84,10.1-14.76,23.38-14.76,39.59v75.45H561.9V52.48a21.3,21.3,0,0,1,42.57-1c0,.12.07,80.87.07,80.87h32.19s0-80.75.07-80.87a21.3,21.3,0,0,1,42.57,1v79.88h32.52V56.91C711.89,40.7,707,27.42,697.14,17.32Z"
      />
      <path
        fill="currentColor"
        d="M260.84,103.66h-.55c-9.57,0-17.77-2.92-24.6-9a33.92,33.92,0,0,1-10.11-14.08h100a67.83,67.83,0,0,0,1.28-13.07A67.06,67.06,0,0,0,305.2,18c-.42-.38-.83-.76-1.25-1.12l-.21-.18A68.1,68.1,0,0,0,263,.32c-1.2-.07-2.4-.1-3.58-.11a67.27,67.27,0,0,0,.17,134.54c.81,0,1.61,0,2.42-.06,1.05,0,2.09-.1,3.13-.19l.51,0,1.8-.18,2-.25L270,134a67.34,67.34,0,0,0,52.38-42.24H287.89C280.21,99.18,271.83,103.38,260.84,103.66ZM236,39.64a36.87,36.87,0,0,1,23.51-8H260c9.29,0,16.94,2.65,23.5,8.23a33.89,33.89,0,0,1,9.29,11.43H226.4A33.21,33.21,0,0,1,236,39.64Z"
      />
      <path
        fill="currentColor"
        d="M990.48,63.33c-5.51-5.68-12.7-9-21.36-9.76-1-.08-2-.12-2.92-.12a28.76,28.76,0,0,0-20.92,8.63,28.76,28.76,0,0,0-20.92-8.63c-1,0-1.93,0-2.91.12-8.67.8-15.86,4.08-21.37,9.76-5.9,6.06-8.9,14.07-8.9,23.8v44.94h21.34V84.56A11.08,11.08,0,0,1,934.67,84c0,1,0,31,0,46.78v1.25h21.14v-1.25c0-15.79,0-45.79,0-46.78a11.08,11.08,0,0,1,22.15.52v47.51h21.34V87.13C999.38,77.4,996.38,69.39,990.48,63.33Z"
      />
      <path
        fill="currentColor"
        d="M825.19,118a33,33,0,0,1,27.64-32.55,65.11,65.11,0,0,0,2.48-18v-.15a67.23,67.23,0,1,0-134.46,0c0,.07,0,.13,0,.2s0,.14,0,.21A63.11,63.11,0,0,0,731.8,104a67.09,67.09,0,0,0,93.77,19A33.4,33.4,0,0,1,825.19,118Zm-37.51-15.87c-19.75,0-35.72-15.51-35.72-34.71s71.7-19.2,71.7,0C823.39,86.31,807.43,102.09,787.68,102.09Z"
      />
      <circle fill="currentColor" cx="858.19" cy="117.96" r="16.5" />
      <path
        fill="currentColor"
        d="M1089,93.72a40.7,40.7,0,1,0-2.48,14h-21.88c-4.45,4.33-9.3,6.76-15.67,6.93h-.31a20.58,20.58,0,0,1-14.25-5.23,19.71,19.71,0,0,1-5.85-8.16h59.73A41,41,0,0,0,1089,93.72ZM1034.58,77.6A21.35,21.35,0,0,1,1048.19,73h.32a20.08,20.08,0,0,1,13.61,4.77,19.57,19.57,0,0,1,5.38,6.62H1029A19.21,19.21,0,0,1,1034.58,77.6Z"
      />
    </svg>
  </div>
)

export default LogoImage
