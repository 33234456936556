import React from 'react'
import cn from 'classnames'

import Cart from 'components/Cart/Cart'
import Logotype from 'components/Logotype/Logotype'
import MarketSwitcher from 'components/MarketSwitcher/MarketSwitcher'
import NoSSR from 'components/NoSSR'
import SideNavButton from 'components/SideNavButton/SideNavButton'
import Wrapper from 'components/Wrapper/Wrapper'
import useResponsive from 'hooks/useResponsive'

import BusinessButton from './BusinessButton'
import SearchButton from './SearchButton'

import styles from './StickyNavigationBar.module.css'

type StickyNavigationBarProps = {
  darkContent?: boolean
  showStickyNavigation?: boolean
  visibleDesktopOnly?: boolean
}

const StickyNavigationBar: React.FC<StickyNavigationBarProps> = ({
  darkContent = false,
  showStickyNavigation = false,
  visibleDesktopOnly = false,
}) => {
  const { isMobile } = useResponsive()

  const logoVariant = darkContent ? 'dark' : 'light'

  return (
    <div
      data-cy="stickyHeader"
      className={cn(styles.base, {
        [styles.isVisible]: showStickyNavigation,
        [styles.visibleDesktopOnly]: visibleDesktopOnly,
      })}
    >
      <Wrapper className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <nav className={styles.topNav}>
            <SideNavButton dark={darkContent} />

            <div className={styles.logoContainer}>
              <Logotype size={isMobile ? 'sm' : 'md'} variant={logoVariant} />
            </div>

            <div className={styles.topNavFunctions}>
              <SearchButton dark={darkContent} />
              <BusinessButton dark={darkContent} />
              <MarketSwitcher />
              <NoSSR>
                <Cart dark={darkContent} />
              </NoSSR>
            </div>
          </nav>
        </div>
      </Wrapper>
    </div>
  )
}

export default StickyNavigationBar
