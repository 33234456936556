import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconVideoHD = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9 3C1.75684 3 0 4.75684 0 6.9V17.1C0 19.2432 1.75684 21 3.9 21H14.1C16.2432 21 18 19.2432 18 17.1V16.2902L22.6371 19.0723C22.7737 19.1542 22.9296 19.1983 23.0889 19.2003C23.2481 19.2022 23.4051 19.1619 23.5436 19.0834C23.6822 19.0049 23.7975 18.891 23.8777 18.7533C23.9578 18.6157 24 18.4593 24 18.3V5.7C24 5.57951 23.9759 5.46024 23.929 5.34926C23.8821 5.23828 23.8134 5.13784 23.7269 5.05391C23.6405 4.96998 23.538 4.90426 23.4257 4.86064C23.3134 4.81702 23.1935 4.7964 23.073 4.8C22.9192 4.80461 22.7691 4.84858 22.6371 4.92773L18 7.70977V6.9C18 4.75684 16.2432 3 14.1 3H3.9ZM3.57971 15V9.00244H4.89851V11.304H7.32611V9.00244H8.64491V15H7.32611V12.4128H4.89851V15H3.57971ZM10.9567 10.1112V13.8912H12.1327C12.3231 13.8912 12.5079 13.8604 12.6871 13.7988C12.8663 13.7372 13.0259 13.6364 13.1659 13.4964C13.3059 13.3508 13.4179 13.1632 13.5019 12.9336C13.5859 12.704 13.6279 12.424 13.6279 12.0936C13.6279 11.7912 13.5971 11.5196 13.5355 11.2788C13.4795 11.0324 13.3843 10.8224 13.2499 10.6488C13.1155 10.4752 12.9363 10.3436 12.7123 10.254C12.4939 10.1588 12.2223 10.1112 11.8975 10.1112H10.9567ZM9.63788 15V9.00244H12.2251C12.6115 9.00244 12.9699 9.06404 13.3003 9.18724C13.6363 9.31044 13.9247 9.49524 14.1655 9.74164C14.4119 9.98804 14.6023 10.296 14.7367 10.6656C14.8767 11.0352 14.9467 11.4692 14.9467 11.9676C14.9467 12.4044 14.8907 12.8076 14.7787 13.1772C14.6667 13.5468 14.4959 13.866 14.2663 14.1348C14.0423 14.4036 13.7595 14.6164 13.4179 14.7732C13.0819 14.9244 12.6843 15 12.2251 15H9.63788Z"
      fill="currentColor"
    />
  ),
  viewBox: '-3 -3 30 30',
})

export default IconVideoHD
