import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTwentyFourHours = createIcon({
  path: (
    <g>
      <path
        d="M12.4772 23.7742C12.4169 23.5539 12.3767 23.2934 12.3767 23.0531C12.3767 21.8311 13.0601 20.8896 14.447 20.8896C15.7534 20.8896 16.4167 21.7309 16.4167 22.6724C16.4167 23.5739 15.9745 24.255 14.8691 24.9161L12.7184 26.1981C10.3868 27.6004 9.66323 29.4634 9.64313 31.7471H19.291V29.3633H13.04C13.241 28.8624 13.6631 28.5019 14.1656 28.1813L16.678 26.6589C18.286 25.6773 19.2106 24.3151 19.2106 22.6324C19.2106 20.3687 17.4217 18.4456 14.5073 18.4456C11.4722 18.4456 9.74363 20.5089 9.74363 22.7926C9.74363 23.1132 9.80392 23.5138 9.84412 23.6941L12.4772 23.7742Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5171 28.9626H25.6877V31.7471H28.4213V28.9626H30.5719V26.5988H28.4213V18.7461H25.0043L19.5171 26.0779V28.9626ZM25.6877 26.5988H21.9693L25.6877 21.6308V26.5988Z"
        fill="currentColor"
      />
      <path
        d="M35.4176 24.8059C34.4045 24.8059 33.8076 25.5631 33.7533 26.5367V31.7471H31.3474V18.694H33.7533V23.616C34.3322 22.9309 35.309 22.6424 36.1593 22.6424C38.4205 22.6424 39.4697 24.2109 39.4697 26.1581V31.7471H37.0637V26.5727C37.0637 25.5811 36.5753 24.8059 35.4176 24.8059Z"
        fill="currentColor"
      />
      <path
        d="M18 9H30C34.3965 9 38.3513 10.8915 41.0949 13.9051L43.2182 11.7818C39.9299 8.22621 35.2249 6 30 6H18C8.05888 6 0 14.0589 0 24V26C0 35.9411 8.05888 44 18 44H28.5V41H18C9.71573 41 3 34.2843 3 26V24C3 15.7157 9.71573 9 18 9Z"
        fill="currentColor"
      />
      <path
        d="M34.8842 13.4789C34.3309 13.8357 34.3902 14.6629 34.9887 14.9372L44.5318 19.3102C45.0853 19.5638 45.7152 19.1577 45.7125 18.5488L45.667 8.05153C45.6641 7.39322 44.9351 6.9979 44.3818 7.35466L34.8842 13.4789Z"
        fill="currentColor"
      />
      <path
        d="M45 25.5H48V26C48 26.5955 47.9711 27.1843 47.9146 27.765L44.9287 27.4744C44.9758 26.9901 45 26.4983 45 26V25.5Z"
        fill="currentColor"
      />
      <path
        d="M44.3582 30.357L47.2294 31.2269C46.8845 32.3653 46.4298 33.456 45.8778 34.4867L43.2332 33.0702C43.693 32.2118 44.0713 31.304 44.3582 30.357Z"
        fill="currentColor"
      />
      <path
        d="M41.5968 35.5148L43.9147 37.4193C43.1663 38.3302 42.3302 39.1663 41.4193 39.9147L39.5148 37.5968C40.2747 36.9724 40.9724 36.2747 41.5968 35.5148Z"
        fill="currentColor"
      />
      <path
        d="M37.0702 39.2332L38.4866 41.8778C37.456 42.4298 36.3653 42.8845 35.2269 43.2294L34.357 40.3582C35.304 40.0713 36.2118 39.693 37.0702 39.2332Z"
        fill="currentColor"
      />
      <path
        d="M31.4744 40.9287L31.765 43.9146C31.1843 43.9711 30.5955 44 30 44H28.5V41H30C30.4983 41 30.9901 40.9758 31.4744 40.9287Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 48 48',
})

export default IconTwentyFourHours
