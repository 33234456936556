import React, { forwardRef } from 'react'

const fallbackIcon = {
  path: (
    <g stroke="currentColor" strokeWidth="1.5">
      <path
        strokeLinecap="round"
        fill="none"
        d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      />
      <path
        fill="currentColor"
        strokeLinecap="round"
        d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      />
      <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
    </g>
  ),
  viewBox: '0 0 24 24',
}

export type IconProps = React.SVGAttributes<SVGElement>

// eslint-disable-next-line react/display-name
export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { viewBox: setViewBox, focusable = false, children, ...rest } = props

  const shared = {
    ref,
    focusable,
  }

  const viewBox = setViewBox ?? fallbackIcon.viewBox
  const path = (children ?? fallbackIcon.path) as React.ReactNode

  return (
    <svg viewBox={viewBox} {...shared} {...rest}>
      {path}
    </svg>
  )
})

export default Icon
