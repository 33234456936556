import React, { useCallback, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { MarketContext } from 'contexts/MarketContext'
import {
  currentLanguageCodeVar,
  currentLanguageIdVar,
  currentMarketIdVar,
} from 'graphql/reactive'
import Cookies from 'lib/cookies'
import dayjs from 'lib/dayjs'
import { i18n } from 'lib/i18n'
import availableMarkets from 'lib/markets.json'
import { isServer } from 'lib/utils'
import dynamic from 'next/dynamic'
import Router from 'next/router'

import useDetectedMarket, {
  DETECTED_MARKET_QUERY,
} from 'hooks/useDetectedMarket'
import usePath from 'hooks/usePath'
import useSelectMarket from 'hooks/useSelectMarket'

const MarketSelectorUi = dynamic(() =>
  import('components/MarketSelector/MarketSelectorUi'),
)

function MarketSelector({ children, isErrorOr404 }) {
  const apolloClient = useApolloClient()
  const { detectedMarket } = useDetectedMarket()

  const selectMarket = useSelectMarket()

  const changeTranslationLanguage = async (language) => {
    if (!language) return
    if (i18n.language === language) return
    await i18n.changeLanguage(language)
  }

  const path = usePath()

  const [showSuggester, setShowSuggester] = useState(detectedMarket?.isMismatch)
  const [userChangesMarket, setUserChangesMarket] = useState(false)

  const [marketState, setMarketState] = useState(
    selectMarket({
      marketId: detectedMarket?.market,
      languageCode: detectedMarket?.language,
    }),
  )

  changeTranslationLanguage(marketState?.selectedLanguage?.id)

  const setCookies = ({ marketId, languageId }) => {
    Cookies.set('marketid', marketId, { expires: 365 })
    Cookies.set('languageid', languageId, { expires: 365 })
  }

  const routingUrl = useCallback(
    ({ keepPath, marketId, languageCode }) => {
      return keepPath
        ? `/${marketId}/${languageCode}${path.nakedPath}${path.queryParams}`
        : `/${marketId}/${languageCode}${path.queryParams}`
    },
    [path.nakedPath, path.queryParams],
  )

  const replaceMarket = useCallback(
    ({ marketId, languageId, languageCode, keepPath = false }) => {
      const url = routingUrl({ keepPath, marketId, languageCode })

      Router.replace(url).then(() => {
        window.scrollTo(0, 0)
        changeTranslationLanguage(languageId)
      })
    },
    [routingUrl],
  )

  const pushMarket = ({
    marketId,
    languageId,
    languageCode,
    keepPath = false,
  }) => {
    const url = routingUrl({ keepPath, marketId, languageCode })

    Router.push(url).then(() => {
      window.scrollTo(0, 0)
      changeTranslationLanguage(languageId)
    })
  }

  useEffect(() => {
    if (
      marketState.selectedLanguage &&
      !userChangesMarket &&
      !detectedMarket?.isCrawler
    ) {
      const { selectedLanguage } = marketState
      changeTranslationLanguage(selectedLanguage.id)
    }
  }, [marketState, detectedMarket, userChangesMarket])

  const setApolloState = ({ marketId, languageId, languageCode }) => {
    currentMarketIdVar(marketId)
    currentLanguageIdVar(languageId)
    currentLanguageCodeVar(languageCode)
  }

  if (marketState.selectedMarket) {
    const { selectedMarket, selectedLanguage } = marketState
    setApolloState({
      marketId: selectedMarket.id,
      languageId: selectedLanguage.id,
      languageCode: selectedLanguage.code,
    })

    dayjs.locale(selectedLanguage.dayjs)
    if (isServer()) changeTranslationLanguage(selectedLanguage.id)
  }

  const clearApolloCache = async () => {
    apolloClient.onClearStore(() => {
      apolloClient.cache.writeQuery({
        query: DETECTED_MARKET_QUERY,
        data: {
          detectedMarket,
        },
      })
    })
    await apolloClient.clearStore()
  }

  const handleMarketChange = async ({ marketId, languageId, push = true }) => {
    const market = selectMarket({ marketId, languageId })
    const { selectedLanguage } = market

    await clearApolloCache()

    setShowSuggester(false)
    setCookies({ marketId, languageId: selectedLanguage.id })
    setMarketState(market)

    if (push) {
      pushMarket({
        marketId,
        languageId: selectedLanguage.id,
        languageCode: selectedLanguage.code,
      })
    } else {
      replaceMarket({
        marketId,
        languageId: selectedLanguage.id,
        languageCode: selectedLanguage.code,
        keepPath: true,
      })
    }
  }

  const handleMarketKeep = () => {
    setCookies({
      marketId: marketState.selectedMarket.id,
      languageId: marketState.selectedLanguage.id,
    })
  }

  const setSelectedCurrency = (selectedCurrency) => {
    setMarketState({ ...marketState, selectedCurrency })
  }

  // Show market selector when we don't have a market
  if (
    (!marketState.selectedMarket && !detectedMarket?.market && !isErrorOr404) ||
    (path.isBase && userChangesMarket) ||
    (path.isBase && !detectedMarket) ||
    (path.isBase && detectedMarket?.isCrawler)
  ) {
    return (
      <MarketSelectorUi
        markets={availableMarkets}
        handleMarketChange={handleMarketChange}
      />
    )
  }

  return (
    <MarketContext.Provider
      value={{
        markets: availableMarkets,
        market: marketState,
        detectedMarket,
        userChangesMarket,
        showSuggester,
        setShowSuggester,
        handleMarketChange,
        handleMarketKeep,
        setUserChangesMarket,
        setSelectedCurrency,
      }}
    >
      {children}
    </MarketContext.Provider>
  )
}

export default MarketSelector
