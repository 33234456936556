import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRabbit = createIcon({
  path: (
    <g>
      <path
        d="M19.5312 16.4844L15.7812 11.7969C17.4609 11.3672 18.75 9.84375 18.75 8.04688C18.75 6.17188 17.4609 5.15625 17.0703 4.92188C17.8906 0.703125 15.6641 0 14.7656 0C14.1406 0 13.5547 0.273438 13.125 0.703125C12.6562 0.273438 12.1094 0 11.4453 0C10.4297 0 8.08594 0.898438 9.33594 5.625C9.375 5.78125 9.41406 5.9375 9.45312 6.09375C9.375 6.44531 9.375 6.75781 9.375 6.99219V8.35938C6.875 8.94531 4.88281 10.6641 3.86719 12.9297C3.59375 12.8906 3.35938 12.8125 3.125 12.8125C1.36719 12.8125 0 14.2188 0 15.9375C0 17.6953 1.36719 19.0625 3.125 19.0625C3.39844 19.0625 3.63281 19.0234 3.90625 18.9453C4.49219 19.6094 5.3125 20 6.25 20H12.5C13.3203 20 13.9844 19.4922 14.2578 18.75L14.5703 19.1406C14.9609 19.6875 15.625 20 16.3281 20H17.8125C18.5938 20 19.3359 19.5703 19.7266 18.9062C20.1562 18.0859 20.0391 17.1484 19.5312 16.4844ZM3.125 17.1875V17.2266C2.77344 17.2266 2.46094 17.0703 2.22656 16.8359C1.71875 16.3672 1.71875 15.5469 2.22656 15.0781C2.69531 14.6094 3.24219 14.7266 3.28125 14.7266C3.24219 14.8438 3.00781 15.9375 3.125 17.1875ZM17.8125 18.125H16.3281C16.2109 18.125 16.1328 18.0859 16.0547 18.0078L13.3203 14.6094L8.75 16.875H11.25C11.9141 16.875 12.5 17.4609 12.5 18.125H6.25C5.54688 18.125 5 17.5781 5 16.875V16.25C5 12.8125 7.77344 10.0391 11.25 10.0391L11.2109 7.03125C11.2109 6.64062 11.3281 6.36719 11.4453 6.09375C11.3281 5.82031 11.2109 5.50781 11.1328 5.19531C10.7031 3.55469 10.7812 2.10938 11.3281 1.95312C11.875 1.79688 12.6562 2.96875 13.0859 4.57031C13.5547 2.96875 14.3359 1.79688 14.8828 1.95312C15.4297 2.10938 15.5078 3.55469 15.0781 5.19531C15 5.39062 14.9609 5.625 14.8828 5.78125L16.0156 6.48438C16.5625 6.875 16.875 7.46094 16.875 8.08594C16.875 9.14062 15.9766 10 14.8828 10H13.75V12.2266L18.0469 17.6562C18.2031 17.8906 18.0469 18.125 17.8125 18.125ZM14.3359 7.5C14.3359 7.14844 14.0234 6.875 13.7109 6.875C13.3594 6.875 13.0859 7.14844 13.0859 7.5C13.0859 7.8125 13.3594 8.125 13.7109 8.125C14.0234 8.125 14.3359 7.85156 14.3359 7.5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 21 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconRabbit
