import { createContext } from 'react'
import { ExperimentClient } from '@amplitude/experiment-js-client'

export interface AmplitudeExperimentContextValue {
  experiment?: ExperimentClient
  loaded: boolean
  isVariantActive: (variantId: string, variantValue?: string) => boolean
  isControlActive: (variantId: string, defaultControl?: string) => boolean
  getVariantPayload: <T>(variantId: string) => T | null
  getVariantKey: (experimentId: string, controlKey?: string) => string | null
}

export const AmplitudeExperimentContext =
  createContext<AmplitudeExperimentContextValue | null>(null)
