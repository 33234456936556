import React, { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'lib/i18n'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import usePath from 'hooks/usePath'
import useRouter from 'hooks/useRouter'

import chevronBack from './chevronBack.svg'
import chevronBackDark from './chevronBackDark.svg'

import styles from './SideNavButton.module.css'

const SideMenu = dynamic(() => import('components/SideMenu/SideMenu'))

type NavButtonProps = {
  sideMenuOpen: boolean
  setSideMenuOpen: (value: boolean) => void
  dark?: boolean
}

type SideNavButton = Pick<NavButtonProps, 'dark'>

const NavButton: React.FC<NavButtonProps> = ({
  sideMenuOpen,
  setSideMenuOpen,
  children,
  dark,
}) => (
  <button
    className={cn(styles.navButtonContainer, {
      [styles.navButtonContainerDark]: dark,
    })}
    onClick={() => setSideMenuOpen(!sideMenuOpen)}
    type="button"
  >
    <div
      className={cn(styles.navButton, {
        [styles.navButtonOpen]: sideMenuOpen,
      })}
    >
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
    </div>
    {children}
  </button>
)

const BackButton = ({ dark }: { dark: boolean }) => {
  const router = useRouter()

  return (
    <button
      className={styles.backButton}
      onClick={() => router.back()}
      type="button"
    >
      <Image
        layout="fill"
        src={dark ? chevronBackDark : chevronBack}
        alt="countdown"
      />
    </button>
  )
}

const SideNavButton: React.FC<SideNavButton> = ({ dark = false }) => {
  const { t } = useTranslation()
  const { nakedPath } = usePath()
  const isCheckout =
    nakedPath.includes('product/') ||
    (nakedPath.startsWith('/checkout') && !nakedPath.endsWith('/completed'))
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const menuProps = {
    sideMenuOpen,
    setSideMenuOpen,
  }

  return (
    <>
      <SideMenu button={<NavButton {...menuProps} dark />} {...menuProps} />
      <div
        className={cn(styles.sideNavButton, {
          [styles.sideNavButtonOpen]: sideMenuOpen,
        })}
      >
        {isCheckout && (
          <span className={styles.sideNavButtonBack}>
            <BackButton dark={dark} />
          </span>
        )}

        <span
          className={cn({
            [styles.desktopOnly]: isCheckout,
          })}
        >
          <NavButton {...menuProps} dark={dark}>
            <span className={styles.sideNavButtonText}>
              {t('sideNavButton.menu', 'Menu')}
            </span>
          </NavButton>
        </span>
      </div>
    </>
  )
}

export default SideNavButton
