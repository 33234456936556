import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTickBox = createIcon({
  path: (
    <>
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#27292D" />
      <path
        d="M16.86 7.99635L16.86 7.99636C16.6983 8.00107 16.5448 8.06827 16.4316 8.1838L10.1246 14.4908L7.56805 11.9343C7.51087 11.8753 7.4426 11.8281 7.36715 11.7955C7.29082 11.7625 7.20868 11.7451 7.12553 11.7443C7.04239 11.7434 6.95991 11.7592 6.88293 11.7906C6.80594 11.822 6.73601 11.8685 6.67721 11.9273C6.61841 11.9861 6.57194 12.056 6.54051 12.133C6.50908 12.21 6.49333 12.2925 6.49417 12.3756C6.49502 12.4588 6.51245 12.5409 6.54543 12.6172C6.57804 12.6927 6.62521 12.761 6.68422 12.8181L9.68266 15.8166L9.68269 15.8166C9.7999 15.9338 9.95885 15.9996 10.1246 15.9996C10.2903 15.9996 10.4492 15.9338 10.5664 15.8166L10.5665 15.8166L17.3155 9.06758C17.4049 8.97996 17.466 8.86749 17.4907 8.74473C17.5156 8.62136 17.5027 8.49335 17.4536 8.37745C17.4046 8.26155 17.3216 8.16316 17.2157 8.09517C17.1098 8.02717 16.9859 7.99273 16.86 7.99635Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#27292D" />
    </>
  ),
  viewBox: '0 0 24 24',
  defaultProps: { fill: 'none', height: 24 },
})

export default IconTickBox
