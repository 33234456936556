import { gql } from '@apollo/client'
import businessReviewCardFragment from 'graphql/fragments/businessReviewCardFragment'
import cardProfileProductFragment from 'graphql/fragments/cardProfileProductFragment'
import coloredBannerFragment from 'graphql/fragments/coloredBannerFragment'
import fullPublicProfileProduct from 'graphql/fragments/fullPublicProfileProduct'
import heroWithImageFragment from 'graphql/fragments/heroWithImageFragment'
import meetingCardFragment from 'graphql/fragments/meetingCardFragment'
import merchCardFragment from 'graphql/fragments/merchCardFragment'

const publicCmsItemsFragment = gql`
  fragment publicCmsItemsFragment on PublicCmsItem {
    ... on PublicCmsItemProfileCollectionContent {
      id
      linkPath
      profileCollection {
        id
        slug
        publicCmsItem {
          id
          content
          ... on PublicCmsItemProfileCollectionContent {
            linkPath
          }
        }
        profileProducts(first: $profilesPerCollection) {
          totalCount
          pageSize
          pageInfo {
            hasNextPage
            pageNumber
          }
          edges {
            node {
              ...cardProfileProductFragment
            }
          }
        }
      }
    }

    ... on PublicCmsItemVideoRequestCardList {
      id
      videoRequests {
        edges {
          node {
            id
            ...cmsItemPublicVideoRequest
          }
        }
      }
    }

    ... on PublicCmsItemProfileCollectionTagList {
      id
      profileCollections {
        id
        slug
        publicCmsItem {
          id
          content
          ... on PublicCmsItemProfileCollectionContent {
            linkPath
            title
            color
          }
        }
      }
    }
    ... on PublicCmsItemCustomerReviews {
      id
      reviews {
        id
        date
        rating
        name
        content
      }
    }
    ... on PublicCmsItemLandingForm {
      id
      title
      subtitle
      type
      formId
      formType
      backgroundColor
    }
    ... on PublicCmsItemLandingIntro {
      id
      imageOne {
        id
        url
        width
        height
        meanBackgroundColorHex
      }
      imageTwo {
        id
        url
        width
        height
        meanBackgroundColorHex
      }
    }
    ... on PublicCmsItemLandingBoxSection {
      id
      video {
        id
        url(preset: VIDEO_DEFAULT)
      }
      boxes {
        id
        heading
        content
        icon {
          url
        }
      }
    }
    ... on PublicCmsItemLandingContentSection {
      id
      reviews {
        id
        date
        rating
        name
        content
      }
      video {
        id
        url(preset: VIDEO_DEFAULT)
      }
    }
    ... on PublicCmsItemLandingCtaSection {
      id
      image {
        id
        url
        width
        height
      }
    }
    ... on PublicCmsItemBlogPost {
      image {
        id
        url(format: { width: 300, height: 375, fit: crop })
      }
      title
      excerpt
      slug
      category {
        slug
      }
    }
    ... on PublicCmsItemLandingTextSection {
      id
      profileCollection {
        id
        slug
        profileProducts(first: $profilesPerCollection) {
          totalCount
          pageSize
          pageInfo {
            hasNextPage
            pageNumber
          }
          edges {
            node {
              ...cardProfileProductFragment
            }
          }
        }
      }
    }

    ... on PublicCmsItemProfileCollectionCampaign {
      profileCollection {
        id
        slug
        publicCmsItem {
          id
          content
          ... on PublicCmsItemProfileCollectionContent {
            linkPath
          }
        }
        profileProducts(first: 4) {
          totalCount
          pageSize
          pageInfo {
            hasNextPage
            pageNumber
          }
          edges {
            node {
              ...cardProfileProductFragment
            }
          }
        }
      }
    }

    ... on PublicCmsItemContentCardList {
      id
      type
      title
      contentCards {
        id
        type
        cardType
        title
        ... on PublicCmsItemContentCardGeneric {
          description
          tagline
          linkPath
          linkUrl
          color
          backgroundColor
          backgroundImage {
            id
            url(format: { width: 400, height: 754, fit: crop })
            width
            height
            meanBackgroundColorHex
          }
        }
        ... on PublicCmsItemContentCardVideoRequest {
          videoRequest {
            ...cmsItemPublicVideoRequest
          }
          backgroundImage {
            id
            url(format: { width: 400, height: 754, fit: crop })
            meanBackgroundColorHex
          }
        }
        ... on PublicCmsItemContentCardGenericSmall {
          linkPath
          backgroundImage {
            id
            url(format: { width: 324, height: 194, fit: crop })
            meanBackgroundColorHex
          }
        }
      }
    }

    ... on PublicCmsItemMerchCardList {
      id
      type
      title
      merchCards {
        ...merchCardFragment
      }
    }

    ... on PublicCmsItemMeetingCard {
      ...meetingCardFragment
    }

    ... on PublicCmsItemProfileCollectionRecentlyViewed {
      id
      type
    }

    ... on PublicCmsItemHeroStartPage {
      id
      type
    }

    ... on PublicCmsItemHeroWithImage {
      ...heroWithImageFragment
    }

    ... on PublicCmsItemBanner {
      id
      design
      pushToTop
      headline
      markets
      startTime
      endTime
      countdownTime
      linkPath
    }

    ... on PublicCmsItemDiscountCoupon {
      id
      design
      subject
      description
      coupon {
        code
        discountPercentage
        expiresAt
      }
    }

    ... on PublicCmsItemBusinessPageHero {
      id
      profiles {
        id
        name
        title
        slug
        hasB2bProduct
        profilePicture {
          id
          cardUrl: url(format: { width: 265, height: 458, fit: crop })
        }
      }
    }

    ... on PublicCmsItemBusinessSteps {
      id
      profilesList {
        id
        name
        slug
      }
    }

    ... on PublicCmsItemBusinessReviewCardCollection {
      id
      businessReviewCardList {
        ...businessReviewCardFragment
      }
    }

    ... on PublicCmsItemBusinessSuggestions {
      id
      suggestions {
        profile {
          id
          name
          title
          slug
          profilePicture {
            id
            cardUrl: url(format: { width: 265, height: 458, fit: crop })
          }
          profileVideo {
            url(preset: VIDEO_DEFAULT)
          }
        }
        testimonial
        customVideo {
          url(preset: VIDEO_DEFAULT)
        }
      }
    }

    ... on PublicCmsItemStatic {
      id
      component
    }

    ... on PublicCmsItemFaq {
      id
      faqList {
        question
        answer
      }
    }

    ... on PublicCmsItemColoredBanner {
      ...coloredBannerFragment
    }

    ... on PublicCmsItemScrollCardsList {
      id
      type
      content
      scrollCards {
        ... on PublicCmsItemColoredBanner {
          id
          type
          titleWeight
          titleSize
          title
          titleColor
          text
          textColor
          backgroundColor
          ctaButtonLink
          backgroundImage {
            id
            url
          }
        }
      }
    }
  }
  ${cardProfileProductFragment}
  ${merchCardFragment}
  ${meetingCardFragment}
  ${fullPublicProfileProduct}
  ${businessReviewCardFragment}
  ${coloredBannerFragment}
  ${heroWithImageFragment}
`

export default publicCmsItemsFragment
