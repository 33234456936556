import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from 'lib/nextConfig'

const DEV_BUGSNAG = process.env.DEV_BUGSNAG === 'true'

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: config.WEBAPP_BUGSNAG_API_KEY,
    appVersion: config.VERSION || 'no-version',
    releaseStage: config.RELEASE_STAGE,
    enabledReleaseStages:
      config.RELEASE_STAGE && (DEV_BUGSNAG || config.RELEASE_STAGE !== 'dev')
        ? [config.RELEASE_STAGE]
        : [],
    plugins: [new BugsnagPluginReact()],
    logger: config.IS_TEST_ENV ? null : console,
  })
}
