import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconEnvelope = createIcon({
  path: (
    <g>
      <path
        d="M2.5 4.375C2.14844 4.375 1.875 4.6875 1.875 5V5.89844L8.59375 11.4062C9.41406 12.0703 10.5469 12.0703 11.3672 11.4062L18.125 5.89844V5C18.125 4.6875 17.8125 4.375 17.5 4.375H2.5ZM1.875 8.32031V15C1.875 15.3516 2.14844 15.625 2.5 15.625H17.5C17.8125 15.625 18.125 15.3516 18.125 15V8.32031L12.5781 12.8516C11.0547 14.1016 8.90625 14.1016 7.42188 12.8516L1.875 8.32031ZM0 5C0 3.63281 1.09375 2.5 2.5 2.5H17.5C18.8672 2.5 20 3.63281 20 5V15C20 16.4062 18.8672 17.5 17.5 17.5H2.5C1.09375 17.5 0 16.4062 0 15V5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconEnvelope
