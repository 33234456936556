import React from 'react'
import { useMarketContext } from 'contexts/MarketContext'

import Link from 'components/Link/Link'

import LogoImage, { LogoImageProps } from './LogoImage'

const RawLogo: React.FC<LogoImageProps> = ({ ...props }) => {
  const marketContext = useMarketContext()
  const { selectedMarket: market } = marketContext.market

  const marketName = market ? market.name : ''

  return <LogoImage {...props} alt={`memmo - ${marketName}`} />
}

const Logotype: React.FC<{ noLink?: boolean } & LogoImageProps> = ({
  noLink = false,
  ...props
}) => {
  if (noLink) return <RawLogo {...props} />

  return (
    <Link href="/">
      <a data-cy="logo-link">
        <RawLogo {...props} />
      </a>
    </Link>
  )
}

export default Logotype
