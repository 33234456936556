import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconPen = createIcon({
  path: (
    <g>
      <path
        d="M20.3359 3.34375C21.1562 4.20312 21.1562 5.57031 20.3359 6.42969L8.10938 18.6562C7.64062 19.0859 7.09375 19.4375 6.50781 19.5938L1.78125 21C1.54688 21.0391 1.3125 21 1.15625 20.8438C1 20.6875 0.921875 20.4531 1 20.2188L2.40625 15.4922C2.5625 14.9062 2.91406 14.3594 3.34375 13.8906L15.5703 1.66406C16.4297 0.84375 17.7969 0.84375 18.6562 1.66406L20.3359 3.34375ZM14.3203 4.71094L17.2891 7.67969L19.4375 5.57031C19.7891 5.17969 19.7891 4.59375 19.4375 4.24219L17.7578 2.5625C17.4062 2.21094 16.8203 2.21094 16.4297 2.5625L14.3203 4.71094ZM13.4219 5.57031L4.24219 14.7891C3.92969 15.0625 3.69531 15.4531 3.57812 15.8438L2.52344 19.4766L6.15625 18.4219C6.54688 18.3047 6.9375 18.0703 7.21094 17.7578L16.3906 8.57812L13.4219 5.57031Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 22',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconPen
