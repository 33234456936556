import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'
import type { User } from 'apollo-gql'
import Cookies from 'lib/cookies'
import { setItem } from 'lib/localStorage'
import trackLoggedIn from 'lib/tracking/avo/trackLoggedIn'

import useCurrentUser from 'hooks/useCurrentUser'
import usePath from 'hooks/usePath'
import useRouter from 'hooks/useRouter'

type AuthContext = {
  isAuthenticated: boolean
  currentUser: User
  loginEmailPrefill: string
  setLoginEmailPrefill: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  error: ApolloError
}

const AuthContext = createContext<AuthContext>(undefined)

const LOGIN_PATH = '/auth/login'

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const router = useRouter()
  const { nakedPath } = usePath()
  const [loginEmailPrefill, setLoginEmailPrefill] = useState()

  const cookieToken = Cookies.get('tempToken')

  if (cookieToken) {
    setItem('token', cookieToken)
    Cookies.remove('tempToken')
  }

  const { currentUser, loading, error } = useCurrentUser()

  useEffect(() => {
    if (nakedPath.includes('/account') && !currentUser && !loading) {
      router.push(LOGIN_PATH)
    }

    const handleRouteChange = (url: string) => {
      if (url.includes('/account') && !currentUser && !loading) {
        router.push(LOGIN_PATH)
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [currentUser, loading, nakedPath, router])

  useEffect(() => {
    if (currentUser) {
      trackLoggedIn({ userId: currentUser.id })
    }
  }, [currentUser])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!currentUser,
        currentUser: currentUser as User,
        loginEmailPrefill,
        setLoginEmailPrefill,
        loading,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContext => useContext(AuthContext)

export { AuthProvider, useAuth }
