import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

const IconStarFull = createIcon({
  path: (
    <path
      d="M20.0098 2.92479e-05C19.7294 -0.00172106 19.4542 0.0751201 19.2153 0.221838C18.9764 0.368555 18.7834 0.579278 18.6582 0.830107L13.5059 11.1348L1.27149 13.0176C0.997641 13.0599 0.740863 13.1773 0.529632 13.3566C0.318402 13.536 0.160972 13.7703 0.0748037 14.0337C-0.011365 14.297 -0.022906 14.5791 0.0414604 14.8486C0.105827 15.1181 0.243585 15.3646 0.439459 15.5606L8.90235 24.0235L7.01758 36.2715C6.97542 36.5455 7.01002 36.8259 7.11754 37.0814C7.22506 37.337 7.4013 37.5577 7.62668 37.7192C7.85207 37.8807 8.11779 37.9765 8.39435 37.9961C8.67091 38.0156 8.94748 37.9582 9.19337 37.8301L20 32.1914L30.8066 37.8301C31.0525 37.9582 31.3291 38.0156 31.6057 37.9961C31.8822 37.9765 32.1479 37.8807 32.3733 37.7192C32.5987 37.5577 32.775 37.337 32.8825 37.0814C32.99 36.8259 33.0246 36.5455 32.9824 36.2715L31.0977 24.0235L39.5606 15.5606C39.7564 15.3646 39.8942 15.1181 39.9586 14.8486C40.0229 14.5791 40.0114 14.297 39.9252 14.0337C39.839 13.7703 39.6816 13.536 39.4704 13.3566C39.2592 13.1773 39.0024 13.0599 38.7285 13.0176L26.4941 11.1348L21.3418 0.830107C21.2181 0.582246 21.0281 0.373488 20.793 0.226973C20.5579 0.0804573 20.2868 0.00190707 20.0098 2.92479e-05Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 40 38',
})

const IconStarOutline = createIcon({
  path: (
    <path
      d="M19.5526 1.27729L19.5529 1.27673C19.5947 1.1931 19.659 1.12285 19.7387 1.07393C19.8183 1.02501 19.9101 0.999396 20.0035 0.999979L20.0098 -1.26964e-06L20.003 0.999976C20.0954 1.0006 20.1857 1.02679 20.2641 1.07564C20.3425 1.12449 20.4058 1.19408 20.4471 1.27673L20.4474 1.27729L25.5997 11.582L25.831 12.0445L26.342 12.1231L38.5758 14.0058C38.6671 14.02 38.7527 14.0591 38.8232 14.1189C38.8936 14.1787 38.9461 14.2568 38.9748 14.3446C39.0035 14.4324 39.0074 14.5265 38.9859 14.6163C38.9644 14.7062 38.9185 14.7883 38.8532 14.8537L30.3906 23.3163L30.0322 23.6747L30.1093 24.1755L31.9941 36.4236C32.0081 36.5149 31.9966 36.6084 31.9607 36.6936C31.9249 36.7788 31.8661 36.8524 31.791 36.9062C31.7158 36.9601 31.6273 36.992 31.535 36.9985C31.4428 37.0051 31.3506 36.9859 31.2687 36.9432L20.4626 31.3048L20 31.0635L19.5374 31.3048L8.73136 36.9432C8.64938 36.9859 8.55717 37.0051 8.46496 36.9985C8.37276 36.992 8.28417 36.9601 8.20902 36.9062C8.13388 36.8524 8.07512 36.7788 8.03928 36.6936C8.00343 36.6084 7.99189 36.5149 8.00595 36.4236L9.89072 24.1755L9.96779 23.6747L9.60946 23.3163L1.1468 14.8537C1.0815 14.7883 1.03557 14.7062 1.01411 14.6163C0.992648 14.5265 0.996496 14.4324 1.02522 14.3446C1.05395 14.2568 1.10644 14.1787 1.17686 14.1189C1.24729 14.0591 1.3329 14.02 1.4242 14.0058L13.658 12.1231L14.169 12.0445L14.4003 11.582L19.5526 1.27729Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  ),
  viewBox: '0 0 40 38',
})

export { IconStarFull as default, IconStarFull, IconStarOutline }
