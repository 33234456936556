import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconGift = createIcon({
  path: (
    <g>
      <path
        d="M10 3.35938L10.9375 1.75781C11.5625 0.664062 12.6953 0 13.9453 0H14.0625C15.9375 0 17.5 1.5625 17.5 3.4375C17.5 4.02344 17.3438 4.53125 17.1094 5H18.125C19.1406 5 20 5.85938 20 6.875V9.375C20 10.1953 19.4531 10.8984 18.75 11.1719V17.5C18.75 18.9062 17.6172 20 16.25 20H3.75C2.34375 20 1.25 18.9062 1.25 17.5V11.1719C0.507812 10.8984 0 10.1953 0 9.375V6.875C0 5.85938 0.820312 5 1.875 5H2.85156C2.61719 4.53125 2.5 4.02344 2.5 3.4375C2.5 1.5625 4.02344 0 5.9375 0H6.01562C7.26562 0 8.39844 0.664062 9.02344 1.75781L10 3.35938ZM11.25 6.875V9.375H18.125V6.875H11.25ZM8.75 6.875H1.875V9.375H8.75V6.875ZM8.75 11.25H3.125V17.5C3.125 17.8516 3.39844 18.125 3.75 18.125H8.75V11.25ZM11.25 18.125H16.25C16.5625 18.125 16.875 17.8516 16.875 17.5V11.25H11.25V18.125ZM14.0625 5C14.9219 5 15.625 4.33594 15.625 3.4375C15.625 2.57812 14.9219 1.875 14.0625 1.875H13.9453C13.3594 1.875 12.8516 2.1875 12.5391 2.69531L11.1719 5H14.0625ZM8.78906 5L7.42188 2.69531C7.10938 2.1875 6.60156 1.875 6.01562 1.875H5.9375C5.03906 1.875 4.375 2.57812 4.375 3.4375C4.375 4.33594 5.03906 5 5.9375 5H8.78906Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconGift
