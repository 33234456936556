import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCheckMarkUnfilled = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2.01078L5.63549 11L0 5.6636L2.04784 3.51969L5.50177 6.79032L11.8202 0L14 2.01078Z"
    />
  ),
  viewBox: '0 0 14 11',
  defaultProps: {
    height: 11,
  },
})

export default IconCheckMarkUnfilled
