import React from 'react'
import { lightGrey } from 'lib/theme'
import { base64 } from 'lib/utils'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'
import useResponsive from 'hooks/useResponsive'

import styles from './WaveWrapper.module.css'

interface WaveWrapperProps {
  top?: boolean
  bottom?: boolean
  inverted?: boolean
  backgroundColor?: string
  waveColor?: string
  hideBottom?: boolean
}

const WaveWrapper: React.FC<WaveWrapperProps> = ({
  top = false,
  bottom = false,
  inverted = false,
  backgroundColor = 'transparent',
  waveColor = lightGrey,
  children,
  hideBottom = false,
}) => {
  const { isMobile } = useResponsive()

  const mobilePathUp = `<path class="st0" d="M273.6,0h-1.2h-1H48h-0.8h-1.5H0v9.6c4.4-1.4,9-2.7,14.2-3.8c8.5-1.8,18.6-3,32.1-3C60,2.8,71.1,4.2,79.5,6
	c8.5,1.8,15.2,4.4,21.9,6.9c6.8,2.5,13.5,5.2,22.2,7S142.8,23,157,23c31.1,0,46.1-5.3,60.8-10.4c14.1-5,28-9.8,55.5-9.8
	c0.1,0,0.2,0,0.2,0c27.8,0,41.2,5,54.7,10c12.1,4.6,24.2,9.1,46.8,10V0H273.6z" style='stroke: none; fill: ${waveColor};' />`

  const mobilePathDown = `<path class="st0" d="M375,25V2.2c-22.6,0.9-34.7,5.5-46.8,10c-13.5,5.1-26.9,10-54.7,10c0,0-0.1,0-0.2,0c-27.5,0-41.4-4.9-55.5-9.8
	C203.1,7.3,188.1,2,157,2c-14.2,0-24.7,1.3-33.4,3.1s-15.4,4.5-22.2,7C94.7,14.7,88,17.2,79.5,19c-8.4,1.8-19.5,3.2-33.2,3.2
	c-13.5,0-23.6-1.2-32.1-3C9,18.1,4.4,16.8,0,15.4V25h45.8h1.5H48h223.4h1h1.2H375z" style='stroke: none; fill: ${waveColor};' />`

  const desktopPathDown = `<path class="st0" d="M1409.4,11.1c-23.1,4-46.1,8-94,8c-47,0-70.7-3.9-94.9-7.8c-22.9-3.6-46.3-7.4-90.4-8.1h-26.6
	c-40.1,0.6-61.1,4.3-82.2,7.9c-23,4-45.9,8-93.6,8c-6.4,0-12.3-0.1-17.8-0.2v0.2c-46.6,0-70-3.9-93.9-7.8
	c-22.7-3.6-45.8-7.4-89.4-8.1h-28.1c-40.3,0.6-61.5,4.3-82.6,7.9c-23.1,4-46.1,8-94,8c-47.1,0-70.8-3.9-95-7.8
	c-22.9-3.6-46.3-7.4-90.4-8.1h-13.7c-48.7,0-71.9,4-95.1,8c-23,4-45.9,8-93.5,8c-46.7,0-70.5-3.9-94.6-7.8c-12.1-2-24.3-4-39.6-5.5
	L0,28h134.1l0,0h1306V6.4C1428.6,7.8,1419,9.4,1409.4,11.1z" style='stroke: none; fill: ${waveColor};' />`

  const desktopPathUp = `<path class="st0" d="M1440.1,21.6V0h-1306l0,0H0l0,22.1c15.3-1.5,27.5-3.5,39.6-5.5c24.1-3.9,47.9-7.8,94.6-7.8
	c47.6,0,70.5,4,93.5,8c23.2,4,46.4,8,95.1,8h13.7c44.1-0.7,67.5-4.5,90.4-8.1c24.2-3.9,47.9-7.8,95-7.8c47.9,0,70.9,4,94,8
	c21.1,3.6,42.3,7.3,82.6,7.9h28.1c43.6-0.7,66.7-4.5,89.4-8.1c23.9-3.9,47.3-7.8,93.9-7.8v0.2c5.5-0.1,11.4-0.2,17.8-0.2
	c47.7,0,70.6,4,93.6,8c21.1,3.6,42.1,7.3,82.2,7.9h26.6c44.1-0.7,67.5-4.5,90.4-8.1c24.2-3.9,47.9-7.8,94.9-7.8c47.9,0,70.9,4,94,8
	C1419,18.6,1428.6,20.2,1440.1,21.6z" style='stroke: none; fill: ${waveColor};' />`

  const mobileViewBox = '0 0 375 26'
  const desktopViewBox = '0 0 1440 28'
  const mobilePathTop = !inverted ? mobilePathUp : mobilePathDown
  const mobilePathBottom = !inverted ? mobilePathDown : mobilePathUp
  const desktopPathTop = !inverted ? desktopPathUp : desktopPathDown
  const desktopPathBottom = !inverted ? desktopPathDown : desktopPathUp

  const viewBox = isMobile ? mobileViewBox : desktopViewBox
  const topPath = isMobile ? mobilePathTop : desktopPathTop
  const bottomPath = isMobile ? mobilePathBottom : desktopPathBottom

  const svg = ({ topPlacement = true }) => {
    const path = topPlacement ? topPath : bottomPath
    return base64(
      `<svg xmlns='http://www.w3.org/2000/svg' viewBox="${viewBox}" preserveAspectRatio='none'>${path}</svg>`,
    )
  }

  const divPadding = inverted ? 28 : 50

  return (
    <div
      className={styles.wrapper}
      style={{
        paddingTop: top ? divPadding : 0,
        paddingBottom: bottom ? divPadding : 0,
        backgroundColor,
      }}
    >
      {top && (
        <div
          style={{
            top: -3,
            maskImage: `url(data:image/svg+xml;base64,${svg({
              topPlacement: true,
            })})`,
            WebkitMaskImage: `url(data:image/svg+xml;base64,${svg({
              topPlacement: true,
            })})`,
            maskPosition: !inverted ? 'top' : 'bottom',
            WebkitMaskPosition: !inverted ? 'top' : 'bottom',
            backgroundColor: waveColor,
          }}
          className={styles.wave}
        />
      )}
      <ConditionalWrapper
        condition={inverted}
        wrapper={(wrapperChildren: React.ReactNode) => (
          <div style={{ padding: '23px 0', background: waveColor }}>
            {wrapperChildren}
          </div>
        )}
      >
        {children}
      </ConditionalWrapper>
      {bottom && !hideBottom && (
        <div
          style={{
            bottom: -3,
            maskImage: `url(data:image/svg+xml;base64,${svg({
              topPlacement: false,
            })})`,
            WebkitMaskImage: `url(data:image/svg+xml;base64,${svg({
              topPlacement: false,
            })})`,
            maskPosition: !inverted ? 'bottom' : 'top',
            WebkitMaskPosition: !inverted ? 'bottom' : 'top',
            backgroundColor: waveColor,
            transform: inverted && 'scale(-1, 1)',
          }}
          className={styles.wave}
        />
      )}
    </div>
  )
}

export default WaveWrapper
