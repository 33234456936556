import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconLockAlt = createIcon({
  path: (
    <g>
      <path
        d="M10.625 15.3125C10.625 15.6641 10.3125 15.9375 10 15.9375C9.64844 15.9375 9.375 15.6641 9.375 15.3125V12.1875C9.375 11.875 9.64844 11.5625 10 11.5625C10.3125 11.5625 10.625 11.875 10.625 12.1875V15.3125ZM10 0C12.7344 0 15 2.26562 15 5V7.5H15.625C17.3438 7.5 18.75 8.90625 18.75 10.625V16.875C18.75 18.6328 17.3438 20 15.625 20H4.375C2.61719 20 1.25 18.6328 1.25 16.875V10.625C1.25 8.90625 2.61719 7.5 4.375 7.5H5V5C5 2.26562 7.22656 0 10 0ZM10 1.25C7.89062 1.25 6.25 2.92969 6.25 5V7.5H13.75V5C13.75 2.92969 12.0703 1.25 10 1.25ZM4.375 8.75C3.32031 8.75 2.5 9.60938 2.5 10.625V16.875C2.5 17.9297 3.32031 18.75 4.375 18.75H15.625C16.6406 18.75 17.5 17.9297 17.5 16.875V10.625C17.5 9.60938 16.6406 8.75 15.625 8.75H4.375Z"
        fill="black"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconLockAlt
