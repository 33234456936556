import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconBabyCarriage = createIcon({
  path: (
    <g>
      <path
        d="M5.39062 0.898438L10 7.5H15.625V5.9375C15.625 4.76562 16.6016 3.75 17.8125 3.75H19.0625C19.5703 3.75 20 4.17969 20 4.6875C20 5.23438 19.5703 5.625 19.0625 5.625H17.8125C17.6172 5.625 17.5 5.78125 17.5 5.9375V8.75C17.5 9.84375 17.2266 10.9375 16.7969 11.9531C16.3281 12.9297 15.6641 13.8281 14.8438 14.5703C14.0234 15.3125 13.0859 15.8984 12.0312 16.2891C10.9766 16.6797 9.84375 16.875 8.75 16.875C7.61719 16.875 6.48438 16.6797 5.42969 16.2891C4.375 15.8984 3.4375 15.3125 2.61719 14.5703C1.79688 13.8281 1.13281 12.9297 0.664062 11.9531C0.234375 10.9375 0 9.84375 0 8.75V7.5C0.078125 4.60938 1.60156 2.07031 3.82812 0.585938C4.375 0.234375 5.03906 0.390625 5.39062 0.898438ZM2.38281 11.1719C2.73438 11.9141 3.24219 12.6172 3.86719 13.2031C4.49219 13.75 5.27344 14.2188 6.09375 14.5312C6.95312 14.8438 7.8125 15 8.75 15C9.64844 15 10.5469 14.8438 11.3672 14.5312C12.1875 14.2188 12.9688 13.75 13.5938 13.2031C14.2188 12.6172 14.7266 11.9141 15.0781 11.1719C15.1562 10.9766 15.2344 10.8203 15.2734 10.625C15.4297 10.2344 15.5078 9.80469 15.5859 9.375H1.875C1.95312 9.80469 2.03125 10.2344 2.1875 10.625C2.22656 10.8203 2.30469 10.9766 2.38281 11.1719ZM1.25 18.125C1.25 17.1094 2.07031 16.25 3.125 16.25C4.14062 16.25 5 17.1094 5 18.125C5 19.1797 4.14062 20 3.125 20C2.07031 20 1.25 19.1797 1.25 18.125ZM16.25 18.125C16.25 19.1797 15.3906 20 14.375 20C13.3203 20 12.5 19.1797 12.5 18.125C12.5 17.1094 13.3203 16.25 14.375 16.25C15.3906 16.25 16.25 17.1094 16.25 18.125Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconBabyCarriage
