import { useState } from 'react'
import { isDesktopMediaQuery, isMobileMediaQuery } from 'lib/theme'
import { useMediaQuery } from 'react-responsive'

import useEffectOnce from './useEffectOnce'

const useResponsive = (): {
  isMobile: boolean
  isDesktop: boolean
} => {
  const [isClient, setIsClient] = useState(false)
  const isMobile = useMediaQuery(isMobileMediaQuery)
  const isDesktop = useMediaQuery(isDesktopMediaQuery)

  useEffectOnce(() => {
    setIsClient(true)
  })

  return {
    isMobile: isClient ? isMobile : true,
    isDesktop: isClient ? isDesktop : false,
  }
}

export default useResponsive
