import { GIFTING_OCCASIONS, GiftingOccasion } from 'constants/index'
import dayjs from 'dayjs'

import useMarket from './useMarket'

type UseGiftingOccasionReturnType = {
  marketIsGifting: boolean
  giftingOccasion?: GiftingOccasion
}

export default function useGiftingOccasions(): UseGiftingOccasionReturnType {
  const market = useMarket()

  let occasion: GiftingOccasion
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (occasion in GIFTING_OCCASIONS) {
    const occasionData = GIFTING_OCCASIONS[occasion]
    if (
      Object.keys(occasionData).includes(market.id) &&
      dayjs().isBetween(
        occasionData[market.id].start,
        occasionData[market.id].end,
        'day',
        '[]',
      )
    ) {
      return {
        marketIsGifting: true,
        giftingOccasion: occasion,
      }
    }
  }

  return { marketIsGifting: false }
}
