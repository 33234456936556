import { ApolloError, gql, useQuery } from '@apollo/client'
import type { SortInput, UseCurrentUserQuery } from 'apollo-gql'
import jwtDecode from 'jwt-decode'
import { getItem } from 'lib/localStorage'
import { isServer } from 'lib/utils'

const query = gql`
  query useCurrentUser($customerSort: [SortInput!]) {
    currentUser {
      id
      email
      name
      customers(sort: $customerSort) {
        edges {
          node {
            ... on Customer {
              id
              email
              phone
              shippingAddressLine1
              shippingAddressLine2
              shippingAddressCity
              shippingAddressZip
              shippingAddressState
              shippingAddressCountry
            }
          }
        }
      }
      profiles {
        totalCount
      }
    }
  }
`

type UseCurrentUserProps = {
  customerSort?: [SortInput]
}

type UseCurrentUserReturn = {
  loading: boolean
  error: ApolloError
  currentUser: UseCurrentUserQuery['currentUser']
}

export default function useCurrentUser({
  customerSort,
}: UseCurrentUserProps = {}): UseCurrentUserReturn {
  const token = !isServer() && getItem('token')

  let sessionToken
  if (token) {
    const decodedToken = jwtDecode<{ type: string }>(token)
    const { type: tokenType } = decodedToken

    // If not session do not return user
    if (tokenType === 'session') {
      sessionToken = token
    }
  }

  const { loading, error, data } = useQuery<UseCurrentUserQuery>(query, {
    errorPolicy: 'all',
    skip: !sessionToken,
    ssr: false,
    variables: {
      customerSort,
    },
  })

  return { loading, error, currentUser: data?.currentUser }
}
