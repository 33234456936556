import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconStarShooting = createIcon({
  path: (
    <g>
      <path
        d="M15.2188 11.4688L11.1172 10.8438L9.28125 7.13281C9.125 6.82031 8.8125 6.625 8.46094 6.625C8.14844 6.625 7.83594 6.82031 7.67969 7.13281L5.84375 10.8438L1.74219 11.4688C1 11.5469 0.726562 12.4844 1.27344 12.9922L4.20312 15.8828L3.53906 19.9844C3.5 20.2188 3.57812 20.4922 3.73438 20.6875C3.89062 20.8828 4.125 21 4.39844 21C4.55469 21 4.67188 21 4.82812 20.9219L8.5 18.9688L12.1328 20.9219C12.2891 21 12.4062 21 12.5625 21C12.8359 21 13.0703 20.8828 13.2266 20.6875C13.4219 20.4922 13.5 20.2188 13.4609 19.9844L12.7578 15.8828L15.7266 12.9922C16.2344 12.4844 15.9609 11.5469 15.2188 11.4688ZM10.7266 15.2188L11.2734 18.3438L8.46094 16.8594L5.6875 18.3438L6.23438 15.2188L3.96875 13.0312L7.09375 12.5625L8.46094 9.75L9.86719 12.5625L12.9922 13.0312L10.7266 15.2188ZM13.1484 8.85156C13.3047 9.04688 13.5391 9.125 13.8125 9.125C14.0469 9.125 14.2812 9.04688 14.4375 8.85156L20.6875 2.60156C21.0781 2.25 21.0781 1.66406 20.6875 1.3125C20.3359 0.921875 19.75 0.921875 19.3984 1.3125L13.1484 7.5625C12.7578 7.91406 12.7578 8.5 13.1484 8.85156ZM10.6875 4.75C10.9219 4.75 11.1562 4.67188 11.3125 4.47656L13.1875 2.60156C13.5781 2.25 13.5781 1.66406 13.1875 1.3125C12.8359 0.921875 12.25 0.921875 11.8984 1.3125L10.0234 3.1875C9.63281 3.53906 9.63281 4.125 10.0234 4.47656C10.1797 4.67188 10.4141 4.75 10.6875 4.75ZM19.3984 8.77344L17.5234 10.6484C17.1328 11.0391 17.1328 11.625 17.5234 11.9766C17.6797 12.1719 17.9141 12.25 18.1875 12.25C18.4219 12.25 18.6562 12.1719 18.8125 11.9766L20.6875 10.1016C21.0781 9.75 21.0781 9.16406 20.6875 8.8125C20.3359 8.42188 19.75 8.42188 19.3984 8.77344Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 21',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconStarShooting
