import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCommentDots = createIcon({
  path: (
    <g>
      <path
        d="M6.625 8.125C5.92188 8.125 5.375 8.71094 5.375 9.375C5.375 10.0781 5.92188 10.625 6.625 10.625C7.28906 10.625 7.83594 10.0781 7.83594 9.375C7.83594 8.71094 7.28906 8.125 6.625 8.125ZM11 8.125C10.2969 8.125 9.75 8.67188 9.75 9.375C9.75 10.0391 10.3359 10.5859 11 10.5859C11.625 10.5859 12.2109 10 12.2109 9.375C12.2109 8.71094 11.6641 8.125 11 8.125ZM15.375 8.125C14.6719 8.125 14.125 8.71094 14.125 9.375C14.125 10.0781 14.7109 10.625 15.375 10.625C16.0391 10.625 16.5859 10.0781 16.5859 9.375C16.625 8.71094 16.0391 8.125 15.375 8.125ZM11 1.25C5.45312 1.25 1 4.88281 1 9.375C1 11.2109 1.78125 12.9297 3.07031 14.2969C2.48438 15.8203 1.27344 17.1484 1.27344 17.1484C1 17.4219 0.960938 17.8125 1.07812 18.1641C1.19531 18.5547 1.54688 18.75 1.9375 18.75C4.32031 18.75 6.19531 17.7734 7.36719 16.9531C8.46094 17.3047 9.71094 17.5 11 17.5C16.5078 17.5 20.9609 13.8672 20.9609 9.41406C20.9609 4.96094 16.5078 1.25 11 1.25ZM11 15.625C9.94531 15.625 8.89062 15.4688 7.91406 15.1562L7.01562 14.8828L6.27344 15.4297C5.72656 15.8203 4.94531 16.25 4.00781 16.5625C4.32031 16.0938 4.59375 15.5469 4.78906 15L5.21875 13.9062L4.39844 13.0469C3.69531 12.3047 2.875 11.0547 2.875 9.375C2.875 5.9375 6.50781 3.125 10.9609 3.125C15.4141 3.125 19.0469 5.9375 19.0469 9.375C19.0469 12.8516 15.4531 15.625 11 15.625Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 21 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconCommentDots
