import { CustomDestination } from 'lib/tracking/avo/sdk'
import { isServer } from 'lib/utils'

const amplitudeDestination: CustomDestination = {
  make: async (_env: string, apiKey: string) => {
    if (!isServer()) {
      const amplitude = (await import('amplitude-js')).default
      amplitude.getInstance().init(apiKey, null, {
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
        logAttributionCapturedEvent: true,
        saveParamsReferrerOncePerSession: false,
      })
    }
  },
  logEvent: async (
    eventName: string,
    eventProperties: Record<string, unknown>,
  ) => {
    if (!isServer()) {
      const amplitude = (await import('amplitude-js')).default
      amplitude.getInstance().logEvent(eventName, eventProperties)
    }
  },
  identify: async (userId: string) => {
    if (!isServer()) {
      const amplitude = (await import('amplitude-js')).default
      amplitude.getInstance().setUserId(userId)
    }
  },
  unidentify: async () => {
    if (!isServer()) {
      const amplitude = (await import('amplitude-js')).default
      amplitude.getInstance().setUserId(null)
      amplitude.getInstance().regenerateDeviceId()
    }
  },
  setUserProperties: async (
    _userId: string,
    userProperties: Record<string, unknown>,
  ) => {
    if (!isServer()) {
      const amplitude = (await import('amplitude-js')).default
      amplitude.getInstance().setUserProperties(userProperties)
    }
  },
}

export default amplitudeDestination
