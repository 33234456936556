import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTimesCircle = createIcon({
  path: (
    <g>
      <path
        d="M6.83594 6.83594C7.1875 6.48438 7.77344 6.48438 8.125 6.83594L9.96094 8.71094L11.8359 6.83594C12.1875 6.48438 12.7734 6.48438 13.125 6.83594C13.5156 7.22656 13.5156 7.8125 13.125 8.16406L11.2891 10L13.125 11.8359C13.5156 12.2266 13.5156 12.8125 13.125 13.1641C12.7734 13.5547 12.1875 13.5547 11.8359 13.1641L9.96094 11.3281L8.125 13.1641C7.77344 13.5547 7.1875 13.5547 6.83594 13.1641C6.44531 12.8125 6.44531 12.2266 6.83594 11.8359L8.67188 10L6.83594 8.16406C6.44531 7.8125 6.44531 7.22656 6.83594 6.83594ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconTimesCircle
