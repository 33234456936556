import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconReset = createIcon({
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 2.39996L5.625 3.82475e-07L8.75011 3.00008L5.625 5.9999L5.625 3.59994L5.00003 3.59994C2.93186 3.59994 1.24999 5.21451 1.24999 7.19993C1.24999 9.18535 2.93186 10.7999 5.00003 10.7999C7.06809 10.7999 8.75006 9.18535 8.75006 7.19993L8.75006 6.59996L10 6.59996L10 7.19993C10 9.84643 7.75689 12 5.00013 12C2.24306 12 8.60812e-07 9.84654 6.29438e-07 7.19993C3.98064e-07 4.55332 2.24311 2.39986 5.00013 2.39986L5.625 2.39996Z"
      fill="#95979F"
    />
  ),
  viewBox: '0 0 10 12',
  defaultProps: { fill: 'none', height: 12 },
})

export default IconReset
