import React from 'react'
import dynamic from 'next/dynamic'

// Used for wrapping components that should not be rendered on the server - good for use cases when you want to conditionally render a component depending on the device type / screen size etc that's not available on the server.

const NoSSR: React.FC = ({ children }) => <>{children}</>

export default dynamic(() => Promise.resolve(NoSSR), {
  ssr: false,
})
