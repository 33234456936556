import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { currentMarketIdVar } from 'graphql/reactive'

import { GET_SIDEMENU } from 'hooks/useSideMenu'

async function prefetchSideMenu(
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<boolean> {
  const marketId = currentMarketIdVar()

  if (marketId) {
    await apolloClient.query({
      query: GET_SIDEMENU,
      variables: {
        marketId,
      },
    })
  }
  return true
}

export default prefetchSideMenu
