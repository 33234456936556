import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconHatSanta = createIcon({
  path: (
    <g>
      <path
        d="M23.8672 8.51562C23.9453 8.20312 23.8672 7.85156 23.6328 7.57812C23.3594 7.34375 23.0469 7.26562 22.6953 7.34375C22.3438 6.75781 21.3672 6.75781 21.0156 7.34375C20.7031 7.26562 20.3516 7.34375 20.0781 7.57812C19.8438 7.85156 19.7656 8.20312 19.8438 8.51562C19.5312 8.71094 19.375 9.02344 19.375 9.375C19.375 9.72656 19.5312 10.0391 19.8438 10.2344C19.7656 10.5469 19.8438 10.8984 20.0781 11.1719C20.3516 11.4062 20.6641 11.5234 21.0156 11.4062C21.2109 11.7188 21.5234 11.875 21.875 11.875C22.1875 11.875 22.5 11.7188 22.6953 11.4062C22.9688 11.4844 23.2812 11.4844 23.6328 11.1719C23.8672 10.8984 23.9453 10.5469 23.8672 10.2344C24.1797 10.0391 24.375 9.72656 24.375 9.375C24.375 9.02344 24.1797 8.71094 23.8672 8.51562ZM18.125 14.375H18.3594L15.2344 6.25C15.0781 5.89844 14.7266 5.625 14.3359 5.625C14.2188 5.625 14.1016 5.66406 13.9844 5.70312C13.5156 5.89844 13.2422 6.44531 13.4375 6.91406L16.3672 14.375H3.63281L6.64062 6.83594C7.53906 4.60938 9.6875 3.125 12.1094 3.125C13.9844 3.125 15.7812 4.0625 16.875 5.625L17.9688 7.10938C18.1641 7.38281 18.4375 7.5 18.75 7.5C18.9453 7.5 19.1016 7.46094 19.2578 7.34375C19.6875 7.03125 19.8047 6.44531 19.4922 6.05469L18.3984 4.53125C16.9531 2.5 14.6094 1.25 12.1094 1.25C8.90625 1.25 6.09375 3.16406 4.88281 6.13281L1.60156 14.375H1.875C0.820312 14.375 0 15.2344 0 16.25V16.875C0 17.9297 0.820312 18.75 1.875 18.75H18.125C19.1406 18.75 20 17.9297 20 16.875V16.25C20 15.2344 19.1406 14.375 18.125 14.375ZM18.75 7.1875C18.7109 7.1875 18.7109 7.22656 18.7109 7.22656C18.6719 7.22656 18.6719 7.1875 18.6719 7.1875C18.7109 7.1875 18.75 7.1875 18.75 7.1875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconHatSanta
