import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconReplay = createIcon({
  path: (
    <g>
      <path
        d="M10.6433 11.5799L10.6505 11.5795L10.6576 11.5785C11.3875 11.4795 11.9499 10.8543 11.9499 10.0973C11.9499 9.27178 11.2806 8.60253 10.4551 8.60253L6.18241 8.60233C8.54481 5.66634 12.121 3.90104 16 3.90104C22.9582 3.90104 28.599 9.54179 28.599 16.5C28.599 23.4582 22.9582 29.099 16 29.099C13.604 29.099 11.3105 28.4305 9.32555 27.188C8.51621 26.6814 7.76622 26.0839 7.09067 25.4083C6.41408 24.7316 5.81593 23.9803 5.30893 23.1695C4.06838 21.1856 3.40104 18.894 3.40104 16.5C3.40104 16.1149 3.41828 15.7321 3.45252 15.3523C3.52665 14.5301 2.92021 13.8035 2.09799 13.7293C1.27578 13.6552 0.549143 14.2617 0.475011 15.0839C0.432722 15.5529 0.411458 16.0253 0.411458 16.5C0.411458 19.4583 1.23836 22.2986 2.77412 24.7546C3.40113 25.7573 4.14048 26.6859 4.97659 27.5221C5.81142 28.357 6.73844 29.0955 7.73933 29.722C10.1967 31.2602 13.0392 32.0885 16 32.0885C24.6093 32.0885 31.5885 25.1093 31.5885 16.5C31.5885 7.89069 24.6093 0.911458 16 0.911458C11.4162 0.911458 7.17102 2.90643 4.25373 6.25022L4.25521 2.40991L4.25521 2.40269L4.25473 2.39549L4.24303 2.22163L4.24255 2.21442L4.24157 2.20726C4.14257 1.47744 3.51735 0.915034 2.76042 0.915034C1.93487 0.915034 1.26562 1.58428 1.26562 2.40983V10.0973V10.1045L1.26611 10.1117L1.2778 10.2855L1.27829 10.2927L1.27926 10.2999C1.37827 11.0297 2.00349 11.5921 2.76042 11.5921H10.4551H10.4622L10.4694 11.5916L10.6433 11.5799Z"
        fill="white"
      />
    </g>
  ),
  viewBox: '0 0 32 33',
  defaultProps: { fill: 'none', height: 18 },
})

export default IconReplay
