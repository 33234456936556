import { currentLanguageCodeVar, currentMarketIdVar } from 'graphql/reactive'
import { LinkProps } from 'next/link'
import { NextRouter, useRouter as useNextRouter } from 'next/router'

import usePath from 'hooks/usePath'

type TransitionOptions = {
  shallow?: boolean
  scroll?: boolean
  localize?: boolean
}

type UseRouter = () => {
  push: (
    url: LinkProps['href'],
    asPayload?: LinkProps['href'],
    options?: TransitionOptions,
  ) => void
  replace: (
    url: LinkProps['href'],
    asPayload?: LinkProps['href'],
    options?: TransitionOptions,
  ) => void
  pathname: string
  asPath: string
  query: NextRouter['query']
  events: NextRouter['events']
  beforePopState: NextRouter['beforePopState']
  back: NextRouter['back']
}

const useRouter: UseRouter = () => {
  const router = useNextRouter()
  const path = usePath()

  const marketId = currentMarketIdVar()
  const languageCode = currentLanguageCodeVar()

  const defaultOptions = { localize: true }

  const localizedHref = (href: LinkProps['href']) => {
    if (typeof href === 'string') {
      return `/${marketId}/${languageCode}${href}`
    }

    const { query } = href

    return {
      pathname: `/[market]/[language]${href.pathname}`,
      query,
    }
  }

  const localizedAs = (as: LinkProps['as']) => {
    return typeof as === 'string'
      ? `/${marketId}/${languageCode}${as}`
      : { ...as, pathname: `/${marketId}/${languageCode}${as.pathname}` }
  }

  const correctedAs = (url: LinkProps['href'], as: LinkProps['as']) => {
    return url === '/' ? '' : as
  }

  const push = (
    url: LinkProps['href'],
    asPayload?: LinkProps['as'],
    options?: TransitionOptions,
  ) => {
    const as = asPayload && correctedAs(url, asPayload)
    const args = Object.assign(defaultOptions, options)
    const shouldLocalize = args.localize && path.isLocalized

    const href = shouldLocalize ? localizedHref(url) : url
    const newAs = as && (shouldLocalize ? localizedAs(as) : as)

    router.push(href, newAs, args)
  }

  const replace = (
    url: LinkProps['href'],
    asPayload?: LinkProps['as'],
    options?: TransitionOptions,
  ) => {
    const as = asPayload && correctedAs(url, asPayload)
    const args = Object.assign(defaultOptions, options)
    const shouldLocalize = args.localize && path.isLocalized

    router.replace(
      shouldLocalize ? localizedHref(url) : url,
      as && (shouldLocalize ? localizedAs(as) : as),
      args,
    )
  }

  return {
    push,
    replace,
    pathname: router.pathname,
    asPath: router.asPath,
    query: router.query,
    events: router.events,
    beforePopState: router.beforePopState,
    back: router.back,
  }
}

export default useRouter
