import React from 'react'
import cn from 'classnames'

import styles from './Typography.module.css'

type TypographySubtypeProps = {
  className?: string
  theme?: 'primary' | 'secondary' | 'tertiary' | 'tertiaryDark' | 'white'
}

type TypographyHOCProps = (
  displayName: string,
  element: string,
  classes: string[],
) => React.FC<TypographySubtypeProps>

const Typography: TypographyHOCProps = (displayName, element, classes) => {
  const Component: React.FC<TypographySubtypeProps> = ({
    children,
    className,
    theme = 'secondary',
  }) => {
    return React.createElement(
      element,
      { className: cn(className, styles[theme], ...classes) },
      children,
    )
  }

  Component.displayName = `Typography.${displayName}`
  return Component
}

const LargeHeading = Typography('LargeHeading', 'h1', [
  styles.heading,
  styles.largeHeading,
])
const MediumHeading = Typography('MediumHeading', 'h1', [
  styles.heading,
  styles.mediumHeading,
])
const SmallHeading = Typography('SmallHeading', 'h1', [
  styles.heading,
  styles.smallHeading,
])

const H1 = Typography('H1', 'h1', [styles.headingTag, styles.h1])
const H2 = Typography('H2', 'h2', [styles.headingTag, styles.h2])
const H3 = Typography('H3', 'h3', [styles.headingTag, styles.h3])
const H4 = Typography('H4', 'h4', [styles.headingTag, styles.h4])
const H5 = Typography('H5', 'h5', [styles.headingTag, styles.h5])
const H6 = Typography('H6', 'h6', [styles.headingTag, styles.h6])

const Body = Typography('Body', 'span', [styles.bodyTag])
const BodyBold = Typography('BodyBold', 'span', [styles.bodyTag, styles.bold])
const BodySmall = Typography('BodySmall', 'span', [
  styles.bodyTag,
  styles.small,
])
const BodySmallBold = Typography('BodySmallBold', 'span', [
  styles.bodyTag,
  styles.small,
  styles.bold,
])

const CTA = Typography('CTA', 'span', [styles.cta])
const Meta = Typography('Meta', 'span', [styles.meta])
const MetaBold = Typography('MetaBold', 'span', [styles.meta, styles.bold])
const LinkBold = Typography('LinkBold', 'span', [styles.link, styles.bold])
const LinkBoldSmall = Typography('LinkBoldSmall', 'span', [
  styles.link,
  styles.linkSmall,
  styles.bold,
])

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LargeHeading,
  MediumHeading,
  SmallHeading,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Body,
  BodyBold,
  BodySmall,
  BodySmallBold,
  CTA,
  Meta,
  MetaBold,
  LinkBold,
  LinkBoldSmall,
}
