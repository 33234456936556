import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRectangleAd = createIcon({
  path: (
    <g>
      <path
        d="M12.0078 12.4219C12.2422 12.8906 12.0469 13.4375 11.5781 13.6719C11.1094 13.9062 10.5625 13.7109 10.3281 13.2422L10.1328 12.8125H6.57812L6.38281 13.2422C6.14844 13.7109 5.60156 13.9062 5.13281 13.6719C4.66406 13.4375 4.46875 12.8906 4.70312 12.4219L7.51562 6.79688C7.67188 6.48438 7.98438 6.25 8.33594 6.25C8.72656 6.25 9.03906 6.48438 9.19531 6.79688L12.0078 12.4219ZM7.51562 10.9375H9.19531L8.375 9.29688L7.51562 10.9375ZM16.5 7.1875C16.5 6.67969 16.8906 6.25 17.4375 6.25C17.9453 6.25 18.375 6.67969 18.375 7.1875V12.8125C18.375 13.3594 17.9453 13.75 17.4375 13.75C17.1641 13.75 16.9297 13.6719 16.7734 13.4766C16.3828 13.6719 15.9922 13.75 15.5625 13.75C14 13.75 12.75 12.5 12.75 10.9375C12.75 9.41406 14 8.125 15.5625 8.125C15.875 8.125 16.1875 8.20312 16.5 8.32031V7.1875ZM15.5625 10C15.0156 10 14.625 10.4297 14.625 10.9375C14.625 11.4844 15.0156 11.875 15.5625 11.875C16.0703 11.875 16.5 11.4844 16.5 10.9375C16.5 10.4297 16.0703 10 15.5625 10ZM0.25 3.75C0.25 2.38281 1.34375 1.25 2.75 1.25H20.25C21.6172 1.25 22.75 2.38281 22.75 3.75V16.25C22.75 17.6562 21.6172 18.75 20.25 18.75H2.75C1.34375 18.75 0.25 17.6562 0.25 16.25V3.75ZM2.125 3.75V16.25C2.125 16.6016 2.39844 16.875 2.75 16.875H20.25C20.5625 16.875 20.875 16.6016 20.875 16.25V3.75C20.875 3.4375 20.5625 3.125 20.25 3.125H2.75C2.39844 3.125 2.125 3.4375 2.125 3.75Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 23 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconRectangleAd
