import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconLaptop = createIcon({
  path: (
    <g>
      <path
        d="M24.0625 13.75H0.9375C0.390625 13.75 0 14.1797 0 14.6875V15.3125C0 17.2266 1.52344 18.75 3.4375 18.75H21.5625C23.4375 18.75 25 17.2266 25 15.3125V14.6875C25 14.1797 24.5703 13.75 24.0625 13.75ZM21.5625 16.875H3.4375C2.65625 16.875 2.03125 16.3672 1.875 15.625H23.0859C22.9297 16.3672 22.3047 16.875 21.5625 16.875ZM4.375 3.4375C4.375 3.28125 4.49219 3.125 4.6875 3.125H20.3125C20.4688 3.125 20.625 3.28125 20.625 3.4375V12.5H22.5V3.4375C22.5 2.26562 21.4844 1.25 20.3125 1.25H4.6875C3.47656 1.25 2.5 2.26562 2.5 3.4375V12.5H4.375V3.4375Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconLaptop
