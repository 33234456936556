// Generated by Avo VERSION 110.14.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: string, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: object) => void;
  setUserProperties?: (userId: string, userProperties: object) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: object) => void;
  revenue?: (amount: number, eventProperties: object) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: object,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: object,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: object,
    groupTypesToGroupIds: object,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: object,
  shapeUserProps?: object,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "APnFcmdrgmeucBxiig6O";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: int;
  right: int;
}

interface bottomLeftParameters {
  bottom: int;
  left: int;
}

interface topRightParameters {
  top: int;
  right: int;
}

interface topLeftParameters {
  top: int;
  left: int;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: int;
  right?: int;
  bottom?: int;
  left?: int;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggePosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: object) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: string, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "8y1SgsRER8Hql90B4v3a",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "JToNwC3zo9svd6kBck5o",
          "se": (new Date()).toISOString(),
          "so": "f7dVXxEtXzz",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: string, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "8y1SgsRER8Hql90B4v3a",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "JToNwC3zo9svd6kBck5o",
          "se": (new Date()).toISOString(),
          "so": "f7dVXxEtXzz",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "JToNwC3zo9svd6kBck5o",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const CheckoutStepName = {
  'CHECKOUT_FORM': 'checkout form',
  'CHECKOUT_PAGE': 'checkout page',
  'FILLED_IN_REQUEST_FORM': 'filled in request form',
  'PAYMENT': 'payment',
  'REQUEST_FORM': 'request form',
  'PAYMENT_INFO_SUBMITTED': 'payment info submitted',
  'ORDER_SUBMITTED': 'order submitted',
} as const;
export type CheckoutStepNameType = typeof CheckoutStepName;
export type CheckoutStepNameValueType = CheckoutStepNameType[keyof CheckoutStepNameType];

export const ProductSaleMode = {
  'B2B': 'B2B',
  'B2C': 'B2C',
} as const;
export type ProductSaleModeType = typeof ProductSaleMode;
export type ProductSaleModeValueType = ProductSaleModeType[keyof ProductSaleModeType];

export const Platform = {
  'WEB_APP': 'WebApp',
  'API': 'API',
  'I_O_S': 'iOS',
  'ANDROID': 'Android',
} as const;
export type PlatformType = typeof Platform;
export type PlatformValueType = PlatformType[keyof PlatformType];

export const ProductVatType = {
  'EBOOK': 'EBOOK',
  'ESERVICE': 'ESERVICE',
  'PHYSICAL': 'PHYSICAL',
  'SAAS': 'SAAS',
  'STANDARD': 'STANDARD',
} as const;
export type ProductVatTypeType = typeof ProductVatType;
export type ProductVatTypeValueType = ProductVatTypeType[keyof ProductVatTypeType];

export const ProductProductType = {
  'BUSINESS_USE': 'BUSINESS_USE',
  'DONATION': 'DONATION',
  'EMAIL_DELIVERY': 'EMAIL_DELIVERY',
  'EXPRESS_DELIVERY': 'EXPRESS_DELIVERY',
  'GENERIC_PRODUCT': 'GENERIC_PRODUCT',
  'SERVICE_FEE': 'SERVICE_FEE',
  'SMS_DELIVERY': 'SMS_DELIVERY',
  'VIDEO_MIN_LENGTH': 'VIDEO_MIN_LENGTH',
  'VIDEO_NO_WATERMARK': 'VIDEO_NO_WATERMARK',
  'VIDEO_QUALITY': 'VIDEO_QUALITY',
  'VIDEO_REQUEST': 'VIDEO_REQUEST',
  'VIDEO_REQUEST_BUSINESS': 'VIDEO_REQUEST_BUSINESS',
  'VIDEO_REQUEST_BUSINESS_EXTENDED_INSTRUCTIONS': 'VIDEO_REQUEST_BUSINESS_EXTENDED_INSTRUCTIONS',
  'VIDEO_REQUEST_BUSINESS_INTERNAL': 'VIDEO_REQUEST_BUSINESS_INTERNAL',
  'VIDEO_REQUEST_BUSINESS_INTERNAL_EXTENDED_INSTRUCTIONS': 'VIDEO_REQUEST_BUSINESS_INTERNAL_EXTENDED_INSTRUCTIONS',
  'VIDEO_REQUEST_EXTENDED_INSTRUCTIONS': 'VIDEO_REQUEST_EXTENDED_INSTRUCTIONS',
} as const;
export type ProductProductTypeType = typeof ProductProductType;
export type ProductProductTypeValueType = ProductProductTypeType[keyof ProductProductTypeType];

export const RequestRequestType = {
  'VIDEO': 'VIDEO',
  'VIDEO_BUSINESS': 'VIDEO_BUSINESS',
} as const;
export type RequestRequestTypeType = typeof RequestRequestType;
export type RequestRequestTypeValueType = RequestRequestTypeType[keyof RequestRequestTypeType];

export const ReactionStatus = {
  'NEW': 'NEW',
  'AWAITING_REVIEW': 'AWAITING_REVIEW',
  'REJECTED': 'REJECTED',
  'APPROVED': 'APPROVED',
} as const;
export type ReactionStatusType = typeof ReactionStatus;
export type ReactionStatusValueType = ReactionStatusType[keyof ReactionStatusType];

let sysPlatform: any;

export function setSystemProperties(properties: {platform: PlatformValueType}
  ) {
  if (properties.platform !== null && properties.platform !== undefined) {
    sysPlatform = properties.platform;
  }
}

let Amplitude: any;
let GTMClientSide: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, systemProperties: {platform: PlatformValueType},
  destinationOptions: any, AmplitudeDestination: CustomDestination,
  GTMClientSideDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    Amplitude = AmplitudeDestination;
    if (__AVO_ENV__ === 'prod') {
      Amplitude && Amplitude.make && Amplitude.make(__AVO_ENV__, "55efc4f60c28b66167949aef2050d324");
    } else if (__AVO_ENV__ === 'dev') {
      Amplitude && Amplitude.make && Amplitude.make(__AVO_ENV__, "6366fa5ff465510495b7bd58929c4cf4");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Amplitude. Head to destination settings in Avo to set a staging key.");
      Amplitude && Amplitude.make && Amplitude.make(__AVO_ENV__, null);
    }

    GTMClientSide = GTMClientSideDestination;
    if (__AVO_ENV__ === 'prod') {
      GTMClientSide && GTMClientSide.make && GTMClientSide.make(__AVO_ENV__, "55efc4f60c28b66167949aef2050d324");
    } else if (__AVO_ENV__ === 'dev') {
      GTMClientSide && GTMClientSide.make && GTMClientSide.make(__AVO_ENV__, "6366fa5ff465510495b7bd58929c4cf4");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for GTM Client Side. Head to destination settings in Avo to set a staging key.");
      GTMClientSide && GTMClientSide.make && GTMClientSide.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface LoggedInProperties {
  marketId: string;
  localeId: string;
  userId_: string;
  userId: string;
}
/**
 * loggedIn: When the user logs into their account
(GTM: userLogin)
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.userId: User connected to the customer or profile
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/yTTyIPIQ8}
 */
export function loggedIn(properties: LoggedInProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yTTyIPIQ8", "5d032ed94c35ffc761528c4ff9d8adca55107ac867676f3d48b0e8952cedbcb1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("loggedIn", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {
      "user_id": properties.userId,
      });
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yTTyIPIQ8", "loggedIn", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], [
      {id:"8qEEsx8xGfQ", name: "user_id", value: properties.userId},
      ].concat([] as any[]));
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};
  userProperties["user_id"] = properties.userId;

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("loggedIn", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "yTTyIPIQ8", "5d032ed94c35ffc761528c4ff9d8adca55107ac867676f3d48b0e8952cedbcb1");
    }
    // destination Amplitude
    Amplitude.identify(properties.userId_);
    Amplitude.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    Amplitude.logEvent("loggedIn", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface LoggedOutProperties {
  marketId: string;
  localeId: string;
}
/**
 * loggedOut: When the user logs out of their account
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/vJUs0xFOYU}
 */
export function loggedOut(properties: LoggedOutProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vJUs0xFOYU", "5c14fd908010373cd9c1eb875afe12cc2a2c955c7a70b80a68d87e7f6820048e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("loggedOut", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vJUs0xFOYU", "loggedOut", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("loggedOut", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "vJUs0xFOYU", "5c14fd908010373cd9c1eb875afe12cc2a2c955c7a70b80a68d87e7f6820048e");
    }
    // destination Amplitude
    Amplitude.logEvent("loggedOut", (Object as any).assign({}, eventProperties));
    Amplitude.unidentify();

  } else {
    // do nothing
  }
}

export interface SystemErrorEncounteredProperties {
  marketId: string;
  localeId: string;
  errorPage: string;
  errorId: string | null | undefined;
  bugsnagId: string | null | undefined;
  errorName: string | null | undefined;
  bugsnagSessionId: string | null | undefined;
}
/**
 * systemErrorEncountered: When the user encounters an error anywhere in the product
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.errorPage: Where did this error occur?
eg. /se/sv/profile/patrick-lef/checkout
 * @param properties.errorId: memmos internal error trace id
 * @param properties.bugsnagId: What was the reported error id
 * @param properties.errorName: What was the exact error?
 * @param properties.bugsnagSessionId: the session id associated with the bugsnag error report
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/vef-c4yuMg}
 */
export function systemErrorEncountered(
  properties: SystemErrorEncounteredProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vef-c4yuMg", "9e0a3920b41353c0f8eb088857b15175e92fc4832ed24f0e9ff3db0b83176283", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("systemErrorEncountered", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "error_page": properties.errorPage,
      "error_id": properties.errorId,
      "bugsnag_id": properties.bugsnagId,
      "error_name": properties.errorName,
      "bugsnag_session_id": properties.bugsnagSessionId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vef-c4yuMg", "systemErrorEncountered", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "9ggmSln_j1N", name: "error_page", value: properties.errorPage},
      {id: "wLaqMS6_YyQ", name: "error_id", value: properties.errorId},
      {id: "L9La9bUrn8g", name: "bugsnag_id", value: properties.bugsnagId},
      {id: "6UKOjjPRReX", name: "error_name", value: properties.errorName},
      {id: "N01v43rGlT", name: "bugsnag_session_id", value: properties.bugsnagSessionId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["error_page"] = properties.errorPage;
  if (properties.errorId !== undefined && properties.errorId !== null) {
    eventProperties["error_id"] = properties.errorId;
  }
  if (properties.bugsnagId !== undefined && properties.bugsnagId !== null) {
    eventProperties["bugsnag_id"] = properties.bugsnagId;
  }
  if (properties.errorName !== undefined && properties.errorName !== null) {
    eventProperties["error_name"] = properties.errorName;
  }
  if (properties.bugsnagSessionId !== undefined && properties.bugsnagSessionId !== null) {
    eventProperties["bugsnag_session_id"] = properties.bugsnagSessionId;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("systemErrorEncountered", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "error_page": properties.errorPage,
        "error_id": properties.errorId,
        "bugsnag_id": properties.bugsnagId,
        "error_name": properties.errorName,
        "bugsnag_session_id": properties.bugsnagSessionId,
        "platform": sysPlatform,
        }, "vef-c4yuMg", "9e0a3920b41353c0f8eb088857b15175e92fc4832ed24f0e9ff3db0b83176283");
    }
    // destination Amplitude
    Amplitude.logEvent("systemErrorEncountered", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface SearchCompletedProperties {
  marketId: string;
  localeId: string;
  numberOfSearchResults: number;
  profileSearchResults: number;
  potentialProfilesSearchResults: number;
  keywords: string;
}
/**
 * searchCompleted: When the user performs a search, triggered after 3 characters have been entered
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.numberOfSearchResults: Number of search results returned
 * @param properties.profileSearchResults: Number of search results returned containing profiles
 * @param properties.potentialProfilesSearchResults: Number of search results returned containing potential profiles
 * @param properties.keywords: Keywords the user used
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/V7nMtmb5QA}
 */
export function searchCompleted(properties: SearchCompletedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V7nMtmb5QA", "af16f54595148865269cbbf504b665440b856796d22fe0a3a52bcbbe7c906107", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("searchCompleted", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "number_of_search_results": properties.numberOfSearchResults,
      "profile_search_results": properties.profileSearchResults,
      "potential_profiles_search_results": properties.potentialProfilesSearchResults,
      "keywords": properties.keywords,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V7nMtmb5QA", "searchCompleted", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "VSNIjDdrPJq", name: "number_of_search_results", value: properties.numberOfSearchResults},
      {id: "vsvBdaNUP-K", name: "profile_search_results", value: properties.profileSearchResults},
      {id: "siwZ6KFBD5j", name: "potential_profiles_search_results", value: properties.potentialProfilesSearchResults},
      {id: "oZxjwn6W-XT", name: "keywords", value: properties.keywords},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["number_of_search_results"] = properties.numberOfSearchResults;
  eventProperties["profile_search_results"] = properties.profileSearchResults;
  eventProperties["potential_profiles_search_results"] = properties.potentialProfilesSearchResults;
  eventProperties["keywords"] = properties.keywords;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("searchCompleted", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "number_of_search_results": properties.numberOfSearchResults,
        "profile_search_results": properties.profileSearchResults,
        "potential_profiles_search_results": properties.potentialProfilesSearchResults,
        "keywords": properties.keywords,
        "platform": sysPlatform,
        }, "V7nMtmb5QA", "af16f54595148865269cbbf504b665440b856796d22fe0a3a52bcbbe7c906107");
    }
    // destination Amplitude
    Amplitude.logEvent("searchCompleted", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface SearchResultClickedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType;
  productId: string;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string;
  discountAmountUsd: number;
  discountPercentage: number;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
  searchRank: number | null | undefined;
}
/**
 * searchResultClicked: When the user clicks on a search result
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.searchRank: Ranking of the search result that was clicked
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/zV49WHqE4LV}
 */
export function searchResultClicked(properties: SearchResultClickedProperties
  ) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zV49WHqE4LV", "3dce04473bdc5cc260f24fc95485382a8ee789b050871e9ea30b0724ff25d9c6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("searchResultClicked", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "search_rank": properties.searchRank,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zV49WHqE4LV", "searchResultClicked", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "i8VpsSp_j-N", name: "search_rank", value: properties.searchRank},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  eventProperties["product_product_type"] = properties.productProductType;
  eventProperties["product_id"] = properties.productId;
  eventProperties["product_slug"] = properties.productSlug;
  eventProperties["product_sale_mode"] = properties.productSaleMode;
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  eventProperties["discount_percentage"] = properties.discountPercentage;
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.searchRank !== undefined && properties.searchRank !== null) {
    eventProperties["search_rank"] = properties.searchRank;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("searchResultClicked", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "search_rank": properties.searchRank,
        "platform": sysPlatform,
        }, "zV49WHqE4LV", "3dce04473bdc5cc260f24fc95485382a8ee789b050871e9ea30b0724ff25d9c6");
    }
    // destination Amplitude
    Amplitude.logEvent("searchResultClicked", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface SearchResultPurchasedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType;
  productId: string;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string;
  discountAmountUsd: number;
  discountPercentage: number;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * searchResultPurchased: When the user buys a product they searched for and clicked on
(Algolia: convertedObjectIDsAfterSearch)
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/7RjBrTiFwcy}
 */
export function searchResultPurchased(
  properties: SearchResultPurchasedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7RjBrTiFwcy", "26e69ecacb660756355372e9945cce0e36a94643cdb60cc30b7f1988b3081a00", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("searchResultPurchased", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7RjBrTiFwcy", "searchResultPurchased", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  eventProperties["product_product_type"] = properties.productProductType;
  eventProperties["product_id"] = properties.productId;
  eventProperties["product_slug"] = properties.productSlug;
  eventProperties["product_sale_mode"] = properties.productSaleMode;
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  eventProperties["discount_percentage"] = properties.discountPercentage;
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("searchResultPurchased", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "7RjBrTiFwcy", "26e69ecacb660756355372e9945cce0e36a94643cdb60cc30b7f1988b3081a00");
    }
    // destination Amplitude
    Amplitude.logEvent("searchResultPurchased", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface ProfileProductCardPlayedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType;
  productId: string;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string;
  discountAmountUsd: number;
  discountPercentage: number;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
  listPosition: number | null | undefined;
}
/**
 * profileProductCardPlayed: When the users play the video for a profile
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.listPosition: Position in the listing is this video being played
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/yzYpgd_IOc0}
 */
export function profileProductCardPlayed(
  properties: ProfileProductCardPlayedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yzYpgd_IOc0", "f0422fc9ff31a30118cf794843b5a5d3b6894071d0cf7195c13c22d7ab5459c3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("profileProductCardPlayed", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "list_position": properties.listPosition,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yzYpgd_IOc0", "profileProductCardPlayed", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "nzICI9Z_jCb", name: "list_position", value: properties.listPosition},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  eventProperties["product_product_type"] = properties.productProductType;
  eventProperties["product_id"] = properties.productId;
  eventProperties["product_slug"] = properties.productSlug;
  eventProperties["product_sale_mode"] = properties.productSaleMode;
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  eventProperties["discount_percentage"] = properties.discountPercentage;
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.listPosition !== undefined && properties.listPosition !== null) {
    eventProperties["list_position"] = properties.listPosition;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("profileProductCardPlayed", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "list_position": properties.listPosition,
        "platform": sysPlatform,
        }, "yzYpgd_IOc0", "f0422fc9ff31a30118cf794843b5a5d3b6894071d0cf7195c13c22d7ab5459c3");
    }
    // destination Amplitude
    Amplitude.logEvent("profileProductCardPlayed", (Object as any).assign({}, eventProperties));

    // destination GTMClientSide
    GTMClientSide.logEvent("profileProductCardPlayed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProfileViewedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType | null | undefined;
  productId: string | null | undefined;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number | null | undefined;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string | null | undefined;
  discountAmountUsd: number | null | undefined;
  discountPercentage: number | null | undefined;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * profileViewed: When the user views a profile detail page
(GTM profileView, productDetails)
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/IG0qOHDrfQQ}
 */
export function profileViewed(properties: ProfileViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IG0qOHDrfQQ", "cbcecdf7e4511624e6ecfae84bd98b11574da76bdc74e79d31b1143b465a6473", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("profileViewed", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IG0qOHDrfQQ", "profileViewed", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  if (properties.productProductType !== undefined && properties.productProductType !== null) {
    eventProperties["product_product_type"] = properties.productProductType;
  }
  if (properties.productId !== undefined && properties.productId !== null) {
    eventProperties["product_id"] = properties.productId;
  }
  eventProperties["product_slug"] = properties.productSlug;
  eventProperties["product_sale_mode"] = properties.productSaleMode;
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  if (properties.profileProductPriceIncVatUsd !== undefined && properties.profileProductPriceIncVatUsd !== null) {
    eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  }
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  if (properties.profileProductPriceCurrency !== undefined && properties.profileProductPriceCurrency !== null) {
    eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  }
  if (properties.discountAmountUsd !== undefined && properties.discountAmountUsd !== null) {
    eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  }
  if (properties.discountPercentage !== undefined && properties.discountPercentage !== null) {
    eventProperties["discount_percentage"] = properties.discountPercentage;
  }
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("profileViewed", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "IG0qOHDrfQQ", "cbcecdf7e4511624e6ecfae84bd98b11574da76bdc74e79d31b1143b465a6473");
    }
    // destination Amplitude
    Amplitude.logEvent("profileViewed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CartProductAddedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType;
  productId: string;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType | null | undefined;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string;
  discountAmountUsd: number;
  discountPercentage: number;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string;
  marketId: string;
  localeId: string;
  orderId: string | null | undefined;
}
/**
 * cartProductAdded: • After users click on "Order from" button and see the request form
• When adding an addon
(GTM addToCart)
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/2_rVMqONqmw/trigger/n-rU2YWvm
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.orderId: Id of the order related to the request
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/2_rVMqONqmw}
 */
export function cartProductAdded(properties: CartProductAddedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2_rVMqONqmw", "5092a60b1f1d4875dade0c01642d6ea8b3df36f2c3bdc0e4607af85911143055", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("cartProductAdded", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "order_id": properties.orderId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2_rVMqONqmw", "cartProductAdded", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "yvxBv-g1SrF", name: "order_id", value: properties.orderId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  eventProperties["product_product_type"] = properties.productProductType;
  eventProperties["product_id"] = properties.productId;
  eventProperties["product_slug"] = properties.productSlug;
  if (properties.productSaleMode !== undefined && properties.productSaleMode !== null) {
    eventProperties["product_sale_mode"] = properties.productSaleMode;
  }
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  eventProperties["discount_percentage"] = properties.discountPercentage;
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.orderId !== undefined && properties.orderId !== null) {
    eventProperties["order_id"] = properties.orderId;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("cartProductAdded", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "order_id": properties.orderId,
        "platform": sysPlatform,
        }, "2_rVMqONqmw", "5092a60b1f1d4875dade0c01642d6ea8b3df36f2c3bdc0e4607af85911143055");
    }
    // destination Amplitude
    Amplitude.logEvent("cartProductAdded", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CartProductRemovedProperties {
  productName: string;
  productProductType: ProductProductTypeValueType;
  productId: string;
  productSlug: string;
  productSaleMode: ProductSaleModeValueType;
  productVatType: ProductVatTypeValueType;
  profileProductId: string;
  profileProductPriceIncVatUsd: number;
  profileProductAcceptingOrders: boolean;
  profileProductPriceCurrency: string;
  discountAmountUsd: number;
  discountPercentage: number;
  hasDiscount: boolean;
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
  orderId: string | null | undefined;
}
/**
 * cartProductRemoved: When the user removes an addon or other product from the cart
 *
 * @param properties the properties associatied with this event
 * @param properties.productName: Name of the product
 * @param properties.productProductType: Type of product
 * @param properties.productId: Internal product identifier
 * @param properties.productSlug: The url part for the product
 * @param properties.productSaleMode: What's the target audience for the product
 * @param properties.productVatType: What's the VAT type
 * @param properties.profileProductId: The relation id mixing profile + product
 * @param properties.profileProductPriceIncVatUsd: Price of the product in usd cent of priceCurrency
 * @param properties.profileProductAcceptingOrders: Is this profile accepting orders for this product
 * @param properties.profileProductPriceCurrency: Currency for the price
 * @param properties.discountAmountUsd: Discount amount in usd cent
 * @param properties.discountPercentage: Discount percentage
 * @param properties.hasDiscount: If it has a discount
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.orderId: Id of the order related to the request
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/a1sijjfCL9h}
 */
export function cartProductRemoved(properties: CartProductRemovedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "a1sijjfCL9h", "1c0bac5b8a039f6dc83c19226ea8b8e40b080f2f9057839ffaa47825702a99de", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("cartProductRemoved", {
      "product_name": properties.productName,
      "product_product_type": properties.productProductType,
      "product_id": properties.productId,
      "product_slug": properties.productSlug,
      "product_sale_mode": properties.productSaleMode,
      "product_vat_type": properties.productVatType,
      "profile_product_id": properties.profileProductId,
      "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
      "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
      "profile_product_price_currency": properties.profileProductPriceCurrency,
      "discount_amount_usd": properties.discountAmountUsd,
      "discount_percentage": properties.discountPercentage,
      "has_discount": properties.hasDiscount,
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "order_id": properties.orderId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("a1sijjfCL9h", "cartProductRemoved", messages, [
      {id: "B4ATUfes-__", name: "product_name", value: properties.productName},
      {id: "cLHhQLoiEEj", name: "product_product_type", value: properties.productProductType},
      {id: "LTs1DzmVwZa", name: "product_id", value: properties.productId},
      {id: "pI4WjCa0_Gw", name: "product_slug", value: properties.productSlug},
      {id: "4Yh0wzHZeup", name: "product_sale_mode", value: properties.productSaleMode},
      {id: "WF6PFR6n9cg", name: "product_vat_type", value: properties.productVatType},
      {id: "Fuxq501xNX6", name: "profile_product_id", value: properties.profileProductId},
      {id: "WXrrxLX9V0r", name: "profile_product_price_inc_vat_usd", value: properties.profileProductPriceIncVatUsd},
      {id: "1bVhW8MYEQY", name: "profile_product_accepting_orders", value: properties.profileProductAcceptingOrders},
      {id: "wLVa_7CVVoW", name: "profile_product_price_currency", value: properties.profileProductPriceCurrency},
      {id: "p7dAJNInVRs", name: "discount_amount_usd", value: properties.discountAmountUsd},
      {id: "3LsXlFwRL4m", name: "discount_percentage", value: properties.discountPercentage},
      {id: "tcizJ0d8FzN", name: "has_discount", value: properties.hasDiscount},
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "yvxBv-g1SrF", name: "order_id", value: properties.orderId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["product_name"] = properties.productName;
  eventProperties["product_product_type"] = properties.productProductType;
  eventProperties["product_id"] = properties.productId;
  eventProperties["product_slug"] = properties.productSlug;
  eventProperties["product_sale_mode"] = properties.productSaleMode;
  eventProperties["product_vat_type"] = properties.productVatType;
  eventProperties["profile_product_id"] = properties.profileProductId;
  eventProperties["profile_product_price_inc_vat_usd"] = properties.profileProductPriceIncVatUsd;
  eventProperties["profile_product_accepting_orders"] = properties.profileProductAcceptingOrders;
  eventProperties["profile_product_price_currency"] = properties.profileProductPriceCurrency;
  eventProperties["discount_amount_usd"] = properties.discountAmountUsd;
  eventProperties["discount_percentage"] = properties.discountPercentage;
  eventProperties["has_discount"] = properties.hasDiscount;
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.orderId !== undefined && properties.orderId !== null) {
    eventProperties["order_id"] = properties.orderId;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("cartProductRemoved", {
        "product_name": properties.productName,
        "product_product_type": properties.productProductType,
        "product_id": properties.productId,
        "product_slug": properties.productSlug,
        "product_sale_mode": properties.productSaleMode,
        "product_vat_type": properties.productVatType,
        "profile_product_id": properties.profileProductId,
        "profile_product_price_inc_vat_usd": properties.profileProductPriceIncVatUsd,
        "profile_product_accepting_orders": properties.profileProductAcceptingOrders,
        "profile_product_price_currency": properties.profileProductPriceCurrency,
        "discount_amount_usd": properties.discountAmountUsd,
        "discount_percentage": properties.discountPercentage,
        "has_discount": properties.hasDiscount,
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "order_id": properties.orderId,
        "platform": sysPlatform,
        }, "a1sijjfCL9h", "1c0bac5b8a039f6dc83c19226ea8b8e40b080f2f9057839ffaa47825702a99de");
    }
    // destination Amplitude
    Amplitude.logEvent("cartProductRemoved", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CheckoutStepStartedProperties {
  orderId: string | null | undefined;
  orderTotalPriceIncVatUsd: number | null | undefined;
  orderItemCount: number | null | undefined;
  paymentMethod: string | null | undefined;
  couponCode: string | null | undefined;
  couponId: string | null | undefined;
  productTypes: string[] | null | undefined;
  marketId: string;
  localeId: string;
  checkoutStepId: number | null | undefined;
  checkoutStepName: CheckoutStepNameValueType | null | undefined;
}
/**
 * checkoutStepStarted: Once every step is started in checkout
(GTM checkout)
 *
 * @param properties the properties associatied with this event
 * @param properties.orderId: Id of the order related to the request
 * @param properties.orderTotalPriceIncVatUsd: Current total value of the user's cart, so with discounts but not including taxes and shipping
 * @param properties.orderItemCount: Current total number of products in the user's cart
 * @param properties.paymentMethod: What payment method the user selected to use
 * @param properties.couponCode: The code the user entered
 * @param properties.couponId: Internal id of coupon
 * @param properties.productTypes: List of product types included in the order. For example VIDEO_REQUEST,SERVICE_FEE. Use this for example to only see donation orders
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.checkoutStepId: The checkout step the user just started
 * @param properties.checkoutStepName: Name of the checkout step. Syncs with the ID
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/wKsYmcZNay3}
 */
export function checkoutStepStarted(properties: CheckoutStepStartedProperties
  ) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wKsYmcZNay3", "b697ee0d9ddbd3ab3b7b7910a786fce6c28b50337ecb19f93d8d822436218454", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("checkoutStepStarted", {
      "order_id": properties.orderId,
      "order_total_price_inc_vat_usd": properties.orderTotalPriceIncVatUsd,
      "order_item_count": properties.orderItemCount,
      "payment_method": properties.paymentMethod,
      "coupon_code": properties.couponCode,
      "coupon_id": properties.couponId,
      "product_types": properties.productTypes,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "checkout_step_id": properties.checkoutStepId,
      "checkout_step_name": properties.checkoutStepName,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wKsYmcZNay3", "checkoutStepStarted", messages, [
      {id: "yvxBv-g1SrF", name: "order_id", value: properties.orderId},
      {id: "1Tuken0qOeI", name: "order_total_price_inc_vat_usd", value: properties.orderTotalPriceIncVatUsd},
      {id: "1TxOZhNn1ZF", name: "order_item_count", value: properties.orderItemCount},
      {id: "7iPYcq2oTsB", name: "payment_method", value: properties.paymentMethod},
      {id: "RrRIsh9B8HD", name: "coupon_code", value: properties.couponCode},
      {id: "xjUO600EF9W", name: "coupon_id", value: properties.couponId},
      {id: "4AoIrv93At", name: "product_types", value: properties.productTypes},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "FPiRNQ9KXNx", name: "checkout_step_id", value: properties.checkoutStepId},
      {id: "1rYsqP_m4Lj", name: "checkout_step_name", value: properties.checkoutStepName},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.orderId !== undefined && properties.orderId !== null) {
    eventProperties["order_id"] = properties.orderId;
  }
  if (properties.orderTotalPriceIncVatUsd !== undefined && properties.orderTotalPriceIncVatUsd !== null) {
    eventProperties["order_total_price_inc_vat_usd"] = properties.orderTotalPriceIncVatUsd;
  }
  if (properties.orderItemCount !== undefined && properties.orderItemCount !== null) {
    eventProperties["order_item_count"] = properties.orderItemCount;
  }
  if (properties.paymentMethod !== undefined && properties.paymentMethod !== null) {
    eventProperties["payment_method"] = properties.paymentMethod;
  }
  if (properties.couponCode !== undefined && properties.couponCode !== null) {
    eventProperties["coupon_code"] = properties.couponCode;
  }
  if (properties.couponId !== undefined && properties.couponId !== null) {
    eventProperties["coupon_id"] = properties.couponId;
  }
  if (properties.productTypes !== undefined && properties.productTypes !== null) {
    eventProperties["product_types"] = properties.productTypes;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.checkoutStepId !== undefined && properties.checkoutStepId !== null) {
    eventProperties["checkout_step_id"] = properties.checkoutStepId;
  }
  if (properties.checkoutStepName !== undefined && properties.checkoutStepName !== null) {
    eventProperties["checkout_step_name"] = properties.checkoutStepName;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("checkoutStepStarted", {
        "order_id": properties.orderId,
        "order_total_price_inc_vat_usd": properties.orderTotalPriceIncVatUsd,
        "order_item_count": properties.orderItemCount,
        "payment_method": properties.paymentMethod,
        "coupon_code": properties.couponCode,
        "coupon_id": properties.couponId,
        "product_types": properties.productTypes,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "checkout_step_id": properties.checkoutStepId,
        "checkout_step_name": properties.checkoutStepName,
        "platform": sysPlatform,
        }, "wKsYmcZNay3", "b697ee0d9ddbd3ab3b7b7910a786fce6c28b50337ecb19f93d8d822436218454");
    }
    // destination Amplitude
    Amplitude.logEvent("checkoutStepStarted", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CouponAppliedProperties {
  orderId: string | null | undefined;
  orderTotalPriceIncVatUsd: number | null | undefined;
  orderItemCount: number | null | undefined;
  paymentMethod: string | null | undefined;
  couponCode: string | null | undefined;
  couponId: string | null | undefined;
  productTypes: string[] | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * couponApplied: When the user adds a coupon
(CDP: add-coupon)
 *
 * @param properties the properties associatied with this event
 * @param properties.orderId: Id of the order related to the request
 * @param properties.orderTotalPriceIncVatUsd: Current total value of the user's cart, so with discounts but not including taxes and shipping
 * @param properties.orderItemCount: Current total number of products in the user's cart
 * @param properties.paymentMethod: What payment method the user selected to use
 * @param properties.couponCode: The code the user entered
 * @param properties.couponId: Internal id of coupon
 * @param properties.productTypes: List of product types included in the order. For example VIDEO_REQUEST,SERVICE_FEE. Use this for example to only see donation orders
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/lC7RUW30-nS}
 */
export function couponApplied(properties: CouponAppliedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lC7RUW30-nS", "065d6a43c2af57e05957802692b59f94fafbcdfe5eef77dd447c538eb1142e52", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("couponApplied", {
      "order_id": properties.orderId,
      "order_total_price_inc_vat_usd": properties.orderTotalPriceIncVatUsd,
      "order_item_count": properties.orderItemCount,
      "payment_method": properties.paymentMethod,
      "coupon_code": properties.couponCode,
      "coupon_id": properties.couponId,
      "product_types": properties.productTypes,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lC7RUW30-nS", "couponApplied", messages, [
      {id: "yvxBv-g1SrF", name: "order_id", value: properties.orderId},
      {id: "1Tuken0qOeI", name: "order_total_price_inc_vat_usd", value: properties.orderTotalPriceIncVatUsd},
      {id: "1TxOZhNn1ZF", name: "order_item_count", value: properties.orderItemCount},
      {id: "7iPYcq2oTsB", name: "payment_method", value: properties.paymentMethod},
      {id: "RrRIsh9B8HD", name: "coupon_code", value: properties.couponCode},
      {id: "xjUO600EF9W", name: "coupon_id", value: properties.couponId},
      {id: "4AoIrv93At", name: "product_types", value: properties.productTypes},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.orderId !== undefined && properties.orderId !== null) {
    eventProperties["order_id"] = properties.orderId;
  }
  if (properties.orderTotalPriceIncVatUsd !== undefined && properties.orderTotalPriceIncVatUsd !== null) {
    eventProperties["order_total_price_inc_vat_usd"] = properties.orderTotalPriceIncVatUsd;
  }
  if (properties.orderItemCount !== undefined && properties.orderItemCount !== null) {
    eventProperties["order_item_count"] = properties.orderItemCount;
  }
  if (properties.paymentMethod !== undefined && properties.paymentMethod !== null) {
    eventProperties["payment_method"] = properties.paymentMethod;
  }
  if (properties.couponCode !== undefined && properties.couponCode !== null) {
    eventProperties["coupon_code"] = properties.couponCode;
  }
  if (properties.couponId !== undefined && properties.couponId !== null) {
    eventProperties["coupon_id"] = properties.couponId;
  }
  if (properties.productTypes !== undefined && properties.productTypes !== null) {
    eventProperties["product_types"] = properties.productTypes;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("couponApplied", {
        "order_id": properties.orderId,
        "order_total_price_inc_vat_usd": properties.orderTotalPriceIncVatUsd,
        "order_item_count": properties.orderItemCount,
        "payment_method": properties.paymentMethod,
        "coupon_code": properties.couponCode,
        "coupon_id": properties.couponId,
        "product_types": properties.productTypes,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "lC7RUW30-nS", "065d6a43c2af57e05957802692b59f94fafbcdfe5eef77dd447c538eb1142e52");
    }
    // destination Amplitude
    Amplitude.logEvent("couponApplied", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface NewsletterSubscribedProperties {
  marketId: string;
  localeId: string;
}
/**
 * newsletterSubscribed: Emitted when signup on newsletter on web
(GTM: newsletterSubscription)
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/F_isf5uDoys}
 */
export function newsletterSubscribed(
  properties: NewsletterSubscribedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "F_isf5uDoys", "a734fbaa458b4dbc6f3edd865f81a0dab2036092b60bfc4b5be1cf70906c3eb9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("newsletterSubscribed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("F_isf5uDoys", "newsletterSubscribed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("newsletterSubscribed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "F_isf5uDoys", "a734fbaa458b4dbc6f3edd865f81a0dab2036092b60bfc4b5be1cf70906c3eb9");
    }
    // destination Amplitude
    Amplitude.logEvent("newsletterSubscribed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface PotentialProfileSubscribedProperties {
  marketId: string;
  localeId: string;
  potentialProfileName: string | null | undefined;
  potentialProfileId: string | null | undefined;
}
/**
 * potentialProfileSubscribed: Emitted when signup on potential profile page
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.potentialProfileName: Name of the potential profile
 * @param properties.potentialProfileId: Id of the potential profile
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/FLNvryIOrYE}
 */
export function potentialProfileSubscribed(
  properties: PotentialProfileSubscribedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLNvryIOrYE", "2c4be41785e47e13d0f75b122d69f24af6d0db9e4fe1624c0554f8e6d27fef80", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("potentialProfileSubscribed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "potential_profile_name": properties.potentialProfileName,
      "potential_profile_id": properties.potentialProfileId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLNvryIOrYE", "potentialProfileSubscribed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "VHEEbGYwrhN", name: "potential_profile_name", value: properties.potentialProfileName},
      {id: "p2y_zYHiP2S", name: "potential_profile_id", value: properties.potentialProfileId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.potentialProfileName !== undefined && properties.potentialProfileName !== null) {
    eventProperties["potential_profile_name"] = properties.potentialProfileName;
  }
  if (properties.potentialProfileId !== undefined && properties.potentialProfileId !== null) {
    eventProperties["potential_profile_id"] = properties.potentialProfileId;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("potentialProfileSubscribed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "potential_profile_name": properties.potentialProfileName,
        "potential_profile_id": properties.potentialProfileId,
        "platform": sysPlatform,
        }, "FLNvryIOrYE", "2c4be41785e47e13d0f75b122d69f24af6d0db9e4fe1624c0554f8e6d27fef80");
    }
    // destination Amplitude
    Amplitude.logEvent("potentialProfileSubscribed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface ProfileSubscribedProperties {
  profileTags: string[] | null | undefined;
  profileId: string | null | undefined;
  profileSlug: string | null | undefined;
  profileAcceptingOrders: boolean | null | undefined;
  profileName: string;
  profileOnboardingCompletedAt: string | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * profileSubscribed: Emitted when signup on profile page
 *
 * @param properties the properties associatied with this event
 * @param properties.profileTags: Array of tags on the profile as strings
 * @param properties.profileId: ID of the profile
 * @param properties.profileSlug: The url part for the profile
 * @param properties.profileAcceptingOrders: Is this profile overall accepting orders
 * @param properties.profileName: The name of the profile
 * @param properties.profileOnboardingCompletedAt: The time a profile completed onboarding in app and got visible on the site
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/Gx6CT18YSrC}
 */
export function profileSubscribed(properties: ProfileSubscribedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Gx6CT18YSrC", "adc5a79f15f8df7bd2ac6d578b76a222e28cd455b756bec96910c526c43fdc60", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("profileSubscribed", {
      "profile_tags": properties.profileTags,
      "profile_id": properties.profileId,
      "profile_slug": properties.profileSlug,
      "profile_accepting_orders": properties.profileAcceptingOrders,
      "profile_name": properties.profileName,
      "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Gx6CT18YSrC", "profileSubscribed", messages, [
      {id: "qBk20Xnh62o", name: "profile_tags", value: properties.profileTags},
      {id: "j7y3itd3SdY", name: "profile_id", value: properties.profileId},
      {id: "2TXY17Ngu68", name: "profile_slug", value: properties.profileSlug},
      {id: "LUSUcwqDsd8", name: "profile_accepting_orders", value: properties.profileAcceptingOrders},
      {id: "ZGRuvSktF8D", name: "profile_name", value: properties.profileName},
      {id: "LXYo-fgqQLS", name: "profile_onboarding_completed_at", value: properties.profileOnboardingCompletedAt},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.profileTags !== undefined && properties.profileTags !== null) {
    eventProperties["profile_tags"] = properties.profileTags;
  }
  if (properties.profileId !== undefined && properties.profileId !== null) {
    eventProperties["profile_id"] = properties.profileId;
  }
  if (properties.profileSlug !== undefined && properties.profileSlug !== null) {
    eventProperties["profile_slug"] = properties.profileSlug;
  }
  if (properties.profileAcceptingOrders !== undefined && properties.profileAcceptingOrders !== null) {
    eventProperties["profile_accepting_orders"] = properties.profileAcceptingOrders;
  }
  eventProperties["profile_name"] = properties.profileName;
  if (properties.profileOnboardingCompletedAt !== undefined && properties.profileOnboardingCompletedAt !== null) {
    eventProperties["profile_onboarding_completed_at"] = properties.profileOnboardingCompletedAt;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("profileSubscribed", {
        "profile_tags": properties.profileTags,
        "profile_id": properties.profileId,
        "profile_slug": properties.profileSlug,
        "profile_accepting_orders": properties.profileAcceptingOrders,
        "profile_name": properties.profileName,
        "profile_onboarding_completed_at": properties.profileOnboardingCompletedAt,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "Gx6CT18YSrC", "adc5a79f15f8df7bd2ac6d578b76a222e28cd455b756bec96910c526c43fdc60");
    }
    // destination Amplitude
    Amplitude.logEvent("profileSubscribed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CustomFormSubmittedProperties {
  marketId: string;
  localeId: string;
  customFormId: string | null | undefined;
  formSubmissionId: string | null | undefined;
  customFormType: string | null | undefined;
  customFormDataName: string | null | undefined;
}
/**
 * customFormSubmitted: When filing out a custom form (For example b2b interest)
(GTM: customFormSubmit)
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.customFormId: The id of the form
 * @param properties.formSubmissionId: The id of the form submission
 * @param properties.customFormType: The form type
 * @param properties.customFormDataName: Name field in the form
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/K3B1M403cth}
 */
export function customFormSubmitted(properties: CustomFormSubmittedProperties
  ) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "K3B1M403cth", "04be32872c5d0fc378c5135145753f483e46e3875b407d56cfb90d3b8b93e844", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("customFormSubmitted", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "custom_form_id": properties.customFormId,
      "form_submission_id": properties.formSubmissionId,
      "custom_form_type": properties.customFormType,
      "custom_form_data_name": properties.customFormDataName,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("K3B1M403cth", "customFormSubmitted", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "Eie2p0MgDPR", name: "custom_form_id", value: properties.customFormId},
      {id: "zsYfDSczcCc", name: "form_submission_id", value: properties.formSubmissionId},
      {id: "lMKcaqO7s5F", name: "custom_form_type", value: properties.customFormType},
      {id: "2Ip7hyVyxSC", name: "custom_form_data_name", value: properties.customFormDataName},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.customFormId !== undefined && properties.customFormId !== null) {
    eventProperties["custom_form_id"] = properties.customFormId;
  }
  if (properties.formSubmissionId !== undefined && properties.formSubmissionId !== null) {
    eventProperties["form_submission_id"] = properties.formSubmissionId;
  }
  if (properties.customFormType !== undefined && properties.customFormType !== null) {
    eventProperties["custom_form_type"] = properties.customFormType;
  }
  if (properties.customFormDataName !== undefined && properties.customFormDataName !== null) {
    eventProperties["custom_form_data_name"] = properties.customFormDataName;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("customFormSubmitted", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "custom_form_id": properties.customFormId,
        "form_submission_id": properties.formSubmissionId,
        "custom_form_type": properties.customFormType,
        "custom_form_data_name": properties.customFormDataName,
        "platform": sysPlatform,
        }, "K3B1M403cth", "04be32872c5d0fc378c5135145753f483e46e3875b407d56cfb90d3b8b93e844");
    }
    // destination Amplitude
    Amplitude.logEvent("customFormSubmitted", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface PotentialProfileSuggestedProperties {
  marketId: string;
  localeId: string;
  name: string | null | undefined;
  description: string | null | undefined;
  potentialProfileId: string | null | undefined;
}
/**
 * potentialProfileSuggested: When a visitor suggest that a profile should be added to memmo
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.name: Name of the suggested profile
 * @param properties.description: Description of why they want the profile
 * @param properties.potentialProfileId: Id of the potential profile
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/Z6JPeF60NYl}
 */
export function potentialProfileSuggested(
  properties: PotentialProfileSuggestedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Z6JPeF60NYl", "a4fe99b0fae03f5903d0b4ebc4418b5d3d112f91dffee1f3af5f8f96e8568d54", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("potentialProfileSuggested", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "name": properties.name,
      "description": properties.description,
      "potential_profile_id": properties.potentialProfileId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Z6JPeF60NYl", "potentialProfileSuggested", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "8vqVDMlh-7a", name: "name", value: properties.name},
      {id: "EhRWWqoXBG6", name: "description", value: properties.description},
      {id: "p2y_zYHiP2S", name: "potential_profile_id", value: properties.potentialProfileId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.name !== undefined && properties.name !== null) {
    eventProperties["name"] = properties.name;
  }
  if (properties.description !== undefined && properties.description !== null) {
    eventProperties["description"] = properties.description;
  }
  if (properties.potentialProfileId !== undefined && properties.potentialProfileId !== null) {
    eventProperties["potential_profile_id"] = properties.potentialProfileId;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("potentialProfileSuggested", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "name": properties.name,
        "description": properties.description,
        "potential_profile_id": properties.potentialProfileId,
        "platform": sysPlatform,
        }, "Z6JPeF60NYl", "a4fe99b0fae03f5903d0b4ebc4418b5d3d112f91dffee1f3af5f8f96e8568d54");
    }
    // destination Amplitude
    Amplitude.logEvent("potentialProfileSuggested", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface VideoRequestPageViewedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
  isBoughtByVisitor: boolean | null | undefined;
}
/**
 * videoRequestPageViewed: A person has visited a video request page
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.isBoughtByVisitor: If the current person triggering the event purchased the video
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/fe3IlKwC15P}
 */
export function videoRequestPageViewed(
  properties: VideoRequestPageViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fe3IlKwC15P", "139b319dec3e8883477316ccbaa3ef8694ac1e0c96c36d9815fa1babfc84eaad", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("videoRequestPageViewed", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "is_bought_by_visitor": properties.isBoughtByVisitor,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fe3IlKwC15P", "videoRequestPageViewed", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "AProuSsOamq-", name: "is_bought_by_visitor", value: properties.isBoughtByVisitor},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.isBoughtByVisitor !== undefined && properties.isBoughtByVisitor !== null) {
    eventProperties["is_bought_by_visitor"] = properties.isBoughtByVisitor;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("videoRequestPageViewed", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "is_bought_by_visitor": properties.isBoughtByVisitor,
        "platform": sysPlatform,
        }, "fe3IlKwC15P", "139b319dec3e8883477316ccbaa3ef8694ac1e0c96c36d9815fa1babfc84eaad");
    }
    // destination Amplitude
    Amplitude.logEvent("videoRequestPageViewed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface VideoRequestPlayedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
  isBoughtByVisitor: boolean | null | undefined;
}
/**
 * videoRequestPlayed: When a video request is played
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.isBoughtByVisitor: If the current person triggering the event purchased the video
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/WIDtyaFeXfF}
 */
export function videoRequestPlayed(properties: VideoRequestPlayedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WIDtyaFeXfF", "8808b318a7c1e5852dafbfcea105137cb6c6aaace4affae3b4711f0455a24466", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("videoRequestPlayed", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "is_bought_by_visitor": properties.isBoughtByVisitor,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WIDtyaFeXfF", "videoRequestPlayed", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "AProuSsOamq-", name: "is_bought_by_visitor", value: properties.isBoughtByVisitor},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.isBoughtByVisitor !== undefined && properties.isBoughtByVisitor !== null) {
    eventProperties["is_bought_by_visitor"] = properties.isBoughtByVisitor;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("videoRequestPlayed", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "is_bought_by_visitor": properties.isBoughtByVisitor,
        "platform": sysPlatform,
        }, "WIDtyaFeXfF", "8808b318a7c1e5852dafbfcea105137cb6c6aaace4affae3b4711f0455a24466");
    }
    // destination Amplitude
    Amplitude.logEvent("videoRequestPlayed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface VideoRequestSharedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
  isBoughtByVisitor: boolean | null | undefined;
}
/**
 * videoRequestShared: When clicking sharing buttons on the video request page
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.isBoughtByVisitor: If the current person triggering the event purchased the video
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/R1I23e7ObsY}
 */
export function videoRequestShared(properties: VideoRequestSharedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "R1I23e7ObsY", "341fc7965e6862035ba6b006079450fe3435ee429b73842092d8bd8a817d5fa3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("videoRequestShared", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "is_bought_by_visitor": properties.isBoughtByVisitor,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("R1I23e7ObsY", "videoRequestShared", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "AProuSsOamq-", name: "is_bought_by_visitor", value: properties.isBoughtByVisitor},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.isBoughtByVisitor !== undefined && properties.isBoughtByVisitor !== null) {
    eventProperties["is_bought_by_visitor"] = properties.isBoughtByVisitor;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("videoRequestShared", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "is_bought_by_visitor": properties.isBoughtByVisitor,
        "platform": sysPlatform,
        }, "R1I23e7ObsY", "341fc7965e6862035ba6b006079450fe3435ee429b73842092d8bd8a817d5fa3");
    }
    // destination Amplitude
    Amplitude.logEvent("videoRequestShared", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface VideoRequestDownloadedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
  isBoughtByVisitor: boolean | null | undefined;
}
/**
 * videoRequestDownloaded: When clicking download button on videor equest page
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.isBoughtByVisitor: If the current person triggering the event purchased the video
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/jTav_h79HXR}
 */
export function videoRequestDownloaded(
  properties: VideoRequestDownloadedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jTav_h79HXR", "f19cfe51d38f90774ebd29f3f45956f80590863d8dcdf6c102f52ba64174896b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("videoRequestDownloaded", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "is_bought_by_visitor": properties.isBoughtByVisitor,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jTav_h79HXR", "videoRequestDownloaded", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "AProuSsOamq-", name: "is_bought_by_visitor", value: properties.isBoughtByVisitor},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.isBoughtByVisitor !== undefined && properties.isBoughtByVisitor !== null) {
    eventProperties["is_bought_by_visitor"] = properties.isBoughtByVisitor;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("videoRequestDownloaded", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "is_bought_by_visitor": properties.isBoughtByVisitor,
        "platform": sysPlatform,
        }, "jTav_h79HXR", "f19cfe51d38f90774ebd29f3f45956f80590863d8dcdf6c102f52ba64174896b");
    }
    // destination Amplitude
    Amplitude.logEvent("videoRequestDownloaded", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface VideoRequestBookAgainClickedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * videoRequestBookAgainClicked: When clicking book again
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/hR_LjuyIFlg}
 */
export function videoRequestBookAgainClicked(
  properties: VideoRequestBookAgainClickedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hR_LjuyIFlg", "c06113360ba1184b7d40cae47d4b56477717b58b94b53eef6887cd49e9a6d84f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("videoRequestBookAgainClicked", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hR_LjuyIFlg", "videoRequestBookAgainClicked", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("videoRequestBookAgainClicked", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "hR_LjuyIFlg", "c06113360ba1184b7d40cae47d4b56477717b58b94b53eef6887cd49e9a6d84f");
    }
    // destination Amplitude
    Amplitude.logEvent("videoRequestBookAgainClicked", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface ReactionVideoUploadedProperties {
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  marketId: string;
  localeId: string;
}
/**
 * reactionVideoUploaded: When a customer uploads a video of the reaction
 *
 * @param properties the properties associatied with this event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/Jn0TCbA9Lp0}
 */
export function reactionVideoUploaded(
  properties: ReactionVideoUploadedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Jn0TCbA9Lp0", "79c10554d1aa67c5815c5b7f631f52d450136efe2fbc3040860d287d0c8903c1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("reactionVideoUploaded", {
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Jn0TCbA9Lp0", "reactionVideoUploaded", messages, [
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("reactionVideoUploaded", {
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "platform": sysPlatform,
        }, "Jn0TCbA9Lp0", "79c10554d1aa67c5815c5b7f631f52d450136efe2fbc3040860d287d0c8903c1");
    }
    // destination Amplitude
    Amplitude.logEvent("reactionVideoUploaded", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface LandingPageViewedProperties {
  marketId: string;
  localeId: string;
  path: string | null | undefined;
  pageSlug: string | null | undefined;
}
/**
 * landingPageViewed: When a landing page is viewed
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.path: path of the page viewed
 * @param properties.pageSlug: The slug for the url of the page
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/VuQSP_gm3fA}
 */
export function landingPageViewed(properties: LandingPageViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "VuQSP_gm3fA", "9ab9d40e08048002a5da66d154c1932610b2cfb26bd20ac624f595804b2851ad", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("landingPageViewed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "path": properties.path,
      "page_slug": properties.pageSlug,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("VuQSP_gm3fA", "landingPageViewed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "rdkXBSadme", name: "path", value: properties.path},
      {id: "eVaMR9zKbe8O", name: "page_slug", value: properties.pageSlug},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.path !== undefined && properties.path !== null) {
    eventProperties["path"] = properties.path;
  }
  if (properties.pageSlug !== undefined && properties.pageSlug !== null) {
    eventProperties["page_slug"] = properties.pageSlug;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("landingPageViewed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "path": properties.path,
        "page_slug": properties.pageSlug,
        "platform": sysPlatform,
        }, "VuQSP_gm3fA", "9ab9d40e08048002a5da66d154c1932610b2cfb26bd20ac624f595804b2851ad");
    }
    // destination Amplitude
    Amplitude.logEvent("landingPageViewed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface CmsPageViewedProperties {
  marketId: string;
  localeId: string;
  pageSlug: string | null | undefined;
  path: string | null | undefined;
}
/**
 * cmsPageViewed: When a cms page is viewed. For example Birthday page
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.pageSlug: The slug for the url of the page
 * @param properties.path: path of the page viewed
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/29Yx3DUHll7}
 */
export function cmsPageViewed(properties: CmsPageViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "29Yx3DUHll7", "953bddbdd40fe810fb360441d9e4dea7fbde49fffacbfd207ce0ff5bfc277e2b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("cmsPageViewed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "page_slug": properties.pageSlug,
      "path": properties.path,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("29Yx3DUHll7", "cmsPageViewed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "eVaMR9zKbe8O", name: "page_slug", value: properties.pageSlug},
      {id: "rdkXBSadme", name: "path", value: properties.path},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.pageSlug !== undefined && properties.pageSlug !== null) {
    eventProperties["page_slug"] = properties.pageSlug;
  }
  if (properties.path !== undefined && properties.path !== null) {
    eventProperties["path"] = properties.path;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("cmsPageViewed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "page_slug": properties.pageSlug,
        "path": properties.path,
        "platform": sysPlatform,
        }, "29Yx3DUHll7", "953bddbdd40fe810fb360441d9e4dea7fbde49fffacbfd207ce0ff5bfc277e2b");
    }
    // destination Amplitude
    Amplitude.logEvent("cmsPageViewed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface ProfileCollectionPageViewedProperties {
  marketId: string;
  localeId: string;
  profileCollectionId: string | null | undefined;
  profileCollectionSlug: string | null | undefined;
  profileCollectionType: string | null | undefined;
  profileCollectionProfileCount: number | null | undefined;
  path: string | null | undefined;
  pageSlug: string | null | undefined;
}
/**
 * profileCollectionPageViewed: When a page listing products is viewed
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.profileCollectionId: Id of the collection
 * @param properties.profileCollectionSlug: The slug for the collection used in urls
 * @param properties.profileCollectionType: The type of collection (what data is used to build it)
 * @param properties.profileCollectionProfileCount: Number of profiles in the collection
 * @param properties.path: path of the page viewed
 * @param properties.pageSlug: The slug for the url of the page
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/PKocbIH3NLl}
 */
export function profileCollectionPageViewed(
  properties: ProfileCollectionPageViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "PKocbIH3NLl", "bb417ff019631814600c51bf0b7ed663ca07863a2d698179ee45fce2846ab91e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("profileCollectionPageViewed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "profile_collection_id": properties.profileCollectionId,
      "profile_collection_slug": properties.profileCollectionSlug,
      "profile_collection_type": properties.profileCollectionType,
      "profile_collection_profile_count": properties.profileCollectionProfileCount,
      "path": properties.path,
      "page_slug": properties.pageSlug,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("PKocbIH3NLl", "profileCollectionPageViewed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "BW2KXY07w5f0", name: "profile_collection_id", value: properties.profileCollectionId},
      {id: "oniS7pUR5YVK", name: "profile_collection_slug", value: properties.profileCollectionSlug},
      {id: "SB5q3Ifi_DHb", name: "profile_collection_type", value: properties.profileCollectionType},
      {id: "D-tyxUB7iHvP", name: "profile_collection_profile_count", value: properties.profileCollectionProfileCount},
      {id: "rdkXBSadme", name: "path", value: properties.path},
      {id: "eVaMR9zKbe8O", name: "page_slug", value: properties.pageSlug},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.profileCollectionId !== undefined && properties.profileCollectionId !== null) {
    eventProperties["profile_collection_id"] = properties.profileCollectionId;
  }
  if (properties.profileCollectionSlug !== undefined && properties.profileCollectionSlug !== null) {
    eventProperties["profile_collection_slug"] = properties.profileCollectionSlug;
  }
  if (properties.profileCollectionType !== undefined && properties.profileCollectionType !== null) {
    eventProperties["profile_collection_type"] = properties.profileCollectionType;
  }
  if (properties.profileCollectionProfileCount !== undefined && properties.profileCollectionProfileCount !== null) {
    eventProperties["profile_collection_profile_count"] = properties.profileCollectionProfileCount;
  }
  if (properties.path !== undefined && properties.path !== null) {
    eventProperties["path"] = properties.path;
  }
  if (properties.pageSlug !== undefined && properties.pageSlug !== null) {
    eventProperties["page_slug"] = properties.pageSlug;
  }
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("profileCollectionPageViewed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "profile_collection_id": properties.profileCollectionId,
        "profile_collection_slug": properties.profileCollectionSlug,
        "profile_collection_type": properties.profileCollectionType,
        "profile_collection_profile_count": properties.profileCollectionProfileCount,
        "path": properties.path,
        "page_slug": properties.pageSlug,
        "platform": sysPlatform,
        }, "PKocbIH3NLl", "bb417ff019631814600c51bf0b7ed663ca07863a2d698179ee45fce2846ab91e");
    }
    // destination Amplitude
    Amplitude.logEvent("profileCollectionPageViewed", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface TestEventProperties {
  marketId: string;
  localeId: string;
  testProperty: string;
}
/**
 * testEvent: Test Event for development purposes
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.testProperty: testing testing 123
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/C1WY_7-_L7}
 */
export function testEvent(properties: TestEventProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "C1WY_7-_L7", "8717ef8bc2f98c51e6848deacff7a275a96940e2c3e76dc3f025565d1bea9286", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("testEvent", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "test_property": properties.testProperty,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("C1WY_7-_L7", "testEvent", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "UD_-z5_5eU", name: "test_property", value: properties.testProperty},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  eventProperties["test_property"] = properties.testProperty;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("testEvent", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "test_property": properties.testProperty,
        "platform": sysPlatform,
        }, "C1WY_7-_L7", "8717ef8bc2f98c51e6848deacff7a275a96940e2c3e76dc3f025565d1bea9286");
    }
    // destination Amplitude
    Amplitude.logEvent("testEvent", (Object as any).assign({}, eventProperties));

  } else {
    // do nothing
  }
}

export interface ReactionVideoPlayedProperties {
  marketId: string;
  localeId: string;
  requestId: string | null | undefined;
  requestShortId: string | null | undefined;
  requestRequestType: RequestRequestTypeValueType | null | undefined;
  requestOccasionInternalName: string | null | undefined;
  requestToPronoun: string | null | undefined;
  requestIsGift: boolean | null | undefined;
  requestFromPronoun: string | null | undefined;
  requestVideoPrivateByCustomer: boolean | null | undefined;
  requestWatermarkedVideo: boolean | null | undefined;
  requestVideoQuality: string | null | undefined;
  requestVideoMinLengthSeconds: number | null | undefined;
  requestExpirationTime: string | null | undefined;
  requestStatus: string | null | undefined;
  requestExpiresAt: string | null | undefined;
  requestVideoPrivateByProfile: boolean | null | undefined;
  reactionId: string;
  reactionStatus: ReactionStatusValueType;
}
/**
 * reactionVideoPlayed: A reaction video is played
 *
 * @param properties the properties associatied with this event
 * @param properties.marketId: the active market at the time of the event
 * @param properties.localeId: the active locale at the time of the event
 * @param properties.requestId: This requests Id
 * @param properties.requestShortId: A short id for the request used in urls when delivering the memmo
 * @param properties.requestRequestType: The type of request. Different types have different content associated to them
 * @param properties.requestOccasionInternalName: The occasion the memmo is bought for
 * @param properties.requestToPronoun: Pronoun of the person receiving the memmo
 * @param properties.requestIsGift: If it's sent to somebody else
 * @param properties.requestFromPronoun: Pronoun of the person sending the memmo
 * @param properties.requestVideoPrivateByCustomer: If it should not be shown on the website
 * @param properties.requestWatermarkedVideo: If the downloaded video file should contain a watermark
 * @param properties.requestVideoQuality: What quality the customer want the video in
 * @param properties.requestVideoMinLengthSeconds: The minimum length for the video to be
 * @param properties.requestExpirationTime: The expiration time limit for the request. 1WEEK, 1DAY, 1HOUR
 * @param properties.requestStatus: The current status of the request when the event is created
 * @param properties.requestExpiresAt: The time the request will expire at
 * @param properties.requestVideoPrivateByProfile: If the profile has selected that the video should not be shown on their profile
 * @param properties.reactionId: Internal reaction identifier
 * @param properties.reactionStatus: Status of the reaction
 *
 * @see {@link https://www.avo.app/schemas/JToNwC3zo9svd6kBck5o/events/YL7pHmvlFi}
 */
export function reactionVideoPlayed(properties: ReactionVideoPlayedProperties
  ) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "YL7pHmvlFi", "6e64beba5699a94f30f5dca9e1a8a2c89d3fadf03dfc47891a32ff98f32980c6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("reactionVideoPlayed", {
      "market_id": properties.marketId,
      "locale_id": properties.localeId,
      "request_id": properties.requestId,
      "request_short_id": properties.requestShortId,
      "request_request_type": properties.requestRequestType,
      "request_occasion_internal_name": properties.requestOccasionInternalName,
      "request_to_pronoun": properties.requestToPronoun,
      "request_is_gift": properties.requestIsGift,
      "request_from_pronoun": properties.requestFromPronoun,
      "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
      "request_watermarked_video": properties.requestWatermarkedVideo,
      "request_video_quality": properties.requestVideoQuality,
      "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
      "request_expiration_time": properties.requestExpirationTime,
      "request_status": properties.requestStatus,
      "request_expires_at": properties.requestExpiresAt,
      "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
      "reaction_id": properties.reactionId,
      "reaction_status": properties.reactionStatus,
      "platform": sysPlatform,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("YL7pHmvlFi", "reactionVideoPlayed", messages, [
      {id: "OhA54V0Jh3", name: "market_id", value: properties.marketId},
      {id: "ma8AmfqBtG", name: "locale_id", value: properties.localeId},
      {id: "dEfEjlnB10L", name: "request_id", value: properties.requestId},
      {id: "2ggqzAlbDAp", name: "request_short_id", value: properties.requestShortId},
      {id: "gO-8Eb2ZZPo", name: "request_request_type", value: properties.requestRequestType},
      {id: "MPpb4AhYtik", name: "request_occasion_internal_name", value: properties.requestOccasionInternalName},
      {id: "SsqsEjcs6nE", name: "request_to_pronoun", value: properties.requestToPronoun},
      {id: "XK8xlF3iYZV", name: "request_is_gift", value: properties.requestIsGift},
      {id: "w2dN-Vw5A8Y", name: "request_from_pronoun", value: properties.requestFromPronoun},
      {id: "mP53BE-gDY9", name: "request_video_private_by_customer", value: properties.requestVideoPrivateByCustomer},
      {id: "RCn4f4VVP65", name: "request_watermarked_video", value: properties.requestWatermarkedVideo},
      {id: "cr-POR7CeuU", name: "request_video_quality", value: properties.requestVideoQuality},
      {id: "ej-XFy714CO", name: "request_video_min_length_seconds", value: properties.requestVideoMinLengthSeconds},
      {id: "JaO1pI_a_F", name: "request_expiration_time", value: properties.requestExpirationTime},
      {id: "LpJKJO4kN7", name: "request_status", value: properties.requestStatus},
      {id: "UytICmFisH", name: "request_expires_at", value: properties.requestExpiresAt},
      {id: "WpdeGxfstx", name: "request_video_private_by_profile", value: properties.requestVideoPrivateByProfile},
      {id: "WiWEsUiS0-", name: "reaction_id", value: properties.reactionId},
      {id: "mxSXd1OHMW", name: "reaction_status", value: properties.reactionStatus},
      {id: "DZHdVyW0IG", name: "platform", value: sysPlatform},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["market_id"] = properties.marketId;
  eventProperties["locale_id"] = properties.localeId;
  if (properties.requestId !== undefined && properties.requestId !== null) {
    eventProperties["request_id"] = properties.requestId;
  }
  if (properties.requestShortId !== undefined && properties.requestShortId !== null) {
    eventProperties["request_short_id"] = properties.requestShortId;
  }
  if (properties.requestRequestType !== undefined && properties.requestRequestType !== null) {
    eventProperties["request_request_type"] = properties.requestRequestType;
  }
  if (properties.requestOccasionInternalName !== undefined && properties.requestOccasionInternalName !== null) {
    eventProperties["request_occasion_internal_name"] = properties.requestOccasionInternalName;
  }
  if (properties.requestToPronoun !== undefined && properties.requestToPronoun !== null) {
    eventProperties["request_to_pronoun"] = properties.requestToPronoun;
  }
  if (properties.requestIsGift !== undefined && properties.requestIsGift !== null) {
    eventProperties["request_is_gift"] = properties.requestIsGift;
  }
  if (properties.requestFromPronoun !== undefined && properties.requestFromPronoun !== null) {
    eventProperties["request_from_pronoun"] = properties.requestFromPronoun;
  }
  if (properties.requestVideoPrivateByCustomer !== undefined && properties.requestVideoPrivateByCustomer !== null) {
    eventProperties["request_video_private_by_customer"] = properties.requestVideoPrivateByCustomer;
  }
  if (properties.requestWatermarkedVideo !== undefined && properties.requestWatermarkedVideo !== null) {
    eventProperties["request_watermarked_video"] = properties.requestWatermarkedVideo;
  }
  if (properties.requestVideoQuality !== undefined && properties.requestVideoQuality !== null) {
    eventProperties["request_video_quality"] = properties.requestVideoQuality;
  }
  if (properties.requestVideoMinLengthSeconds !== undefined && properties.requestVideoMinLengthSeconds !== null) {
    eventProperties["request_video_min_length_seconds"] = properties.requestVideoMinLengthSeconds;
  }
  if (properties.requestExpirationTime !== undefined && properties.requestExpirationTime !== null) {
    eventProperties["request_expiration_time"] = properties.requestExpirationTime;
  }
  if (properties.requestStatus !== undefined && properties.requestStatus !== null) {
    eventProperties["request_status"] = properties.requestStatus;
  }
  if (properties.requestExpiresAt !== undefined && properties.requestExpiresAt !== null) {
    eventProperties["request_expires_at"] = properties.requestExpiresAt;
  }
  if (properties.requestVideoPrivateByProfile !== undefined && properties.requestVideoPrivateByProfile !== null) {
    eventProperties["request_video_private_by_profile"] = properties.requestVideoPrivateByProfile;
  }
  eventProperties["reaction_id"] = properties.reactionId;
  eventProperties["reaction_status"] = properties.reactionStatus;
  eventProperties["platform"] = sysPlatform;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("reactionVideoPlayed", {
        "market_id": properties.marketId,
        "locale_id": properties.localeId,
        "request_id": properties.requestId,
        "request_short_id": properties.requestShortId,
        "request_request_type": properties.requestRequestType,
        "request_occasion_internal_name": properties.requestOccasionInternalName,
        "request_to_pronoun": properties.requestToPronoun,
        "request_is_gift": properties.requestIsGift,
        "request_from_pronoun": properties.requestFromPronoun,
        "request_video_private_by_customer": properties.requestVideoPrivateByCustomer,
        "request_watermarked_video": properties.requestWatermarkedVideo,
        "request_video_quality": properties.requestVideoQuality,
        "request_video_min_length_seconds": properties.requestVideoMinLengthSeconds,
        "request_expiration_time": properties.requestExpirationTime,
        "request_status": properties.requestStatus,
        "request_expires_at": properties.requestExpiresAt,
        "request_video_private_by_profile": properties.requestVideoPrivateByProfile,
        "reaction_id": properties.reactionId,
        "reaction_status": properties.reactionStatus,
        "platform": sysPlatform,
        }, "YL7pHmvlFi", "6e64beba5699a94f30f5dca9e1a8a2c89d3fadf03dfc47891a32ff98f32980c6");
    }
    // destination Amplitude
    Amplitude.logEvent("reactionVideoPlayed", (Object as any).assign({}, eventProperties));

    // destination GTMClientSide
    GTMClientSide.logEvent("reactionVideoPlayed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  CheckoutStepName,
  ProductSaleMode,
  Platform,
  ProductVatType,
  ProductProductType,
  RequestRequestType,
  ReactionStatus,
  loggedIn,
  loggedOut,
  systemErrorEncountered,
  searchCompleted,
  searchResultClicked,
  searchResultPurchased,
  profileProductCardPlayed,
  profileViewed,
  cartProductAdded,
  cartProductRemoved,
  checkoutStepStarted,
  couponApplied,
  newsletterSubscribed,
  potentialProfileSubscribed,
  profileSubscribed,
  customFormSubmitted,
  potentialProfileSuggested,
  videoRequestPageViewed,
  videoRequestPlayed,
  videoRequestShared,
  videoRequestDownloaded,
  videoRequestBookAgainClicked,
  reactionVideoUploaded,
  landingPageViewed,
  cmsPageViewed,
  profileCollectionPageViewed,
  testEvent,
  reactionVideoPlayed,
}

// AVOMODULEMAP:"Sdk"
// AVOEVENTMAP:["loggedIn","loggedOut","systemErrorEncountered","searchCompleted","searchResultClicked","searchResultPurchased","profileProductCardPlayed","profileViewed","cartProductAdded","cartProductRemoved","checkoutStepStarted","couponApplied","newsletterSubscribed","potentialProfileSubscribed","profileSubscribed","customFormSubmitted","potentialProfileSuggested","videoRequestPageViewed","videoRequestPlayed","videoRequestShared","videoRequestDownloaded","videoRequestBookAgainClicked","reactionVideoUploaded","landingPageViewed","cmsPageViewed","profileCollectionPageViewed","testEvent","reactionVideoPlayed"]
