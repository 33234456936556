import config from 'lib/nextConfig'

const isServer = () => typeof window === 'undefined'

const isTestEnv = () => config.IS_CYPRESS_ENV || config.IS_TEST_ENV

const isCypressEnv = () => config.IS_CYPRESS_ENV

const base64 = (str) => {
  if (isServer()) {
    return Buffer.from(str).toString('base64')
  }
  return btoa(str)
}

const truncate = (str, chars) => {
  return str.length > chars ? `${str.slice(0, chars)}...` : str
}

const stripBasePath = (path) => {
  return config.basePath && path.startsWith(config.basePath)
    ? path.slice(config.basePath.length)
    : path
}

const withBasePath = (url) =>
  config.basePath ? `${config.basePath}${url}` : url

export {
  isServer,
  isTestEnv,
  isCypressEnv,
  base64,
  truncate,
  withBasePath,
  stripBasePath,
}
