import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconGraduationCap = createIcon({
  path: (
    <g>
      <path
        d="M24.3359 5.35156L13.2812 1.40625C13.0469 1.32812 12.7734 1.25 12.5 1.25C12.1875 1.25 11.9141 1.32812 11.6406 1.40625L0.625 5.35156C0.234375 5.50781 0 5.85938 0 6.25C0 6.67969 0.234375 7.03125 0.625 7.1875L2.96875 8.00781C2.5 8.63281 2.14844 9.33594 1.99219 10.1172C1.5625 10.3125 1.25 10.7422 1.25 11.25C1.25 11.6406 1.40625 11.9922 1.67969 12.2266L0.742188 18.0469C0.664062 18.4375 0.976562 18.75 1.32812 18.75H3.63281C3.98438 18.75 4.29688 18.4375 4.21875 18.0469L3.28125 12.2266C3.55469 11.9922 3.75 11.6406 3.75 11.25C3.75 10.8594 3.51562 10.5078 3.24219 10.3125C3.39844 9.60938 3.75 8.98438 4.21875 8.47656L11.6406 11.1328C11.9141 11.2109 12.1875 11.25 12.5 11.25C12.7734 11.25 13.0469 11.2109 13.3203 11.1328L24.3359 7.1875C24.7266 7.03125 25 6.67969 25 6.25C25 5.85938 24.7266 5.50781 24.3359 5.35156ZM12.6953 9.375C12.5 9.41406 12.3438 9.375 12.3047 9.375L6.83594 7.42188L12.6562 5.625C13.0078 5.50781 13.1641 5.15625 13.0859 4.84375C12.9688 4.49219 12.6172 4.29688 12.3047 4.41406L4.88281 6.71875L3.63281 6.25L12.2656 3.16406C12.4219 3.125 12.5781 3.16406 12.6562 3.16406L21.3281 6.25L12.6953 9.375ZM17.6562 10.8984L18.125 15.2734C18.125 15.7031 16.1719 16.875 12.5 16.875C8.78906 16.875 6.875 15.7031 6.875 15.2734L7.30469 10.8984L5.50781 10.2734L5 15.1953C5 17.5781 8.75 18.75 12.5 18.75C16.2109 18.75 20 17.5781 19.9609 15.1953L19.4531 10.2734L17.6562 10.8984Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconGraduationCap
