import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMasterCardSquare = createIcon({
  path: (
    <g>
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.179 16.8294C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02966 9.03582 5.02997 12.7807 5.02997C14.459 5.02997 15.9949 5.63247 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5773 5.02997C25.3221 5.02997 28.358 8.02966 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1792 16.8294C18.6371 15.6005 19.5616 13.7719 19.5616 11.73C19.5616 9.68801 18.6371 7.85941 17.1792 6.63051C18.3633 5.63247 19.8992 5.02997 21.5775 5.02997C25.3224 5.02997 28.3582 8.02966 28.3582 11.73C28.3582 15.4303 25.3224 18.43 21.5775 18.43C19.8992 18.43 18.3633 17.8275 17.1792 16.8294Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1788 16.8294C18.6367 15.6005 19.5611 13.7719 19.5611 11.73C19.5611 9.68803 18.6367 7.85943 17.1788 6.63053C15.7208 7.85943 14.7964 9.68803 14.7964 11.73C14.7964 13.7719 15.7208 15.6005 17.1788 16.8294Z"
        fill="#FF5E00"
      />
    </g>
  ),
  viewBox: '0 0 34 24',
})

export default IconMasterCardSquare
