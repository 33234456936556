import { FC, useMemo, useState } from 'react'
import {
  Experiment,
  ExperimentClient,
  Source,
  Variants,
} from '@amplitude/experiment-js-client'
import Bugsnag from '@bugsnag/js'
import { logger } from 'lib/logger'
import { isCypressEnv, isServer } from 'lib/utils'

import useEffectOnce from 'hooks/useEffectOnce'
import useRouter from 'hooks/useRouter'

import { AmplitudeExperimentContext, AmplitudeExperimentContextValue } from '.'

const FETCH_TIMEOUT_MS = 2000
const EXPERIMENT_OVERRIDE_STORAGE_KEY = 'experimentOverrides'
const CONTROL_KEY = 'control'

export interface AmplitudeExperimentProviderProps {
  deploymentApiKey: string
  debugMode?: boolean
}

const shouldNotRun = () => isServer()

const setBugsnagExperimentMetadata = (experiments: Variants) => {
  Bugsnag.addMetadata('AMPLITUDE_EXPERIMENT', {
    experiments,
  })
}

const AmplitudeExperimentProvider: FC<AmplitudeExperimentProviderProps> = ({
  deploymentApiKey,
  debugMode = false,
  children,
}) => {
  const [loaded, setLoaded] = useState(false)
  const { query } = useRouter()
  const [localOverrides, setLocalOverrides] = useState<Record<string, string>>(
    {},
  )

  const hasOverrideValue = (experimentId: string) =>
    localOverrides[experimentId] !== undefined
  const getOverrideValue = (experimentId: string) =>
    localOverrides[experimentId]

  useEffectOnce(() => {
    let currentSessionStorage = {}
    try {
      currentSessionStorage =
        JSON.parse(sessionStorage.getItem(EXPERIMENT_OVERRIDE_STORAGE_KEY)) ||
        {}
    } catch (e) {
      // ignore will be overwritten few lines down
    }
    const allOverrides = {
      ...currentSessionStorage,
      ...query,
    }
    sessionStorage.setItem(
      EXPERIMENT_OVERRIDE_STORAGE_KEY,
      JSON.stringify(allOverrides),
    )
    setLocalOverrides(allOverrides)
  })

  const startExperiment = async (experiment: ExperimentClient) => {
    await experiment.fetch()
    setLoaded(true)
    setBugsnagExperimentMetadata(experiment.all())
  }

  const experiment = useMemo<ExperimentClient | undefined>(() => {
    if (shouldNotRun()) {
      return undefined
    }

    const experimentClient = Experiment.initializeWithAmplitudeAnalytics(
      deploymentApiKey,
      {
        debug: debugMode,
        automaticFetchOnAmplitudeIdentityChange: true,
        source: Source.LocalStorage,
        fetchTimeoutMillis: FETCH_TIMEOUT_MS,
      },
    )

    startExperiment(experimentClient)

    logger.debug('[amplitude experiment] Experiment initialized')

    return experimentClient
  }, [debugMode, deploymentApiKey])

  function getVariantKey(
    experimentId: string,
    controlValue = CONTROL_KEY,
  ): string | null {
    if (isServer()) {
      return controlValue
    }

    if (hasOverrideValue(experimentId)) {
      return getOverrideValue(experimentId)
    }

    if (isCypressEnv()) {
      return controlValue
    }

    const variantObject = experiment.variant(experimentId)

    return variantObject?.value
  }
  const isVariantActive = (experimentId: string, variantValue?: string) => {
    return getVariantKey(experimentId) === variantValue
  }

  const isControlActive = (
    experimentId: string,
    defaultControl = CONTROL_KEY,
  ) => {
    const variantKey =
      getVariantKey(experimentId, defaultControl) ?? defaultControl
    return variantKey === defaultControl
  }

  function getVariantPayload<T>(experimentId: string): T | null {
    const variantObject = experiment.variant(experimentId)

    return variantObject.payload as T
  }

  const value: AmplitudeExperimentContextValue = {
    experiment,
    loaded,
    isVariantActive,
    isControlActive,
    getVariantPayload,
    getVariantKey,
  }

  return useMemo(() => {
    return (
      <AmplitudeExperimentContext.Provider value={value}>
        {children}
      </AmplitudeExperimentContext.Provider>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, loaded])
}

export { AmplitudeExperimentContext, AmplitudeExperimentProvider }
