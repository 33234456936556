import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFutbol = createIcon({
  path: (
    <g>
      <path
        d="M6.91406 8.94531L8.08594 12.5H11.875L13.0469 8.94531L10 6.75781L6.91406 8.94531ZM9.96094 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 19.9609 15.5469 19.9609 10.0391C19.9609 4.57031 15.5078 0 9.96094 0ZM16.9922 14.1406L12.9297 14.0625L11.7188 17.9688C11.1719 18.0859 10.5859 18.125 10 18.125C9.375 18.125 8.78906 18.0859 8.24219 17.9688L7.03125 14.0625L2.96875 14.1406C2.38281 13.125 2.03125 12.0312 1.91406 10.8203L5.23438 8.47656L3.90625 4.64844C4.6875 3.75 5.66406 3.04688 6.71875 2.57812L10 5L13.2422 2.57812C14.3359 3.04688 15.2734 3.75 16.0547 4.64844L14.7266 8.47656L18.0469 10.8203C17.9297 12.0312 17.5781 13.125 16.9922 14.1406Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconFutbol
