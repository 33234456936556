import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconBriefcase = createIcon({
  path: (
    <g>
      <path
        d="M17.5 3.75H15V2.5C15 1.13281 13.8672 0 12.5 0H7.5C6.09375 0 5 1.13281 5 2.5V3.75H2.5C1.09375 3.75 0 4.88281 0 6.25V16.25C0 17.6562 1.09375 18.75 2.5 18.75H17.5C18.8672 18.75 20 17.6562 20 16.25V6.25C20 4.88281 18.8672 3.75 17.5 3.75ZM7.5 1.875H12.5C12.8125 1.875 13.125 2.1875 13.125 2.5V3.75H6.875V2.5C6.875 2.1875 7.14844 1.875 7.5 1.875ZM2.5 5.625H17.5C17.8125 5.625 18.125 5.9375 18.125 6.25V10H1.875V6.25C1.875 5.9375 2.14844 5.625 2.5 5.625ZM17.5 16.875H2.5C2.14844 16.875 1.875 16.6016 1.875 16.25V11.875H7.5V12.5C7.5 13.2031 8.04688 13.75 8.75 13.75H11.25C11.9141 13.75 12.5 13.2031 12.5 12.5V11.875H18.125V16.25C18.125 16.6016 17.8125 16.875 17.5 16.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconBriefcase
