import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTreeChristmas = createIcon({
  path: (
    <g>
      <path
        d="M10.0625 11.0391C9.51562 11.0391 9.125 11.4297 9.125 11.9766C9.125 12.4844 9.51562 12.9141 10.0625 12.9141C10.5703 12.9141 11 12.4844 11 11.9766C11 11.4297 10.5703 11.0391 10.0625 11.0391ZM20.6094 17.9922L17.9922 14.5938C18.4219 14.4375 18.7344 14.0859 18.9297 13.6562C19.2422 12.9922 19.125 12.1719 18.6172 11.625L13.5781 6.03906L13.1094 6.27344L12.4453 7.60156L17.2109 12.9141H14.3203L19.0859 19.1641H2.875L7.64062 12.9141H4.75L9.51562 7.60156L8.85156 6.27344L8.38281 6.03906L3.34375 11.625C2.83594 12.1719 2.71875 12.9922 3.03125 13.6562C3.22656 14.0859 3.53906 14.4375 3.96875 14.5938L1.35156 17.9922C0.921875 18.5781 0.84375 19.3594 1.19531 19.9844C1.50781 20.6094 2.13281 21 2.875 21H19.0859C19.7891 21 20.4141 20.6094 20.7656 19.9844C21.1172 19.3203 21.0391 18.5781 20.6094 17.9922ZM14.125 15.7266C14.125 15.1797 13.6953 14.7891 13.1875 14.7891C12.6406 14.7891 12.25 15.1797 12.25 15.7266C12.25 16.2344 12.6406 16.6641 13.1875 16.6641C13.6953 16.6641 14.125 16.2344 14.125 15.7266ZM8.07031 4.47656L9.78906 5.33594L10.6484 7.05469C10.7266 7.21094 10.8438 7.25 10.9609 7.25C11.0391 7.25 11.1953 7.21094 11.2734 7.05469L12.1719 5.33594L13.8906 4.47656C14.1641 4.32031 14.1641 3.96875 13.8906 3.8125L12.1719 2.95312L11.3125 1.23438C11.1562 0.960938 10.8047 0.960938 10.6484 1.23438L9.78906 2.95312L8.07031 3.8125C7.79688 3.96875 7.79688 4.32031 8.07031 4.47656Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 21',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconTreeChristmas
