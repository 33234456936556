import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'lib/i18n'

import Link from 'components/Link/Link'
import { Button } from 'components/UI/Button/Button'
import { IconShoppingBag } from 'components/UI/Icons'
import { ModalCard } from 'components/UI/ModalCard/ModalCard'
import Typography from 'components/UI/Typography/Typography'
import useCart from 'hooks/useCart'
import usePath from 'hooks/usePath'
import useResponsive from 'hooks/useResponsive'

import styles from './Cart.module.css'

const Cart: React.FC<{ dark: boolean }> = ({ dark }) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const { getUnfinishedOrder, clearCart } = useCart()

  const [isOpen, setIsOpen] = useState(false)
  const { nakedPath } = usePath()

  const order = getUnfinishedOrder()

  const isPopoverVisible = !isMobile && isOpen
  const isModalVisible = isMobile && isOpen

  const isOnCheckout =
    nakedPath === order?.path || nakedPath.includes('checkout')
  const shouldShowCart = order?.path?.length > 0 && !isOnCheckout

  const { talentName, path } = order

  const content = (): React.ReactNode => {
    return (
      <div className={styles.content}>
        <Typography.H6>{t('Cart.title', 'Your cart')}</Typography.H6>

        <Typography.BodySmall>
          {t(
            'Cart.body',
            'You have an uncompleted request to {{talentName}}. Do you want to complete your request?',
            { talentName },
          )}
        </Typography.BodySmall>

        <Link href={path}>
          <a>
            <Button onClick={() => setIsOpen(false)}>
              {t('Cart.continue', 'Continue with request')}
            </Button>
          </a>
        </Link>

        <Button
          onClick={() => {
            setIsOpen(false)
            clearCart()
          }}
          type="secondary"
        >
          {t('Cart.remove', 'Remove order')}
        </Button>
      </div>
    )
  }

  return (
    <>
      {shouldShowCart && (
        <>
          <div className={styles.popoverWrapper}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => setIsOpen(!isOpen)}
              className={styles.cart}
            >
              <div
                className={classNames(styles.iconContainer, {
                  [styles.iconContainerDark]: dark,
                })}
              >
                <span className={styles.icon}>
                  <IconShoppingBag />
                </span>

                <span className={styles.indicator}>1</span>
              </div>
            </div>
            <div
              className={classNames(styles.contentWrapper, {
                [styles.hideContent]: !isPopoverVisible,
              })}
            >
              {content()}
            </div>
          </div>

          <ModalCard
            onClose={() => setIsOpen(false)}
            noRender={!isModalVisible}
          >
            {content()}
          </ModalCard>
        </>
      )}
    </>
  )
}

export default Cart
