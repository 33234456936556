/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react'
import { CookiesContextProvider } from 'contexts/CookiesContext'
import dynamic from 'next/dynamic'

import Footer from 'components/Footer/Footer'
import StickyNavigationBar from 'components/NavigationBar/StickyNavigationBar'
import HeroABWrapper from 'components/StartPageHeroABWrapper/Wrapper'
import Wrapper from 'components/Wrapper/Wrapper'
import useGiftingOccasions from 'hooks/useGiftingOccasions'
import useIsRefInViewport from 'hooks/useIsRefInViewport'
import usePath from 'hooks/usePath'
import useResponsive from 'hooks/useResponsive'

import styles from './PublicLayout.module.css'

const PageHeaderStatic = dynamic(
  () => import('components/PageHeaderStatic/PageHeaderStatic'),
)

const CookieConsent = dynamic(
  () => import('components/CookieConsent/CookieConsent'),
)
const PageHeader = dynamic(() => import('components/PageHeader/PageHeader'))

const PressCoverageLogosOnly = dynamic(
  () => import('components/PressCoverage/PressCoverageLogosOnly'),
)

const BusinessPageHeader = dynamic(
  () => import('components/BusinessPageHeader/BusinessPageHeader'),
)

const PageHeaderGifting = dynamic(
  () => import('components/PageHeaderGifting/PageHeaderGifting'),
)

interface PublicLayoutProps {
  headerContent?: React.ReactNode
  headerBackground?: string
  headerVideo?: React.ReactNode
  headerImage?: string
  nakedHeader?: boolean
  headerBorder?: boolean
  hideNewsletterSignup?: boolean
  businessHeader?: boolean
  hidePress?: boolean
  hideFooter?: boolean
  waveColor?: string
  bottom?: boolean
  contentStyle?: React.CSSProperties
  children: React.ReactNode
  navigationBarDarkContent?: boolean
  footer?: React.ReactNode
}

const PublicLayout: React.FC<PublicLayoutProps> = ({
  headerContent,
  headerBackground,
  headerVideo,
  headerImage,
  nakedHeader = false,
  headerBorder = false,
  hideNewsletterSignup = false,
  businessHeader = false,
  hidePress = false,
  hideFooter = false,
  waveColor,
  bottom = true,
  contentStyle,
  children,
  navigationBarDarkContent = false,
  footer,
}: PublicLayoutProps) => {
  const { pathname, nakedPath } = usePath()
  const { isMobile } = useResponsive()
  const { marketIsGifting, giftingOccasion } = useGiftingOccasions()
  const headerRef = useRef(null)
  const isHome = !nakedPath
  const isSearch = pathname.includes('search') || pathname.includes('suggest')
  const hideBottom = isMobile && nakedPath.startsWith('/search')

  const logoVariant = isSearch ? 'primary' : undefined

  const pageHeaderProps = {
    logoVariant,
    hideSearchButton: isSearch,
    greyBackground: isSearch,
    nakedHeader,
    headerBorder,
    headerBackground,
    headerContent,
    headerVideo,
    headerImage,
    waveColor,
    hideBottom,
    bottom,
    contentStyle,
    navigationBarDarkContent,
    headerRef,
  }
  const hideStickyNavigation =
    pathname.includes('checkout') ||
    pathname.includes('product') ||
    pathname.includes('review') ||
    pathname.includes('search') ||
    pathname.includes('suggest')
  const cmsRef = useRef(null)

  const footerElement = footer ?? (
    <Footer hideNewsletterSignup={hideNewsletterSignup} />
  )

  const renderPageHeader = () => {
    if (businessHeader) {
      return <BusinessPageHeader headerRef={headerRef} />
    }

    // Allow occasions if active in market
    if (marketIsGifting && isHome) {
      return (
        <PageHeaderGifting
          giftingOccasion={giftingOccasion}
          {...pageHeaderProps}
        />
      )
    }

    if (isHome) {
      return <PageHeaderStatic {...pageHeaderProps} />
    }

    return <PageHeader {...pageHeaderProps} />
  }

  const isHeaderVisible = useIsRefInViewport({
    ref: headerRef,
  })

  return (
    <CookiesContextProvider>
      <div id="bannerRoot" /> {/* Used for Banner CMS items */}
      <div
        style={{
          background: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
        }}
      >
        {!hideStickyNavigation && (
          <StickyNavigationBar
            darkContent
            showStickyNavigation={!isHeaderVisible}
          />
        )}
        {/* // Wrapper as there is an active A/B test in view */}
        <HeroABWrapper isHome={isHome}>{renderPageHeader()}</HeroABWrapper>

        <div style={{ flexGrow: 1, minHeight: 300 }} ref={cmsRef}>
          {nakedHeader ? (
            children
          ) : (
            <div
              style={contentStyle}
              className={!isHome ? styles.mainContentPage : undefined}
            >
              {children}
            </div>
          )}
        </div>
        {!hidePress && (
          <Wrapper>
            <PressCoverageLogosOnly />
          </Wrapper>
        )}
        {!hideFooter && footerElement}
      </div>
      {!(
        nakedPath.startsWith('/checkout') || nakedPath.startsWith('/profile/')
      ) && <CookieConsent />}
    </CookiesContextProvider>
  )
}

export default PublicLayout
