import React, { useEffect, useState } from 'react'
import { GeneralContext } from 'contexts/GeneralContext'
import Cookies from 'lib/cookies'

import useEffectOnce from 'hooks/useEffectOnce'
import usePath from 'hooks/usePath'
import useRouter from 'hooks/useRouter'

export default function GeneralContextProvider({ children }) {
  const path = usePath()
  const router = useRouter()

  const [videoIdPlaying, setVideoIdPlaying] = useState()
  const [isMuted, setIsMuted] = useState()
  const [searchedFromPath, setSearchedFromPath] = useState()
  const [closeToPath, setCloseToPath] = useState()
  const [expressRequestAddon, setExpressRequestAddon] = useState()
  const [lastAttribution, setLastAttribution] = useState({})
  const [searchAutoFocus, setSearchAutoFocus] = useState(false)

  useEffect(() => {
    if (path.nakedPath.startsWith('/search')) {
      setSearchAutoFocus(true)
    }
  }, [path, router])

  useEffectOnce(() => {
    /* eslint-disable-next-line camelcase */
    const { utm_medium: utmMedium } = router.query

    if (utmMedium) {
      Cookies.set('lastAttributionUtmMedium', utmMedium, { expires: 7 })
    }

    setLastAttribution({
      url: path.nakedPath,
      utmMedium: Cookies.get('lastAttributionUtmMedium'),
    })
  })

  return (
    <GeneralContext.Provider
      value={{
        videoIdPlaying,
        setVideoIdPlaying,
        isMuted,
        setIsMuted,
        searchedFromPath,
        setSearchedFromPath,
        closeToPath,
        setCloseToPath,
        expressRequestAddon,
        setExpressRequestAddon,
        lastAttribution,
        searchAutoFocus,
        setSearchAutoFocus,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}
