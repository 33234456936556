import { gql } from '@apollo/client'

const coloredBannerFragment = gql`
  fragment coloredBannerFragment on PublicCmsItemColoredBanner {
    id
    type
    titleWeight
    titleSize
    title
    titleColor
    text
    textColor
    backgroundColor
    ctaButtonLabel
    ctaButtonLabelColor
    ctaButtonBorderColor
    ctaButtonHoverLabelColor
    ctaButtonHoverBackgroundColor
    ctaButtonHoverBorderColor
    ctaButtonLink
    backgroundImage {
      id
      url
    }
    images {
      id
      url
    }
  }
`

export default coloredBannerFragment
