import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRainbow = createIcon({
  path: (
    <g>
      <path
        d="M11.7188 1.28906C5.74219 1.67969 1.25 6.91406 1.25 12.9297V17.8125C1.25 18.3594 1.64062 18.75 2.14844 18.75C2.61719 18.75 3.125 18.3594 3.125 17.8125V12.5C3.125 6.95312 7.89062 2.53906 13.5547 3.16406C18.3203 3.75 21.875 7.96875 21.875 12.7734V17.3047C21.875 18.3594 22.2656 18.75 22.8125 18.75C23.3203 18.75 23.75 18.3594 23.75 17.8125V12.5C23.75 6.05469 18.2812 0.859375 11.7188 1.28906ZM11.4844 5.11719C7.69531 5.58594 5 8.98438 5 12.8125V17.8125C5 18.3594 5.39062 18.75 5.89844 18.75C6.44531 18.75 6.875 18.3594 6.875 17.8125V12.5C6.875 9.21875 9.6875 6.5625 13.0469 6.91406C15.9766 7.22656 18.125 9.84375 18.125 12.7734V17.8125C18.125 18.3594 18.5156 18.75 19.0234 18.75C19.4922 18.75 20 18.3594 20 17.8125V12.5C20 8.08594 16.0547 4.53125 11.4844 5.11719ZM11.7188 8.82812C9.96094 9.17969 8.75 10.8594 8.75 12.6562V17.8125C8.75 18.3594 9.14062 18.75 9.64844 18.75C10.1172 18.75 10.625 18.3594 10.625 17.8125V12.5391C10.625 11.6406 11.2109 10.8203 12.1094 10.6641C13.3203 10.4297 14.375 11.3672 14.375 12.5V17.8125C14.375 18.3594 14.7656 18.75 15.2734 18.75C15.7422 18.75 16.25 18.3594 16.25 17.8125V12.5C16.25 10.1953 14.1406 8.35938 11.7188 8.82812Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconRainbow
