import React from 'react'
import { ApolloProvider } from '@apollo/client'
import type { Event } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import { config as fontAwesomeconfig } from '@fortawesome/fontawesome-svg-core'
import { AuthProvider } from 'contexts/AuthContext'
import { FeatureFlagsProvider } from 'contexts/FeatureFlagsContext/FeatureFlagsProvider'
import GeneralContextProvider from 'contexts/GeneralContext/GeneralContextProvider'
import { ImpressionContextProvider } from 'contexts/ImpressionContext'
import { useApollo } from 'lib/apolloClient'
import { appWithTranslation } from 'lib/i18n'
import { setItem } from 'lib/localStorage'
import { trackPageview } from 'lib/tracking'
import { initAvoSDK } from 'lib/tracking/avo'
import trackSystemErrorEncountered from 'lib/tracking/avo/trackSystemErrorEncountered'
import { isServer } from 'lib/utils'
import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import { withServerContext } from 'next-server-context'
import NProgress from 'nprogress'

import '../lib/bugsnagClient'

import AmplitudeExperiment from 'components/AmplitudeExperiment'
import DefaultHeadTags from 'components/DefaultHeadTags/DefaultHeadTags'
import MarketSelector from 'components/MarketSelector/MarketSelector'
import useEffectOnce from 'hooks/useEffectOnce'
import usePath from 'hooks/usePath'

import Error from './_error'

import '@fortawesome/fontawesome-svg-core/styles.css'
import '../assets/main.css'
import './_app.css'

declare global {
  interface Window {
    analytics: {
      page: (a: string) => void
    }
    gtag: () => void
  }
}

fontAwesomeconfig.autoAddCss = false

Router.events.on('routeChangeComplete', (url) => {
  // eslint-disable-next-line no-console
  console.log('routeChangeComplete', url)

  // A bit of a hack to get around our marketswitcher magic
  // and Next.js lack of preventing router events to fire
  // The actual root path is tracked in MarketSelectorUI
  if (url !== '/') {
    window?.analytics?.page(url)
    // 'Redirect' from naked path uses this event as well
    trackPageview({ path: url })
  }
})
const onError = (event: Event) => {
  trackSystemErrorEncountered(event)
}
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

function MyApp({ Component, pageProps }: AppProps, statusCode: number) {
  const router = useRouter()
  const path = usePath()
  const apolloClient = useApollo(pageProps.initialApolloState)
  const isErrorOr404 = [404, 500].includes(statusCode)

  if (!isServer()) initAvoSDK()

  useEffectOnce(() => {
    // Pageview event for 'non-redirect' clients
    if (path.isLocalized) {
      trackPageview({ path: path.asPath })
    }

    // Client side only - read ?coupon from url and save in localStorage
    const couponCode = `${router.query.couponCode}`
    if (couponCode) {
      setItem('couponCode', couponCode)
    }
  })

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary FallbackComponent={Error as any} onError={onError}>
          <GeneralContextProvider>
            <FeatureFlagsProvider>
              <AmplitudeExperiment>
                <MarketSelector isErrorOr404={isErrorOr404}>
                  <AuthProvider>
                    <ImpressionContextProvider>
                      <DefaultHeadTags />
                      <Component {...pageProps} />
                    </ImpressionContextProvider>
                  </AuthProvider>
                </MarketSelector>
              </AmplitudeExperiment>
            </FeatureFlagsProvider>
          </GeneralContextProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  return { ...appProps, statusCode: appContext?.ctx?.res?.statusCode }
}

export default withServerContext(appWithTranslation(MyApp))
