import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMoneyBack = createIcon({
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7792 7.01788C23.3038 6.8584 19.863 7.76911 16.9216 9.62705C13.9801 11.485 11.6794 14.2007 10.3303 17.4076C10.009 18.1712 9.12957 18.5298 8.36597 18.2085C7.60237 17.8873 7.24378 17.0078 7.56503 16.2442C9.15229 12.4714 11.8589 9.27647 15.3195 7.09065C18.7801 4.90483 22.828 3.83341 26.9168 4.02103C31.0056 4.20865 34.9383 5.64627 38.1842 8.13982C41.4301 10.6334 43.8327 14.0628 45.0678 17.9651C46.3028 21.8674 46.3109 26.0547 45.0908 29.9617C43.8707 33.8687 41.4812 37.3073 38.245 39.8133C35.0087 42.3193 31.0814 43.772 26.9934 43.9753C22.9054 44.1786 18.8534 43.1227 15.3844 40.9502C14.6823 40.5105 14.4696 39.5849 14.9093 38.8828C15.349 38.1807 16.2747 37.968 16.9768 38.4077C19.9254 40.2543 23.3696 41.1518 26.8444 40.979C30.3192 40.8062 33.6574 39.5714 36.4082 37.4413C39.159 35.3112 41.1901 32.3884 42.2272 29.0675C43.2642 25.7465 43.2574 22.1873 42.2076 18.8703C41.1578 15.5534 39.1155 12.6384 36.3566 10.5189C33.5976 8.39933 30.2547 7.17735 26.7792 7.01788Z"
        fill="currentColor"
      />
      <path
        d="M15.1158 17.4789C15.6691 17.8357 15.6098 18.6629 15.0113 18.9372L5.46818 23.3102C4.91467 23.5638 4.28484 23.1577 4.28748 22.5488L4.33303 12.0515C4.33589 11.3932 5.06493 10.9979 5.6182 11.3547L15.1158 17.4789Z"
        fill="currentColor"
      />
      <path
        d="M24.731 35.5113H26.2419V33.3935C29.6898 33.1482 32.2724 31.2887 32.2724 28.0861C32.2724 25.2968 30.4258 23.9022 26.965 23.1016L26.2419 22.9337V17.8975C27.8044 18.117 28.8633 18.9951 29.0441 20.5189H32.0529C31.9367 17.5488 29.6123 15.5731 26.2419 15.3148V13.1583H24.731V15.3019C21.3606 15.5472 18.9458 17.4842 18.9458 20.4543C18.9458 23.1532 20.7666 24.6512 24.0724 25.4131L24.731 25.568V30.7979C22.8069 30.5913 21.8513 29.5712 21.6318 28.1507H18.623C18.7134 31.2887 21.309 33.1611 24.731 33.3935V35.5113ZM22.0709 20.1961C22.0709 19.0726 22.9877 18.1041 24.731 17.8975V22.585C22.8198 22.0814 22.0709 21.3066 22.0709 20.1961ZM29.1603 28.409C29.1603 29.6874 28.2564 30.6301 26.2419 30.8109V25.9167C28.463 26.472 29.1603 27.1693 29.1603 28.409Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 48 48',
})

export default IconMoneyBack
