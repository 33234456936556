import Bugsnag from '@bugsnag/js'

const LEVELS = ['debug', 'info', 'warn', 'error'] as const

type LEVELS = typeof LEVELS
type LEVEL = LEVELS[number]
type Logger = { [L in LEVEL]: Console[L] }

const log =
  (level: LEVEL) =>
  (...args: unknown[]) => {
    if (level === 'debug' && process.env.NODE_ENV === 'production') {
      return
    }

    // eslint-disable-next-line no-console
    console[level](...args)
  }

type LogEventProps = (
  event: string | Error,
  payload: Record<string, unknown>,
  ...args: unknown[]
) => void

const logError =
  (logEvent: LogEventProps) =>
  (
    event: string | Error,
    payload: Record<string, unknown>,
    ...args: unknown[]
  ) => {
    const error = event instanceof Error ? event : new Error(event)
    if (payload) {
      Bugsnag.notify(error, (env) =>
        env.addMetadata('Error Payload', {
          payload,
        }),
      )
    } else {
      Bugsnag.notify(error)
    }

    logEvent(event, payload, ...args)
  }

const logger: Logger = {} as Record<LEVEL, LogEventProps>

LEVELS.forEach((level) => {
  if (level === 'error') {
    logger[level] = logError(log(level))
  } else {
    logger[level] = log(level)
  }
})

export { logger as default, logger }
