import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconDinersClubSquare = createIcon({
  path: (
    <g>
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8382 19.3H18.4343C22.4868 19.3 25.716 16.085 25.8427 12.1135V12.0504C25.7794 8.0159 22.4868 4.73784 18.4343 4.80088H15.5216C11.6592 4.92696 8.61986 8.20502 8.74649 12.1135C8.74649 16.0219 11.9125 19.2369 15.8382 19.3ZM10.0129 12.0504C10.0129 8.77235 12.7356 6.06165 16.0282 6.06165C19.3208 6.06165 22.0435 8.77235 22.0435 12.0504C22.0435 15.3285 19.3208 18.0392 16.0282 18.0392C12.7356 18.0392 10.0129 15.3285 10.0129 12.0504ZM16.978 16.0849V8.07892C19.1942 8.64627 20.5872 10.8527 20.0173 13.059C19.6374 14.572 18.4977 15.7067 16.978 16.0849ZM12.1024 11.0418C11.5325 13.2482 12.8622 15.5176 15.0784 16.0849V8.07892C13.6221 8.45716 12.4823 9.59187 12.1024 11.0418Z"
        fill="#0079BE"
      />
    </g>
  ),
  viewBox: '0 0 34 24',
})

export default IconDinersClubSquare
