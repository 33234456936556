const stripBasePath = (path) => {
  const basePath = process.env.BASE_PATH
  return basePath && path.startsWith(basePath)
    ? path.slice(basePath.length)
    : path
}

const pathHelpers = (path) => {
  const withoutBasePath = stripBasePath(path)

  const filteredPath = withoutBasePath
    .split('/')
    .filter((item) => item)
    .map((item) => item.split('?')[0])

  // @todo Check against real market/lang pairs
  const isLocalized = () => {
    return RegExp(/^\/(global|test|[A-z]{2})\/[A-z]{2}($|\/|\?)/).test(
      withoutBasePath,
    )
  }

  const hasOnlyMarket = () => {
    if (['/global', '/test'].includes(withoutBasePath)) return true
    return RegExp(/^\/[A-z]{2}\/?$/).test(withoutBasePath)
  }

  const languageHasRegion = () => {
    return RegExp(
      /^\/(global|test|[A-z]{2})\/[A-z]{2}-[A-z]{2}?($|\/|\?)/,
    ).test(withoutBasePath)
  }

  const market = () => {
    return isLocalized() || hasOnlyMarket() ? filteredPath[0] : null
  }

  const language = () => {
    return isLocalized() ? filteredPath[1] : null
  }

  return {
    filteredPath,
    market: market(),
    language: language(),
    isLocalized: isLocalized(),
    hasOnlyMarket: hasOnlyMarket(),
    languageHasRegion: languageHasRegion(),
    withoutBasePath,
  }
}

module.exports = pathHelpers
