import React from 'react'
import prefetchSideMenu from 'graphql/prefetchers/prefetchSideMenu'
import { initializeApollo } from 'lib/apolloClient'
import { isServer } from 'lib/utils'
import { NextPageContext } from 'next'
import dynamic from 'next/dynamic'

import PublicLayout from 'components/PublicLayout/PublicLayout'

const Error = dynamic(() => import('components/Error/Error'))
const Error404 = dynamic(() => import('components/Error404/Error404'))

const ErrorPage = ({ statusCode = null }: { statusCode: number }) => (
  <PublicLayout>
    {statusCode === 404 ? <Error404 /> : <Error statusCode={statusCode} />}
  </PublicLayout>
)

ErrorPage.getInitialProps = async (ctx: NextPageContext) => {
  const { err, res } = ctx

  const currentStatusCode = res?.statusCode || 500
  const throwedStatusCode = err?.statusCode

  const statusCode = throwedStatusCode || currentStatusCode

  const initialProps = {
    namespacesRequired: ['common'],
    statusCode,
  }

  if (isServer()) {
    const apolloClient = initializeApollo(ctx)
    await prefetchSideMenu(apolloClient)

    return {
      initialApolloState: apolloClient.cache.extract(),
      ...initialProps,
    }
  }

  return initialProps
}

export default ErrorPage
