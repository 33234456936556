/* eslint-disable import/prefer-default-export */
import { ADDONS, TYPES } from './products'

export const PRODUCTS = {
  ADDONS,
  TYPES,
}

export { PRODUCTS as default }

// eslint-disable-next-line no-shadow
export enum GiftingOccasion {
  MothersDay = 'MOTHERS_DAY',
  FathersDay = 'FATHERS_DAY',
}

export type OccasionData = {
  [key: string]: { start: string; end: string; url: string }
}

type GiftingOccasionsData = Record<GiftingOccasion, OccasionData>

export const GIFTING_OCCASIONS: GiftingOccasionsData = {
  [GiftingOccasion.MothersDay]: {
    se: {
      start: '2022-05-08',
      end: '2022-05-29',
      url: '/page/mors-dag-present',
    },
    fr: {
      start: '2022-05-08',
      end: '2022-05-29',
      url: '/profiles/fr-fete-des-meres',
    },
  },
  [GiftingOccasion.FathersDay]: {
    global: {
      start: '2022-06-01',
      end: '2022-06-19',
      url: '/profiles/us-fday-manual ',
    },
    ca: {
      start: '2022-06-01',
      end: '2022-06-01',
      url: '/profiles/ca-fday-manual ',
    },
    fr: {
      start: '2022-06-01',
      end: '2022-06-19',
      url: '/profiles/fr-fete-des-meres ',
    },
    gb: {
      start: '2022-06-01',
      end: '2022-06-19',
      url: '/profiles/uk-father ',
    },
  },
}

export const MEMMO_RED = '#f8284e'

export const euCountries = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
]

export const euCountriesWithGB = [...euCountries, 'GB']

export const algoliaEventNames = {
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_CHECKED_OUT: 'Product Checked Out',
  PRODUCT_VIEWED: 'Product Viewed',
  PROFILE_CLICKED: 'Profile Clicked',
} as const

export type AlgoliaEventNames =
  typeof algoliaEventNames[keyof typeof algoliaEventNames]
