import { gql } from '@apollo/client'

const merchCardFragment = gql`
  fragment merchCardFragment on PublicCmsItemMerchCard {
    id
    title
    description
    fromPrice
    linkUrl
    image {
      id
      url(format: { width: 400, height: 400, fit: crop })
    }
  }
`

export default merchCardFragment
