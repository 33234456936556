import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFaceSmileWink = createIcon({
  path: (
    <g>
      <path
        d="M10 13.75C11.4453 13.75 12.4609 13.0859 13.0469 12.4609C13.3984 12.0703 13.9844 12.0312 14.375 12.3828C14.7656 12.7344 14.7656 13.3203 14.4141 13.7109C13.5547 14.6484 12.0703 15.625 10 15.625C7.89062 15.625 6.40625 14.6484 5.54688 13.7109C5.19531 13.3203 5.19531 12.7344 5.58594 12.3828C5.97656 12.0312 6.5625 12.0703 6.91406 12.4609C7.5 13.0859 8.51562 13.75 10 13.75ZM8.125 8.125C8.125 8.82812 7.57812 9.375 6.875 9.375C6.17188 9.375 5.625 8.82812 5.625 8.125C5.625 7.46094 6.17188 6.875 6.875 6.875C7.57812 6.875 8.125 7.46094 8.125 8.125ZM10.9766 9.02344C10.6641 8.71094 10.6641 8.24219 10.9766 7.92969C11.5234 7.30469 12.3438 7.03125 13.0859 7.03125C13.8281 7.03125 14.6484 7.30469 15.2344 7.92969C15.5078 8.24219 15.5078 8.71094 15.1953 9.02344C14.8828 9.33594 14.375 9.29688 14.1016 8.98438C13.8672 8.75 13.5156 8.59375 13.0859 8.59375C12.6562 8.59375 12.3047 8.75 12.1094 8.98438C11.7969 9.29688 11.3281 9.33594 10.9766 9.02344ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconFaceSmileWink
