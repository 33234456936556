import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconSyringe = createIcon({
  path: (
    <g>
      <path
        d="M20.6875 3.8125L18.1875 1.3125C17.7969 0.921875 17.2109 0.921875 16.8594 1.3125C16.4688 1.66406 16.4688 2.25 16.8594 2.60156L17.4844 3.1875L16 4.71094L13.8125 2.5625C13.4609 2.17188 12.875 2.17188 12.5234 2.5625C12.1719 2.875 12.1719 3.38281 12.4062 3.73438L3.77344 12.4062C3.57812 12.6016 3.5 12.8359 3.5 13.0703V17.2109L1.27344 19.4375C0.882812 19.7891 0.882812 20.375 1.27344 20.7656C1.42969 20.9219 1.66406 21 1.9375 21C2.17188 21 2.40625 20.9219 2.5625 20.7266L4.78906 18.5H8.92969C9.16406 18.5 9.39844 18.4219 9.59375 18.2266L18.2656 9.59375C18.4219 9.71094 18.6172 9.75 18.8125 9.75C19.0469 9.75 19.2812 9.67188 19.4375 9.47656C19.8281 9.125 19.8281 8.53906 19.4375 8.1875L17.2891 6L18.8125 4.51562L19.3984 5.10156C19.5547 5.29688 19.7891 5.375 20.0625 5.375C20.2969 5.375 20.5312 5.29688 20.6875 5.10156C21.0781 4.75 21.0781 4.16406 20.6875 3.8125ZM8.53906 16.625H5.375V13.4609L6.46875 12.3672L7.40625 13.3438C7.52344 13.4609 7.67969 13.5 7.875 13.5C8.03125 13.5 8.1875 13.4609 8.30469 13.3438C8.53906 13.1094 8.53906 12.6797 8.30469 12.4453L7.32812 11.5078L8.96875 9.86719L9.90625 10.8438C10.0234 10.9609 10.1797 11 10.375 11C10.5312 11 10.6875 10.9609 10.8047 10.8438C11.0391 10.6094 11.0391 10.1797 10.8047 9.94531L9.82812 9.00781L11.4688 7.36719L12.4062 8.34375C12.5234 8.46094 12.6797 8.5 12.875 8.5C13.0312 8.5 13.1875 8.46094 13.3047 8.34375C13.5391 8.10938 13.5391 7.67969 13.3047 7.44531L12.3281 6.50781L13.7344 5.10156L16.8984 8.26562L8.53906 16.625Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 21',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconSyringe
