import { gql } from '@apollo/client'

const allFeatureFlagsFragment = gql`
  fragment allFeatureFlagsFragment on Query {
    allFeatureFlags {
      id
      key
      enabled
    }
  }
`

export default allFeatureFlagsFragment
