import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFaceMask = createIcon({
  path: (
    <g>
      <path
        d="M5.5625 8.24219C5.17188 7.89062 5.13281 7.30469 5.44531 6.91406C5.95312 6.28906 7.32031 5.3125 9.27344 5.3125C9.82031 5.3125 10.2109 5.74219 10.2109 6.25C10.2109 6.79688 9.82031 7.1875 9.27344 7.1875C7.94531 7.1875 7.08594 7.89062 6.89062 8.125C6.53906 8.51562 5.95312 8.55469 5.5625 8.24219ZM12.75 6.25C12.75 5.74219 13.1406 5.3125 13.6875 5.3125C15.5625 5.3125 16.9297 6.13281 17.4375 6.64062C17.8281 7.03125 17.8281 7.61719 17.4375 7.96875C17.0859 8.32031 16.5 8.32031 16.1484 7.96875C15.9531 7.8125 15.0938 7.1875 13.6875 7.1875C13.1406 7.1875 12.75 6.79688 12.75 6.25ZM11.4609 0C16.3828 0 20.4453 3.51562 21.3047 8.16406C21.5781 8.08594 21.8906 8.20312 22.0469 8.47656C22.2031 8.78906 22.0859 9.14062 21.7734 9.33594L21.4609 9.49219C21.4609 9.64844 21.5 9.84375 21.5 10C21.5 15.5469 17.0078 20 11.5 20C5.95312 20 1.5 15.5469 1.5 10C1.5 9.84375 1.5 9.64844 1.5 9.49219L1.1875 9.33594C0.875 9.14062 0.757812 8.78906 0.914062 8.47656C1.07031 8.20312 1.38281 8.08594 1.65625 8.16406C2.51562 3.51562 6.57812 0 11.5 0H11.4609ZM5.21094 15.1953V12.1875C5.21094 11.9531 5.25 11.7578 5.32812 11.5234L3.375 10.4688C3.45312 12.2656 4.15625 13.9062 5.21094 15.1953ZM5.95312 10.4297C6.42188 10 7.04688 9.6875 7.71094 9.6875H15.25C15.9141 9.6875 16.5391 10 17.0078 10.4297L19.5469 9.10156C19.0781 5.03906 15.6406 1.875 11.4609 1.875C7.32031 1.875 3.88281 5.03906 3.41406 9.10156L5.95312 10.4297ZM19.5859 10.4688L17.6328 11.5234C17.7109 11.7578 17.75 11.9531 17.75 12.1875V15.1953C18.8047 13.9062 19.5078 12.2656 19.5859 10.4688ZM7.08594 12.5H15.875V12.1875C15.875 11.875 15.5625 11.5625 15.25 11.5625H7.71094C7.39844 11.5625 7.08594 11.875 7.08594 12.1875V12.5ZM7.08594 15H15.875V13.75H7.08594V15ZM8.02344 17.1875H14.9375C15.4453 17.1875 15.875 16.7969 15.875 16.25H7.08594C7.08594 16.7969 7.51562 17.1875 8.02344 17.1875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 23 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconFaceMask
