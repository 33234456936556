import { pushToDataLayer } from 'lib/tracking'
import { CustomDestination } from 'lib/tracking/avo/sdk'
import { isServer } from 'lib/utils'

const gtmDestination: CustomDestination = {
  logEvent: (eventName: string, eventProperties: Record<string, unknown>) => {
    if (!isServer()) {
      pushToDataLayer({
        event: eventName,
        properties: eventProperties,
        avo: true,
      })
    }
  },
}

export default gtmDestination
