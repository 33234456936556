import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFaceLaughSquint = createIcon({
  path: (
    <g>
      <path
        d="M5.07812 12.2656C4.92188 11.7578 5.35156 11.25 5.89844 11.25H14.2188C14.7656 11.25 15.1953 11.7578 15.0391 12.2656C14.375 14.4141 12.4219 15.9375 10.0781 15.9375C7.73438 15.9375 5.74219 14.4141 5.07812 12.2656ZM8.71094 6.99219C9.14062 7.22656 9.14062 7.8125 8.71094 8.04688L5.19531 9.92188C4.88281 10.0781 4.53125 9.84375 4.53125 9.49219C4.53125 9.41406 4.53125 9.29688 4.60938 9.21875L6.01562 7.5L4.60938 5.82031C4.53125 5.74219 4.53125 5.625 4.53125 5.54688C4.53125 5.19531 4.88281 4.96094 5.19531 5.11719L8.71094 6.99219ZM15.3516 5.82031L13.9453 7.5L15.3516 9.21875C15.4297 9.29688 15.4688 9.41406 15.4688 9.49219C15.4688 9.84375 15.0781 10.0781 14.7656 9.92188L11.25 8.04688C10.8203 7.8125 10.8203 7.22656 11.25 6.99219L14.7656 5.11719C15.0781 4.96094 15.4688 5.19531 15.4688 5.54688C15.4688 5.625 15.4297 5.74219 15.3516 5.82031ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconFaceLaughSquint
