import { useState } from 'react'
import { isServer } from 'lib/utils'

import useEffectOnce from './useEffectOnce'

type useIsRefInViewportProps = {
  ref: React.MutableRefObject<HTMLDivElement>
  threshold?: number
  rootMargin?: string
}
/**
 * @param {React.MutableRefObject<HTMLDivElement>}  ref - A ref of any kind.
 * @param {number | number[]} threshold - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed. https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/thresholds
 *  @param {string} rootMargin  https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/rootMargin
 * @return {boolean} If the element is visible or not
 */
const useIsRefInViewport = ({
  ref,
  threshold = 0,
  rootMargin,
}: useIsRefInViewportProps): boolean => {
  const [myElementIsVisible, updateMyElementIsVisible] = useState(true)
  if (isServer() || window.IntersectionObserver === undefined) {
    return true
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffectOnce(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        updateMyElementIsVisible(entry.isIntersecting)
      },
      { threshold, rootMargin },
    )
    if (ref && ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  })

  return myElementIsVisible
}

export default useIsRefInViewport
