import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconEnvelopeLight = createIcon({
  path: (
    <g>
      <path
        d="M2.5 3.75C1.79688 3.75 1.25 4.33594 1.25 5V6.5625L8.86719 12.1875C9.53125 12.6562 10.4297 12.6562 11.0938 12.1875L18.75 6.5625V5C18.75 4.33594 18.1641 3.75 17.5 3.75H2.5ZM1.25 8.125V15C1.25 15.7031 1.79688 16.25 2.5 16.25H17.5C18.1641 16.25 18.75 15.7031 18.75 15V8.125L11.8359 13.2031C10.7422 13.9844 9.21875 13.9844 8.125 13.2031L1.25 8.125ZM0 5C0 3.63281 1.09375 2.5 2.5 2.5H17.5C18.8672 2.5 20 3.63281 20 5V15C20 16.4062 18.8672 17.5 17.5 17.5H2.5C1.09375 17.5 0 16.4062 0 15V5Z"
        fill="black"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconEnvelopeLight
