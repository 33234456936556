import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconSun = createIcon({
  path: (
    <g>
      <path
        d="M20.7266 13.6953L18.8516 11.0391L20.7266 8.34375C21 7.91406 21.0781 7.40625 20.8828 6.9375C20.6875 6.46875 20.2578 6.11719 19.75 6.03906L16.5469 5.45312L15.9609 2.25C15.8828 1.74219 15.5312 1.3125 15.0625 1.11719C14.5938 0.921875 14.0859 1 13.6562 1.27344L11 3.14844L8.30469 1.27344C7.875 1 7.36719 0.921875 6.89844 1.11719C6.42969 1.3125 6.07812 1.74219 6 2.25L5.41406 5.45312L2.21094 6.03906C1.70312 6.11719 1.27344 6.46875 1.07812 6.9375C0.882812 7.40625 0.960938 7.91406 1.23438 8.34375L3.10938 11L1.23438 13.6953C0.960938 14.125 0.882812 14.6328 1.07812 15.1016C1.27344 15.5703 1.70312 15.9219 2.21094 16L5.41406 16.5859L6 19.7891C6.07812 20.2969 6.42969 20.7266 6.89844 20.9219C7.36719 21.0781 7.875 21.0391 8.30469 20.7656L11 18.8906L13.6562 20.7656C13.9297 20.9219 14.2031 21 14.5156 21C14.7109 21 14.9062 21 15.0625 20.9219C15.5312 20.7266 15.8828 20.2969 15.9609 19.7891L16.5469 16.5859L19.75 16C20.2578 15.9219 20.6875 15.5703 20.8828 15.1016C21.0781 14.6328 21 14.125 20.7266 13.6953ZM18.8125 14.2812L14.9453 14.9844L14.2422 18.8516L11 16.625L7.71875 18.8516L7.01562 14.9844L3.14844 14.2812L5.375 11L3.14844 7.75781L7.01562 7.05469L7.71875 3.1875L11 5.41406L14.2422 3.1875L14.9453 7.05469L18.8125 7.75781L16.5859 11L18.8125 14.2812ZM11 6.625C8.57812 6.625 6.625 8.57812 6.625 10.9609C6.625 13.3828 8.61719 15.2969 11 15.2969C13.3438 15.2969 15.3359 13.3438 15.3359 10.9609C15.3359 8.61719 13.3828 6.625 11 6.625ZM11 13.5C9.59375 13.5 8.5 12.3672 8.5 11C8.5 9.59375 9.59375 8.5 11 8.5C12.3672 8.5 13.4609 9.63281 13.4609 11C13.4609 12.3281 12.3672 13.5 11 13.5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 22 22',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconSun
