import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconPersonBreastfeeding = createIcon({
  path: (
    <g>
      <path
        d="M13.0859 3.125C13.0859 4.88281 11.7188 6.25 9.96094 6.25C8.24219 6.25 6.83594 4.88281 6.83594 3.125C6.83594 1.40625 8.24219 0 9.96094 0C11.7188 0 13.0859 1.40625 13.0859 3.125ZM9.96094 1.875C9.29688 1.875 8.71094 2.46094 8.71094 3.125C8.71094 3.82812 9.29688 4.375 9.96094 4.375C10.6641 4.375 11.2109 3.82812 11.2109 3.125C11.2109 2.46094 10.6641 1.875 9.96094 1.875ZM3.59375 13.9844C3.125 14.6484 3.39844 15.5469 4.10156 15.8984L9.41406 18.2031C9.88281 18.4375 10.1172 18.9844 9.88281 19.4531C9.6875 19.9219 9.14062 20.1562 8.67188 19.9219L3.35938 17.6172C1.52344 16.7969 0.898438 14.4922 2.10938 12.8906L4.29688 9.92188C5.39062 8.39844 7.1875 7.5 9.0625 7.5H11.3281C12.9297 7.5 14.4141 8.16406 15.5469 9.25781L17.6953 11.4062C18.8672 12.6172 18.9062 14.4922 17.8125 15.7031L15.3516 18.4375C15.0391 18.8281 14.4141 18.8672 14.0625 18.5156C13.6719 18.2031 13.6328 17.5781 13.9844 17.1875L16.4062 14.4531C16.875 13.9844 16.8359 13.2031 16.3672 12.7344L14.2188 10.5859C13.4766 9.80469 12.4219 9.375 11.3281 9.375H9.0625C7.77344 9.375 6.5625 10 5.78125 11.0547L3.59375 13.9844ZM8.71094 14.4531C9.49219 15.5859 10.7422 16.25 12.1094 16.25H12.1484C12.6953 16.25 13.0859 16.6797 13.0859 17.1875C13.0859 17.7344 12.6953 18.125 12.1484 18.125H12.1094C10.0781 18.125 8.16406 17.0703 7.10938 15.3516C6.95312 15.1172 6.83594 14.8047 6.83594 14.5312V12.8125C6.83594 12.3047 7.26562 11.875 7.77344 11.875C8.32031 11.875 8.71094 12.3047 8.71094 12.8125V14.4531ZM9.96094 13.125C9.96094 12.1094 10.8203 11.25 11.8359 11.25C12.8906 11.25 13.7109 12.1094 13.7109 13.125C13.7109 14.1797 12.8906 15 11.8359 15C10.8203 15 9.96094 14.1797 9.96094 13.125Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 19 21',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconPersonBreastfeeding
