import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconSpinnerThird = createIcon({
  path: (
    <g>
      <path
        d="M10 0.625C10 0.3125 10.2734 0 10.625 0C16.1328 0 20.625 4.49219 20.625 10C20.625 11.8359 20.1172 13.5547 19.2578 15C19.1016 15.3125 18.7109 15.4297 18.3984 15.2344C18.125 15.0781 18.0078 14.6875 18.2031 14.375C18.9453 13.125 19.375 11.6016 19.375 10C19.375 5.19531 15.4297 1.25 10.625 1.25C10.2734 1.25 10 0.976562 10 0.625Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 21 20',
  defaultProps: { fill: 'none', height: 14 },
})

export default IconSpinnerThird
