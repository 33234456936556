import React, { ReactNode, useContext, useState } from 'react'
import Cookies from 'lib/cookies'

import useEffectOnce from 'hooks/useEffectOnce'

type CookiesContextProps = {
  areCookiesAccepted: boolean
  setAreCookiesAccepted: (areCookiesAccepted: boolean) => void
}

const CookiesContext = React.createContext<CookiesContextProps>({
  areCookiesAccepted: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAreCookiesAccepted: () => {},
})

type CookiesContextProviderProps = {
  children: ReactNode
}

const CookiesContextProvider = ({
  children,
}: CookiesContextProviderProps): JSX.Element => {
  const [areCookiesAccepted, setAreCookiesAccepted] = useState(false)

  useEffectOnce(() => {
    if (Cookies.get('acceptsCookies') !== undefined) {
      setAreCookiesAccepted(true)
    }
  })

  return (
    <CookiesContext.Provider
      value={{ areCookiesAccepted, setAreCookiesAccepted }}
    >
      {children}
    </CookiesContext.Provider>
  )
}

const useCookiesContext = (): CookiesContextProps => useContext(CookiesContext)

export { useCookiesContext, CookiesContextProvider }
