import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconTurkey = createIcon({
  path: (
    <g>
      <path
        d="M19.4141 10.4922C19.2188 10.7656 19.0625 11.0781 18.9062 11.3516C19.9609 12.9141 20.625 14.6328 20.625 16C20.625 17.9922 19.0625 19.125 11.25 19.125C11.2109 19.125 11.25 19.125 11.25 19.125C3.39844 19.125 1.875 17.9922 1.875 16C1.875 12.5234 5.89844 7.01562 10.7812 6.66406C11.3672 6.50781 11.875 6.42969 12.3828 6.39062C13.0859 6.3125 13.7891 6.23438 15 5.72656C15.0391 5.72656 15.0781 5.6875 15.1562 5.6875C13.9062 5.10156 12.6172 4.75 11.2109 4.75C5.03906 4.75 0 11.5469 0 16C0 20.4531 5.03906 21 11.25 21C11.2109 21 11.25 21 11.25 21C17.4219 21 22.5 20.4922 22.5 16C22.5 14.0859 21.5625 11.7422 20 9.71094C19.8047 9.94531 19.6094 10.1797 19.4141 10.4922ZM22.1484 4.125C23.0078 2.71875 21.9531 0.960938 20.2734 1.03906C18.9453 1.03906 17.9688 2.32812 18.3203 3.61719C18.4766 4.16406 18.3984 4.67188 18.0078 5.17969C17.5781 5.80469 16.6406 6.42969 15.4688 6.89844C12.8906 7.95312 12.1875 7.32812 10.5078 8.10938C7.38281 9.51562 7.1875 13.2656 9.14062 15.2578C11.5625 17.6797 16.0547 16.7812 16.9531 12.9531C17.0312 12.4844 17.5391 11.1172 18.3594 9.82812C18.7891 9.16406 19.2188 8.61719 19.6875 8.26562C20.1953 7.83594 20.9766 7.40625 21.7969 7.79688C23.125 8.34375 24.8047 7.95312 24.9609 6.19531C25.1172 4.63281 23.5547 3.53906 22.1484 4.125Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 26 21',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconTurkey
