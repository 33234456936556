import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCircleQuestion = createIcon({
  path: (
    <g>
      <path
        d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125ZM10 13.125C9.29688 13.125 8.75 13.6719 8.75 14.375C8.75 15.0781 9.25781 15.625 10 15.625C10.6641 15.625 11.25 15.0781 11.25 14.375C11.25 13.6719 10.6641 13.125 10 13.125ZM11.2891 5H9.29688C7.77344 5 6.5625 6.21094 6.5625 7.73438C6.5625 8.24219 6.99219 8.67188 7.5 8.67188C8.00781 8.67188 8.4375 8.24219 8.4375 7.73438C8.4375 7.26562 8.78906 6.875 9.25781 6.875H11.25C11.7578 6.875 12.1875 7.26562 12.1875 7.73438C12.1875 8.04688 12.0312 8.32031 11.7578 8.47656L9.53125 9.80469C9.21875 10 9.0625 10.3125 9.0625 10.625V11.25C9.0625 11.7578 9.49219 12.1875 10 12.1875C10.5078 12.1875 10.9375 11.7578 10.9375 11.25V11.1719L12.6953 10.0781C13.5156 9.57031 14.0234 8.67188 14.0234 7.73438C14.0625 6.21094 12.8516 5 11.2891 5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconCircleQuestion
