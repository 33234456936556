import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconClockArrowRight = createIcon({
  path: (
    <g clipPath="url(#clip0_558_1417)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6523 26.2011C26.8876 26.2011 32.7529 20.3358 32.7529 13.1006C32.7529 5.86533 26.8876 0 19.6523 0C12.4171 0 6.55173 5.86533 6.55173 13.1006C6.55173 20.3358 12.4171 26.2011 19.6523 26.2011ZM21.3247 5.85332C21.3247 4.92967 20.576 4.18091 19.6523 4.18091C18.7287 4.18091 17.9799 4.92967 17.9799 5.85332V12.543C17.9799 13.4666 18.7287 14.2154 19.6523 14.2154H24.3908C25.3145 14.2154 26.0632 13.4666 26.0632 12.543C26.0632 11.6193 25.3145 10.8706 24.3908 10.8706H21.3247V5.85332Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0484 26.7065C29.4038 26.0449 28.345 26.0312 27.6835 26.6758C27.0219 27.3203 27.0082 28.3792 27.6528 29.0407L30.1547 31.6085H5.43679C4.51314 31.6085 3.76437 32.3572 3.76437 33.2809C3.76437 34.2045 4.51314 34.9533 5.43679 34.9533H30.2093L27.6528 37.5772C27.0082 38.2387 27.0219 39.2975 27.6835 39.9421C28.345 40.5867 29.4038 40.5729 30.0484 39.9114L35.3444 34.4761C35.9772 33.8266 35.9772 32.7912 35.3444 32.1418L30.0484 26.7065Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 40 40',
})

export default IconClockArrowRight
