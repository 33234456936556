import { gql } from '@apollo/client'
import fullPublicProfileProduct from 'graphql/fragments/fullPublicProfileProduct'

const businessReviewCardFragment = gql`
  fragment businessReviewCardFragment on PublicCmsItemBusinessReviewCard {
    id
    profileProducts {
      ...fullPublicProfileProduct
    }
    requestOccasion {
      id
      internalName
      title
      image
      emoji
      color
    }
    rating
    review
    date
    reviewer {
      fullName
      title
      companyName
      companyLogo {
        url
      }
    }
  }
  ${fullPublicProfileProduct}
`

export default businessReviewCardFragment
