const red = 'rgb(248, 40, 78)'
const grey = 'rgb(130, 130, 130)'
const darkGrey = 'rgb(39, 41, 45)'
const lightGrey = 'rgb(239, 240, 244)'
const lighterGrey = 'rgb(225, 226, 233)'
const lightBlue = 'rgb(93, 172, 232)'
const lightYellow = 'rgb(230, 203, 164)'
const white = 'rgb(255, 255, 255)'
const green = '#1BB55C'
const blue = '#0F56B3'
const tertiaryBlue700 = '#2C44A5'

// synced from assets/vars.css
const colorsPrimary400 = '#f8284e'
const colorsSecondary100 = '#f8f8f9'
const colorsSecondary900 = '#27292d'
const colorsSecondary300 = '#e6e9ee'
const colorsSecondary200 = '#eff0f4'
const colorsSurface800 = '#303136'
const colorsSurface700 = '#55595e'
const colorsSurface500 = '#95979f'
const colorsSurface000 = '#fff'

const mobileMaxWidth = 767
const desktopMinWidth = 768
const isMobileMediaQuery = { maxWidth: mobileMaxWidth }
const isDesktopMediaQuery = { minWidth: desktopMinWidth }

export {
  red,
  grey,
  darkGrey,
  lightGrey,
  lighterGrey,
  lightBlue,
  lightYellow,
  white,
  green,
  blue,
  tertiaryBlue700,
  isMobileMediaQuery,
  isDesktopMediaQuery,
  mobileMaxWidth,
  desktopMinWidth,
  colorsPrimary400,
  colorsSecondary100,
  colorsSecondary900,
  colorsSecondary300,
  colorsSecondary200,
  colorsSurface800,
  colorsSurface700,
  colorsSurface500,
  colorsSurface000,
}
