import React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import styles from 'components/CtaButton/CtaButton.module.css'
import { IconSpinnerThird } from 'components/UI/Icons'

const CtaButton = (
  allProps: ButtonProps,
  ref: React.MutableRefObject<undefined>,
) => {
  const {
    children,
    size = 'large',
    type = 'primary',
    style,
    loading,
    disabled = false,
    ...props
  } = allProps

  return (
    <Button
      ref={ref}
      size={size}
      style={style}
      type={type}
      disabled={disabled || !!loading}
      {...props}
    >
      {loading ? (
        <>
          <IconSpinnerThird
            className={styles.spinIcon}
            height={18}
            style={style}
          />
          <span style={{ opacity: 0.5, paddingLeft: 10 }}>{children}</span>
        </>
      ) : (
        children
      )}
    </Button>
  )
}

export default React.forwardRef(CtaButton)
