import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCakeCandles = createIcon({
  path: (
    <g>
      <path
        d="M15.2109 8.75H14.9375V6.5625C14.9375 6.05469 14.5078 5.625 14 5.625C13.4531 5.625 13.0625 6.05469 13.0625 6.5625V8.75H9.9375V6.5625C9.9375 6.05469 9.50781 5.625 9 5.625C8.45312 5.625 8.0625 6.05469 8.0625 6.5625V8.75H4.9375V6.5625C4.9375 6.05469 4.50781 5.625 4 5.625C3.45312 5.625 3.0625 6.05469 3.0625 6.5625V8.75H2.75C1.34375 8.75 0.25 9.84375 0.25 11.2109V18.7109C0.25 19.4531 0.796875 20 1.5 20H16.5C17.1641 20 17.75 19.4531 17.75 18.75V11.25C17.75 9.88281 16.6172 8.75 15.2109 8.75ZM15.875 18.125H2.125V15.3516C2.82812 15.2344 3.45312 14.9609 4 14.6094C4.66406 15.0391 5.52344 15.4297 6.5 15.4297C7.4375 15.4297 8.29688 15.0391 9 14.6094C9.66406 15.0391 10.5234 15.4297 11.5 15.4297C12.4375 15.4297 13.2969 15.0391 14 14.6094C14.5078 14.9609 15.1328 15.2734 15.875 15.3906V18.125ZM15.875 13.4375C15.3281 13.2812 14.8594 12.9297 14.5469 12.7344C14.2344 12.4609 13.7266 12.4609 13.4141 12.7344C12.9844 13.0469 12.2422 13.5547 11.5 13.5547C10.7188 13.5547 9.97656 13.0469 9.54688 12.7344C9.23438 12.4609 8.72656 12.4609 8.41406 12.7344C7.98438 13.0469 7.24219 13.5547 6.5 13.5547C5.71875 13.5547 4.97656 13.0469 4.54688 12.7344C4.23438 12.4609 3.72656 12.4609 3.41406 12.7344C3.10156 12.9297 2.63281 13.2812 2.125 13.4375V11.25C2.125 10.9375 2.39844 10.625 2.75 10.625H15.25C15.5625 10.625 15.875 10.9375 15.875 11.25V13.4375ZM14 4.375C14.8594 4.375 15.5625 3.67188 15.5625 2.8125C15.5625 1.95312 14 0 14 0C14 0 12.4375 1.95312 12.4375 2.8125C12.4375 3.71094 13.1016 4.375 14 4.375ZM9 4.375C9.85938 4.375 10.5625 3.67188 10.5625 2.8125C10.5625 1.95312 9 0 9 0C9 0 7.4375 1.95312 7.4375 2.8125C7.4375 3.71094 8.10156 4.375 9 4.375ZM4 4.375C4.85938 4.375 5.5625 3.67188 5.5625 2.8125C5.5625 1.95312 4 0 4 0C4 0 2.4375 1.95312 2.4375 2.8125C2.4375 3.71094 3.10156 4.375 4 4.375Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 18 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconCakeCandles
