import React from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useGeneralContext } from 'contexts/GeneralContext'
import { useTranslation } from 'lib/i18n'
import { useRouter } from 'next/router'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'

import styles from './SearchButton.module.css'

type SearchButtonProps = {
  dark?: boolean
}

const SearchButton: React.FC<SearchButtonProps> = ({ dark = false }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setCloseToPath } = useGeneralContext()

  const setFromPath = () => {
    if (router.pathname.includes('search')) {
      window.scrollTo(0, 0)
      const searchInput = document.querySelector(
        '#searchInputWrapper input',
      ) as HTMLElement | null
      searchInput.focus()
    }
    setCloseToPath(router.asPath)
  }

  return (
    <Link href="/search" prefetch={false}>
      <CtaButton
        size="small"
        ghost
        type="link"
        icon={
          <FontAwesomeIcon
            icon={faSearch}
            color={dark ? 'var(--colors-secondary-900)' : '#fff'}
          />
        }
        className={cn(styles.searchButton, {
          [styles.searchButtonDark]: dark,
        })}
        onClick={setFromPath}
      >
        <span className={styles.searchButtonText}>
          {t('navigationBar.search', 'Search')}
        </span>
      </CtaButton>
    </Link>
  )
}

export default SearchButton
