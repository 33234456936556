import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRedCheckMark = createIcon({
  path: (
    <g>
      <circle cx="9.5" cy="9.5" r="9.5" fill="#F8284E" />
      <path
        d="M4.4707 8.9406L8.10306 12.2935L13.9707 6.14648"
        stroke="white"
        strokeWidth="2"
      />
    </g>
  ),
  viewBox: '0 0 19 19',
  defaultProps: {
    height: 19,
    fill: 'none',
    'aria-label': 'Check Mark',
  },
})

export default IconRedCheckMark
