import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconAdd = createIcon({
  path: (
    <g>
      <circle cx="19" cy="19" r="18" stroke="#27292D" strokeWidth="2" />
      <path
        d="M13 20.9172V18.0828H17.6612V13.5H20.3636V18.0828H25V20.9172H20.3636V25.5H17.6612V20.9172H13Z"
        fill="#27292D"
      />
    </g>
  ),
  viewBox: '0 0 38 38',
  defaultProps: { fill: 'none', height: 38 },
})

export default IconAdd
