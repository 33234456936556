import { gql, useQuery } from '@apollo/client'
import availableMarkets from 'lib/markets.json'

export const DETECTED_MARKET_QUERY = gql`
  query GetDetectedMarket {
    detectedMarket @client {
      detectedBy
      language
      market
    }
  }
`

export default function useDetectedMarket() {
  const { data } = useQuery(DETECTED_MARKET_QUERY)
  const detectedMarket = data?.detectedMarket

  const getMarketById = (marketId) => {
    return availableMarkets.find((mrkt) => mrkt.id === marketId)
  }

  return {
    detectedMarket,
    getMarketById,
  }
}
