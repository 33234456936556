import React from 'react'
import cn from 'classnames'
import { useGeneralContext } from 'contexts/GeneralContext'
import { useMarketContext } from 'contexts/MarketContext'
import { useTranslation } from 'lib/i18n'
import { useRouter } from 'next/router'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'
import useLanguage from 'hooks/useLanguage'

import CompanyIcon from './company.svg'
import CompanyIconDark from './company-dark.svg'

import styles from './BusinessButton.module.css'

type BusinessButtonProps = {
  dark?: boolean
}

const BusinessButton: React.FC<BusinessButtonProps> = ({ dark = false }) => {
  const { t } = useTranslation()
  const { market } = useMarketContext()
  const language = useLanguage()
  const router = useRouter()
  const { setCloseToPath } = useGeneralContext()

  const { businessPage } = market.selectedMarket?.config || {}
  const businessPageLink =
    businessPage && (businessPage as string)[language.code]

  const setFromPath = () => {
    setCloseToPath(router.asPath)
  }

  if (!businessPageLink) {
    return null
  }

  return (
    <Link href={businessPageLink}>
      <CtaButton
        size="small"
        ghost
        type="link"
        icon={
          <img
            alt=""
            src={dark ? CompanyIconDark : CompanyIcon}
            className={styles.businessIcon}
          />
        }
        className={cn(styles.businessButton, {
          [styles.businessButtonDark]: dark,
        })}
        onClick={setFromPath}
      >
        <span className={styles.businessButtonText}>
          {t('navigationBar.forBusinessLink', 'For Business')}
        </span>
      </CtaButton>
    </Link>
  )
}

export default BusinessButton
