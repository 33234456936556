import availableMarkets from 'lib/markets.json'

const selectMarket = ({ marketId, languageId = '', languageCode = '' }) => {
  if (!marketId) return {}

  const market = availableMarkets.find((mrkt) => marketId === mrkt.id)
  if (!market) return {}

  const language = market?.languages.find(
    (lng) => languageId === lng.id || languageCode === lng.code,
  )

  const selectedCurrency = market.defaultCurrency

  const availableCurrencies = market.currencies.filter(
    (c) => c !== selectedCurrency,
  )

  return {
    selectedCurrency,
    availableCurrencies,
    selectedLanguage: language || market.defaultLanguage,
    availableLanguages: market.languages,
    selectedMarket: market,
    indexedLanguages: market.indexedLanguages,
  }
}

export default selectMarket
