import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconDelete = createIcon({
  path: (
    <g>
      <rect width="38" height="38" rx="19" fill="#EFF0F4" />
      <path
        d="M26.4913 10.4853C26.227 10.4916 25.976 10.6022 25.7931 10.7929L19.0001 17.5859L12.2071 10.7929C12.1138 10.697 12.0022 10.6208 11.8789 10.5688C11.7556 10.5168 11.6232 10.4901 11.4893 10.4902C11.2905 10.4905 11.0962 10.55 10.9314 10.6612C10.7665 10.7723 10.6385 10.9302 10.5638 11.1144C10.4891 11.2987 10.471 11.5011 10.5118 11.6957C10.5526 11.8903 10.6506 12.0683 10.7931 12.207L17.586 19L10.7931 25.7929C10.6971 25.8851 10.6205 25.9955 10.5677 26.1176C10.5149 26.2397 10.487 26.3712 10.4856 26.5042C10.4843 26.6372 10.5095 26.7692 10.5598 26.8924C10.6101 27.0156 10.6844 27.1275 10.7785 27.2215C10.8726 27.3156 10.9845 27.39 11.1077 27.4403C11.2309 27.4906 11.3628 27.5158 11.4959 27.5144C11.6289 27.5131 11.7603 27.4852 11.8825 27.4324C12.0046 27.3796 12.115 27.303 12.2071 27.207L19.0001 20.414L25.7931 27.207C25.8852 27.303 25.9956 27.3796 26.1177 27.4324C26.2398 27.4852 26.3713 27.5131 26.5043 27.5144C26.6373 27.5158 26.7693 27.4906 26.8925 27.4403C27.0157 27.39 27.1276 27.3156 27.2217 27.2215C27.3157 27.1275 27.3901 27.0156 27.4404 26.8924C27.4907 26.7692 27.5159 26.6372 27.5145 26.5042C27.5132 26.3711 27.4853 26.2397 27.4325 26.1176C27.3797 25.9955 27.3031 25.8851 27.2071 25.7929L20.4141 19L27.2071 12.207C27.3526 12.0675 27.4526 11.8872 27.4939 11.6899C27.5351 11.4926 27.5158 11.2874 27.4384 11.1012C27.361 10.915 27.2291 10.7566 27.0601 10.6467C26.8911 10.5368 26.6928 10.4806 26.4913 10.4853Z"
        fill="#95979F"
      />
    </g>
  ),
  viewBox: '0 0 38 38',
  defaultProps: { fill: 'none', height: 38 },
})

export default IconDelete
