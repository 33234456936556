import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconClockMail = createIcon({
  path: (
    <path
      d="M35.0529 5C41.1602 5 46.1111 9.92487 46.1111 16C46.1111 22.0751 41.1602 27 35.0529 27C28.9456 27 23.9947 22.0751 23.9947 16C23.9947 9.92487 28.9456 5 35.0529 5ZM39.0741 16.0001H35.0529L35.0529 10.9999C35.0529 10.4476 34.6028 9.99987 34.0476 9.99987C33.4924 9.99987 33.0423 10.4476 33.0423 10.9999L33.0423 16.9971L33.0423 17.0001C33.0423 17.5524 33.4924 18.0001 34.0476 18.0001H39.0741C39.6293 18.0001 40.0794 17.5524 40.0794 17.0001C40.0794 16.4478 39.6293 16.0001 39.0741 16.0001ZM41.0847 36.5V27.5356C42.1925 26.9612 43.2073 26.2335 44.1005 25.3809V36.5C44.1005 39.9661 41.3733 42.7985 37.9369 42.9897L37.5661 43H10.4233C6.93888 43 4.09146 40.2871 3.89923 36.8688L3.88889 36.5V17.5C3.88889 14.0339 6.61615 11.2015 10.0525 11.0103L10.4233 11H22.9857C22.5882 11.9483 22.3003 12.9536 22.1378 14H10.4233C8.57721 14 7.06321 15.4142 6.91642 17.2129L6.90476 17.5V18.358L23.9947 27.3049L26.6189 25.9308C27.5006 26.6725 28.4823 27.3001 29.5412 27.7907L24.697 30.3274C24.3202 30.5247 23.8804 30.5528 23.4856 30.4119L23.2924 30.3274L6.90476 21.748V36.5C6.90476 38.3363 8.32646 39.8424 10.1347 39.9884L10.4233 40H37.5661C39.4122 40 40.9262 38.5858 41.073 36.7871L41.0847 36.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 48 48',
})

export default IconClockMail
