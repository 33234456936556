import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFire = createIcon({
  path: (
    <g>
      <path
        d="M12.8672 2.03125C12.0469 2.77344 11.3438 3.55469 10.6797 4.375C9.625 2.89062 8.29688 1.40625 6.8125 0C2.94531 3.59375 0.25 8.20312 0.25 11.0156C0.25 15.9766 4.15625 20 9 20C13.8047 20 17.75 15.9766 17.75 11.0156C17.75 8.94531 15.7188 4.64844 12.8672 2.03125ZM9 18.125C5.21094 18.125 2.125 14.9609 2.125 11.0156C2.125 9.25781 3.84375 5.82031 6.8125 2.65625C7.67188 3.55469 8.49219 4.53125 9.15625 5.46875L10.6016 7.42188L12.125 5.54688C12.3594 5.27344 12.5938 5 12.8281 4.72656C14.625 6.95312 15.875 9.80469 15.875 11.0156C15.875 14.9609 12.7891 18.125 9 18.125ZM12.4766 9.53125L10.4844 11.7969C10.4844 11.7969 7.32031 7.77344 7.08594 7.5C5.44531 9.49219 4.625 10.6641 4.625 11.9922C4.625 14.6484 6.61719 16.25 9.07812 16.25C10.0547 16.25 10.9922 15.9766 11.7344 15.4297C13.4141 14.2578 13.8047 11.9922 12.8672 10.1953C12.75 9.96094 12.6328 9.76562 12.4766 9.53125Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 18 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconFire
