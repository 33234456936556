import { useMarketContext } from '../contexts/MarketContext'

type SelectedMarketProps = {
  id: string
  name: string
  emergingMarket?: boolean
  defaultCurrency: string
  config: {
    instagram: string
    facebook: string
    supportEmail?: string
    businessPage: { [key: string]: string }
    blogPage: { [key: string]: string }
  }
  dateTimeFormat: string
  defaultLanguage: {
    code: string
    dayjs: string
    id: string
    iso: string
    name: string
    native: string
  }
  countries: {
    capital: string
    code: string
    continent: string
    emoji: string
    id: string
    name: string
    native: string
    phone: string
  }[]
}

export default function useMarket(): SelectedMarketProps {
  const { market } = useMarketContext()
  const { selectedMarket } = market
  return selectedMarket
}
