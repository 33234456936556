import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMegaphone = createIcon({
  path: (
    <g>
      <path
        d="M22.75 2.1875V17.8125C22.75 18.3594 22.3203 18.75 21.8125 18.75C21.2656 18.75 20.875 18.3594 20.875 17.8125V17.6172L13.9219 15.7031C13.5703 17.4609 12.0469 18.75 10.25 18.75C8.14062 18.75 6.5 17.0703 6.5 15C6.5 14.5703 6.53906 14.1406 6.69531 13.75L2.125 12.5V12.8125C2.125 13.3594 1.69531 13.75 1.1875 13.75C0.640625 13.75 0.25 13.3594 0.25 12.8125V7.1875C0.25 6.67969 0.640625 6.25 1.1875 6.25C1.69531 6.25 2.125 6.67969 2.125 7.1875V7.53906L20.875 2.42188V2.1875C20.875 1.67969 21.2656 1.25 21.8125 1.25C22.3203 1.25 22.75 1.67969 22.75 2.1875ZM20.875 15.6641V4.375L2.125 9.49219V10.5469L20.875 15.6641ZM10.25 16.875C11.1875 16.875 12.0078 16.1719 12.0859 15.2344L8.53125 14.2578C8.41406 14.4922 8.375 14.7266 8.375 15C8.375 16.0547 9.19531 16.875 10.25 16.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 23 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconMegaphone
