import React from 'react'
import cn from 'classnames'
import { GiftingOccasion } from 'constants/index'

import useGiftingOccasions from 'hooks/useGiftingOccasions'
import useLanguage from 'hooks/useLanguage'
import useMarket from 'hooks/useMarket'

import styles from './Wrapper.module.css'

type WrapperProps = {
  isHome: boolean
}

const Wrapper: React.FC<WrapperProps> = ({ isHome, children, ...props }) => {
  const market = useMarket()
  const language = useLanguage()
  const { marketIsGifting, giftingOccasion } = useGiftingOccasions()
  const marketLocale = [market.id, language.code].join('-')

  if (!isHome) return <>{children}</>

  // Wrapper only needed because of Fathers Day A/B
  if (!marketIsGifting && giftingOccasion !== GiftingOccasion.FathersDay)
    return <>{children}</>

  return (
    <div className={cn(styles[marketLocale], styles.abwrapper)} {...props}>
      {children}
    </div>
  )
}

export default Wrapper
