import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconChampagneGlasses = createIcon({
  path: (
    <g>
      <path
        d="M25.9219 16.5234C25.7656 16.1719 25.4141 15.9766 25.0625 15.9766C24.9453 15.9766 24.7891 15.9766 24.6719 16.0156L22.9531 16.7578L21.2734 12.6172C23.3047 11.3281 24.0859 8.71094 22.875 6.60156L19.5547 0.664062C19.3203 0.273438 18.8906 0 18.4609 0C18.3047 0 18.1484 0.0390625 17.9922 0.117188L13.5 1.83594L8.96875 0.117188C8.8125 0.0390625 8.65625 0 8.5 0C8.03125 0 7.60156 0.273438 7.36719 0.664062L4.08594 6.60156C2.875 8.71094 3.65625 11.3281 5.6875 12.6172L4.00781 16.7578L2.28906 16.0156C2.17188 15.9766 2.05469 15.9766 1.89844 15.9766C1.54688 15.9766 1.19531 16.1719 1.03906 16.5234C0.84375 17.0312 1.07812 17.5781 1.54688 17.7734L6.85938 19.9609C6.97656 20 7.09375 20 7.21094 20C7.5625 20 7.91406 19.8047 8.07031 19.4531C8.26562 18.9453 8.03125 18.3984 7.5625 18.2031L5.72656 17.4609L7.40625 13.3203C7.71875 13.3984 8.03125 13.4375 8.34375 13.4375C10.4141 13.4375 12.3281 12.1094 12.875 10.0391L13.4609 7.69531L14.0469 10.0391C14.5547 12.1094 16.4688 13.4375 18.5391 13.4375C18.8516 13.4375 19.1641 13.3984 19.4766 13.3203L21.1562 17.4609L19.3203 18.2031C18.8516 18.3984 18.6172 18.9453 18.8125 19.4531C19.0078 19.8047 19.3594 20 19.75 20C19.8672 20 19.9844 20 20.1016 19.9609L25.4141 17.7734C25.8828 17.5781 26.1172 17.0312 25.9219 16.5234ZM5.72656 7.5L6.74219 5.66406L11.5469 7.69531L11.0781 9.57031C10.0625 13.4766 3.73438 11.0547 5.72656 7.5ZM11.9766 5.89844L7.67969 4.0625L8.8125 2.03125L12.6406 3.51562L11.9766 5.89844ZM14.3594 3.51562L18.1875 2.03125L19.3203 4.0625L14.9844 5.89844L14.3594 3.51562ZM15.8828 9.57031L15.4141 7.73438L20.2188 5.70312L21.2344 7.5C23.2266 11.0547 16.8984 13.4766 15.8828 9.57031Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 27 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconChampagneGlasses
