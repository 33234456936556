import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconClose = createIcon({
  path: (
    <path
      d="M16.492 0.435826C16.2278 0.442063 15.9767 0.552661 15.7938 0.743443L9.00082 7.53641L2.20785 0.743443C2.11454 0.647531 2.00294 0.571318 1.87965 0.519317C1.75636 0.467317 1.62389 0.440586 1.49008 0.440709C1.29123 0.440955 1.09696 0.500474 0.932104 0.611662C0.767245 0.72285 0.639273 0.880659 0.564538 1.06493C0.489802 1.2492 0.471695 1.45157 0.512529 1.64618C0.553364 1.84079 0.651286 2.01882 0.793787 2.15751L7.58676 8.95047L0.793787 15.7434C0.697813 15.8356 0.62119 15.946 0.568405 16.0681C0.51562 16.1902 0.487734 16.3217 0.48638 16.4547C0.485026 16.5877 0.510232 16.7197 0.560521 16.8429C0.61081 16.9661 0.685172 17.078 0.779251 17.172C0.87333 17.2661 0.985235 17.3405 1.10841 17.3908C1.23159 17.4411 1.36356 17.4663 1.4966 17.4649C1.62964 17.4636 1.76108 17.4357 1.88321 17.3829C2.00534 17.3301 2.1157 17.2535 2.20785 17.1575L9.00082 10.3645L15.7938 17.1575C15.8859 17.2535 15.9963 17.3301 16.1184 17.3829C16.2406 17.4357 16.372 17.4636 16.505 17.4649C16.6381 17.4663 16.77 17.4411 16.8932 17.3908C17.0164 17.3405 17.1283 17.2661 17.2224 17.172C17.3165 17.078 17.3908 16.9661 17.4411 16.8429C17.4914 16.7197 17.5166 16.5877 17.5153 16.4547C17.5139 16.3216 17.486 16.1902 17.4332 16.0681C17.3804 15.946 17.3038 15.8356 17.2078 15.7434L10.4149 8.95047L17.2078 2.15751C17.3534 2.01797 17.4533 1.83772 17.4946 1.64039C17.5359 1.44305 17.5165 1.23785 17.4391 1.0517C17.3617 0.865546 17.2299 0.707128 17.0609 0.597224C16.8919 0.487321 16.6936 0.431062 16.492 0.435826Z"
      fill="#27292D"
    />
  ),
  viewBox: '0 0 18 18',
  defaultProps: { fill: 'none', height: 18 },
})

export default IconClose
