import { gql } from '@apollo/client'

const heroWithImageFragment = gql`
  fragment heroWithImageFragment on PublicCmsItemHeroWithImage {
    id
    type
    titleWeight
    titleSize
    title
    titleColor
    textWeight
    textSize
    text
    textColor
    ctaButtonLabel
    ctaButtonLabelColor
    ctaButtonBorderColor
    ctaButtonHoverLabelColor
    ctaButtonHoverBackgroundColor
    ctaButtonHoverBorderColor
    ctaButtonLink
    imageMobile {
      id
      width
      height
      url
    }
    imageDesktop {
      id
      width
      height
      url
    }
    boxColor
  }
`

export default heroWithImageFragment
