import { gql } from '@apollo/client'
import { paginate } from 'graphql/pagination'
import {
  currentLanguageCodeVar,
  currentLanguageIdVar,
  currentMarketIdVar,
} from 'graphql/reactive'

export const typeDefs = gql`
  type DetectedMarket {
    detectedBy: String!
    isCrawler: Boolean
    isMismatch: Boolean
    language: String
    market: String
    suggestedBy: String
    suggestedLanguage: String
    suggestedMarket: String
  }

  extend type Query {
    detectedMarket: DetectedMarket
    currentMarketId: String!
    currentLanguageId: String!
  }
`

export const typePolicies = {
  PublicProfileCollection: {
    keyFields: ['slug'],
    fields: {
      profiles: paginate(),
    },
  },
  PublicProfile: {
    keyFields: ['slug'],
    fields: {
      inProfileCollections: {
        profiles: paginate(['slug']),
      },
    },
  },
  PublicPotentialProfile: {
    keyFields: ['slug'],
  },
  ProfileSearchResult: {
    keyFields: ['q'],
    fields: {
      profileProducts: paginate(),
      potentialProfiles: paginate(),
    },
  },
  Page: {
    keyFields: ['slug', 'marketId'],
  },
  Amount: {
    keyFields: ['currency', 'formattedValue'],
  },
  Market: {
    fields: {
      profileCollection: {
        read(existing, { args, toReference }) {
          return (
            existing ||
            toReference({
              __typename: 'PublicProfileCollection',
              slug: args.slug,
            })
          )
        },
      },
      request: {
        read(existing, { args, toReference }) {
          return (
            existing ||
            toReference({
              __typename: 'PublicVideoRequest',
              id: args.id,
            })
          )
        },
      },
      profile: {
        read(existing, { args, toReference }) {
          return (
            existing ||
            toReference({ __typename: 'PublicProfile', slug: args.slug })
          )
        },
      },
      potentialProfile: {
        read(existing, { args, toReference }) {
          return (
            existing ||
            toReference({
              __typename: 'PublicPotentialProfile',
              slug: args.slug,
            })
          )
        },
      },
      profiles: paginate(['marketId']),
    },
  },
  Query: {
    fields: {
      currentMarketId: {
        read() {
          return currentMarketIdVar()
        },
      },
      currentLanguageId: {
        read() {
          return currentLanguageIdVar()
        },
      },
      currentLanguageCode: {
        read() {
          return currentLanguageCodeVar()
        },
      },
    },
  },
}
