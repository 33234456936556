import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMoney = createIcon({
  path: (
    <path
      d="M1.75 0C0.792523 0 0 0.792523 0 1.75V10.25C0 11.2075 0.792523 12 1.75 12H17.25C18.2075 12 19 11.2075 19 10.25V1.75C19 0.792523 18.2075 0 17.25 0H1.75ZM3 1.5H16C16 2.33 16.67 3 17.5 3V9C16.67 9 16 9.67 16 10.5H3C3 9.67 2.33 9 1.5 9V3C2.33 3 3 2.33 3 1.5ZM9.5 2.5C8.52083 2.5 7.69763 2.99205 7.20605 3.66797C6.71448 4.34388 6.5 5.17614 6.5 6C6.5 6.82386 6.71448 7.65612 7.20605 8.33203C7.69763 9.00795 8.52083 9.5 9.5 9.5C10.4792 9.5 11.3024 9.00795 11.7939 8.33203C12.2855 7.65612 12.5 6.82386 12.5 6C12.5 5.17614 12.2855 4.34388 11.7939 3.66797C11.3024 2.99205 10.4792 2.5 9.5 2.5ZM9.5 4C10.0208 4 10.3226 4.19545 10.5811 4.55078C10.8395 4.90612 11 5.44886 11 6C11 6.55114 10.8395 7.09388 10.5811 7.44922C10.3226 7.80455 10.0208 8 9.5 8C8.97917 8 8.67737 7.80455 8.41895 7.44922C8.16052 7.09388 8 6.55114 8 6C8 5.44886 8.16052 4.90612 8.41895 4.55078C8.67737 4.19545 8.97917 4 9.5 4ZM3.5 5C3.23478 5 2.98043 5.10536 2.79289 5.29289C2.60536 5.48043 2.5 5.73478 2.5 6C2.5 6.26522 2.60536 6.51957 2.79289 6.70711C2.98043 6.89464 3.23478 7 3.5 7C3.76522 7 4.01957 6.89464 4.20711 6.70711C4.39464 6.51957 4.5 6.26522 4.5 6C4.5 5.73478 4.39464 5.48043 4.20711 5.29289C4.01957 5.10536 3.76522 5 3.5 5ZM15.5 5C15.2348 5 14.9804 5.10536 14.7929 5.29289C14.6054 5.48043 14.5 5.73478 14.5 6C14.5 6.26522 14.6054 6.51957 14.7929 6.70711C14.9804 6.89464 15.2348 7 15.5 7C15.7652 7 16.0196 6.89464 16.2071 6.70711C16.3946 6.51957 16.5 6.26522 16.5 6C16.5 5.73478 16.3946 5.48043 16.2071 5.29289C16.0196 5.10536 15.7652 5 15.5 5ZM1.52246 12.999C1.63296 13.8265 2.31671 14.469 3.17871 14.499C8.61621 14.687 12.6412 16.4987 14.8037 17.4717C15.1887 17.6452 15.5199 17.7933 15.7939 17.9053C15.9454 17.9673 16.1042 17.998 16.2627 17.998C16.5047 17.998 16.7442 17.9266 16.9512 17.7881C17.2947 17.5576 17.5 17.1718 17.5 16.7568V13H16V15C15.4323 15 14.9453 15.3166 14.6904 15.7803C12.3156 14.7318 8.40476 13.1788 3.23047 13C3.22947 13 3.22902 12.999 3.22852 12.999H1.52246Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 19 18',
})

export default IconMoney
