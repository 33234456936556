import { pushToDataLayer } from 'lib/tracking'

import getMarketTrackingProperties from './utils/getMarketTrackingProperties'

type loggedInParameters = {
  userId: string
}

const trackLoggedIn = async (paramaters: loggedInParameters): Promise<void> => {
  const { userId } = paramaters

  pushToDataLayer({
    event: 'userLogin',
    userId,
  })

  const { loggedIn } = await import('./sdk')
  loggedIn({
    ...getMarketTrackingProperties(),
    userId_: userId,
    userId,
  })
}

export default trackLoggedIn
