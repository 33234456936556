import React from 'react'
import cn from 'classnames'

import styles from './Wrapper.module.css'

type WrapperProps = {
  edgeToEdge?: boolean
  style?: React.CSSProperties
  className?: string
}

const Wrapper: React.FC<WrapperProps> = ({
  edgeToEdge = false,
  style,
  children,
  className,
  ...props
}) => (
  <div
    className={cn(className, styles.wrapper, {
      [styles.edgeToEdge]: edgeToEdge,
    })}
    style={style}
    {...props}
  >
    {children}
  </div>
)

export default Wrapper
