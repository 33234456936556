import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconThumbsUp = createIcon({
  path: (
    <g>
      <path
        d="M3.75 7.5H1.25C0.546875 7.5 0 8.04688 0 8.71094V17.4219C0 18.0859 0.546875 18.6328 1.25 18.6328H3.75C4.41406 18.6328 5 18.0859 5 17.4219V8.75C5 8.08594 4.41406 7.5 3.75 7.5ZM20 8.86719C20 7.46094 18.7891 6.28906 17.3828 6.28906H13.4766C13.8281 5.3125 14.0625 4.45312 14.0625 3.94531C14.0625 2.61719 13.0078 1.28906 11.2891 1.28906C9.49219 1.28906 8.98438 2.53906 8.59375 3.55469C7.34375 6.71875 6.25 6.13281 6.25 7.1875C6.25 7.69531 6.64062 8.125 7.1875 8.125C7.38281 8.125 7.57812 8.08594 7.73438 7.92969C10.7422 5.54688 10 3.16406 11.2891 3.16406C11.9531 3.16406 12.1875 3.63281 12.1875 3.94531C12.1875 4.25781 11.875 5.50781 11.1719 6.75781C11.0938 6.91406 11.0547 7.07031 11.0547 7.22656C11.0547 7.77344 11.4844 8.125 11.9922 8.125H17.3438C17.7734 8.16406 18.125 8.47656 18.125 8.86719C18.125 9.25781 17.8125 9.60938 17.4219 9.60938C16.9141 9.64844 16.5234 10.0781 16.5234 10.5469C16.5234 11.1719 16.9922 11.1719 16.9922 11.6797C16.9922 12.6562 15.625 12.1484 15.625 13.3203C15.625 13.75 15.8594 13.8281 15.8594 14.1797C15.8594 15.0781 14.6875 14.7266 14.6875 15.7812C14.6875 15.9766 14.7656 16.0156 14.7656 16.1719C14.7656 16.5625 14.4141 16.9141 14.0234 16.9141H11.9531C10.9375 16.9141 9.96094 16.5625 9.17969 15.9766L7.73438 14.8828C7.57812 14.7656 7.38281 14.6875 7.1875 14.6875C6.64062 14.6875 6.21094 15.1562 6.21094 15.625C6.21094 15.9375 6.36719 16.2109 6.60156 16.4062L8.04688 17.4609C9.17969 18.3203 10.5469 18.75 11.9531 18.75H14.0234C15.3906 18.75 16.5234 17.6953 16.6016 16.3281C17.3047 15.8594 17.7344 15.0781 17.7344 14.1797C17.7344 14.0625 17.7344 13.9453 17.7344 13.8281C18.3984 13.3594 18.8672 12.5781 18.8672 11.6797C18.8672 11.4844 18.8281 11.2891 18.7891 11.0547C19.4922 10.5859 20 9.80469 20 8.86719Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconThumbsUp
