// Uses next/router not hooks/useRouter

import config from 'lib/nextConfig'
import pathHelpers from 'lib/pathHelpers'
import { useRouter } from 'next/router'
import querystring from 'querystring'

export default function usePath() {
  const router = useRouter()
  const path = pathHelpers(router.asPath)

  const { filteredPath, isLocalized, hasOnlyMarket, market, language } = path

  // Next router internal query params
  const nextRouterQueryParams = () => {
    const query = { ...router.query }
    if (query.path) {
      delete query.path
    }
    const params = querystring.stringify(query)
    return params ? `?${params}` : ''
  }

  // Actual URL query params
  const queryParams = () => {
    const params = router.asPath.split('?')[1]
    return params ? `?${params}` : ''
  }

  const urlSearchParams = () => {
    const params = new URLSearchParams(queryParams())
    return params
  }

  const asPath = () => {
    return router.asPath
  }

  const isBase = () => {
    return router.asPath === '/'
  }

  const pathname = () => {
    return router.pathname
  }

  const currentPath = () => {
    return router.asPath === '/' ? '' : router.asPath
  }

  const nakedPath = () => {
    if (isLocalized || hasOnlyMarket) {
      const segments = isLocalized ? 2 : 1
      const trimmedPath = filteredPath.splice(segments).join('/')
      return trimmedPath ? `/${trimmedPath}` : ''
    }
    return router.asPath === '/' ? '' : `/${filteredPath.join('/')}`
  }

  const localizedBaseUrl = () => {
    const webappUrl = config.WEBAPP_URL
    return isLocalized ? `${webappUrl}/${market}/${language}` : webappUrl
  }

  return {
    market,
    language,
    isLocalized,
    hasOnlyMarket,
    nextRouterQueryParams: nextRouterQueryParams(),
    queryParams: queryParams(),
    asPath: asPath(),
    isBase: isBase(),
    pathname: pathname(),
    currentPath: currentPath(),
    nakedPath: nakedPath(),
    localizedBaseUrl: localizedBaseUrl(),
    urlSearchParams: urlSearchParams(),
  }
}
