import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconCard = createIcon({
  path: (
    <path
      d="M4.1999 0.799988C2.37736 0.799988 0.899902 2.27745 0.899902 4.09999V10.7C0.899902 12.5225 2.37736 14 4.1999 14H9.2999V12.8H4.1999C3.0401 12.8 2.0999 11.8598 2.0999 10.7V5.59999H18.8999V7.39977C19.4892 7.84238 19.9101 8.49693 20.0496 9.25025C20.0664 9.25336 20.0832 9.25661 20.0999 9.26V4.09999C20.0999 2.27745 18.6224 0.799988 16.7999 0.799988H4.1999ZM18.8999 4.39999H2.0999V4.09999C2.0999 2.94019 3.0401 1.99999 4.1999 1.99999H16.7999C17.9597 1.99999 18.8999 2.94019 18.8999 4.09999V4.39999ZM12.8999 10.4V9.79999C12.8999 8.80588 13.7058 7.99999 14.6999 7.99999H17.0999C18.094 7.99999 18.8999 8.80588 18.8999 9.79999V10.4H19.4999C20.494 10.4 21.2999 11.2059 21.2999 12.2V14H18.8999V13.4C18.8999 13.0686 18.6313 12.8 18.2999 12.8C17.9685 12.8 17.6999 13.0686 17.6999 13.4V14H14.0999V13.4C14.0999 13.0686 13.8313 12.8 13.4999 12.8C13.1685 12.8 12.8999 13.0686 12.8999 13.4V14H10.4999V12.2C10.4999 11.2059 11.3058 10.4 12.2999 10.4H12.8999ZM17.0999 9.19999H14.6999C14.3685 9.19999 14.0999 9.46862 14.0999 9.79999V10.4H17.6999V9.79999C17.6999 9.46862 17.4313 9.19999 17.0999 9.19999ZM18.8999 15.8V15.2H21.2999V17C21.2999 17.9941 20.494 18.8 19.4999 18.8H12.2999C11.3058 18.8 10.4999 17.9941 10.4999 17V15.2H12.8999V15.8C12.8999 16.1314 13.1685 16.4 13.4999 16.4C13.8313 16.4 14.0999 16.1314 14.0999 15.8V15.2H17.6999V15.8C17.6999 16.1314 17.9685 16.4 18.2999 16.4C18.6313 16.4 18.8999 16.1314 18.8999 15.8Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 22 19',
})

export default IconCard
