import config from 'lib/nextConfig'
import { isServer } from 'lib/utils'

const cachePrefix = config.basePath ? config.basePath : ''

const withPrefix = (key: string) =>
  cachePrefix ? `${cachePrefix}-${key}` : key

export const getItem = (key: string): string =>
  !isServer() && localStorage.getItem(withPrefix(key))

export const setItem = (key: string, value: string): void =>
  !isServer() && localStorage.setItem(withPrefix(key), value)

export const removeItem = (key: string): void =>
  !isServer() && localStorage.removeItem(withPrefix(key))
