import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconUser = createIcon({
  path: (
    <g>
      <path
        d="M10 10C12.7344 10 15 7.77344 15 5C15 2.26562 12.7344 0 10 0C7.22656 0 5 2.26562 5 5C5 7.77344 7.22656 10 10 10ZM10 1.25C12.0312 1.25 13.75 2.96875 13.75 5C13.75 7.07031 12.0312 8.75 10 8.75C7.92969 8.75 6.25 7.07031 6.25 5C6.25 2.96875 7.92969 1.25 10 1.25ZM11.9531 11.875H8.00781C4.25781 11.875 1.25 14.9219 1.25 18.6719C1.25 19.4141 1.83594 20 2.57812 20H17.3828C18.125 20 18.75 19.4141 18.75 18.6719C18.75 14.9219 15.7031 11.875 11.9531 11.875ZM17.3828 18.75H2.57812C2.53906 18.75 2.5 18.7109 2.5 18.6719C2.5 15.625 4.96094 13.125 8.00781 13.125H11.9531C15 13.125 17.5 15.625 17.5 18.6719C17.5 18.7109 17.4219 18.75 17.3828 18.75Z"
        fill="black"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconUser
