import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconSmile = createIcon({
  path: (
    <path
      d="M5.24927 10C5.03852 10 4.83749 10.0887 4.69539 10.2443C4.55329 10.4 4.48323 10.6082 4.50237 10.8181C4.78259 13.8916 7.05224 16.5 10.5 16.5C13.9478 16.5 16.2174 13.8916 16.4977 10.8181C16.5168 10.6082 16.4468 10.4 16.3047 10.2443C16.1626 10.0887 15.9616 10 15.7508 10H5.24927ZM10.5 15C8.24286 15 6.62852 13.5205 6.13237 11.5H14.8677C14.3715 13.5205 12.7571 15 10.5 15ZM13.7501 6.75C13.3416 6.75 13.0398 7.03719 12.992 7.35982C12.9314 7.76957 12.55 8.05257 12.1403 7.99192C11.7305 7.93126 11.4475 7.54993 11.5082 7.14018C11.6696 6.0495 12.6313 5.25 13.7501 5.25C14.8689 5.25 15.8306 6.0495 15.992 7.14018C16.0527 7.54993 15.7697 7.93126 15.3599 7.99192C14.9502 8.05257 14.5688 7.76957 14.5082 7.35982C14.4604 7.03719 14.1586 6.75 13.7501 6.75ZM6.49202 7.35982C6.53977 7.03719 6.84157 6.75 7.2501 6.75C7.65863 6.75 7.96043 7.03719 8.00818 7.35982C8.06884 7.76957 8.45017 8.05257 8.85992 7.99192C9.26967 7.93126 9.55267 7.54993 9.49202 7.14018C9.33057 6.0495 8.36892 5.25 7.2501 5.25C6.13128 5.25 5.16963 6.0495 5.00818 7.14018C4.94753 7.54993 5.23053 7.93126 5.64028 7.99192C6.05003 8.05257 6.43136 7.76957 6.49202 7.35982ZM10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0ZM2 10C2 5.30558 5.80558 1.5 10.5 1.5C15.1944 1.5 19 5.30558 19 10C19 14.6944 15.1944 18.5 10.5 18.5C5.80558 18.5 2 14.6944 2 10Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 20',
})

export default IconSmile
