import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconVenusMars = createIcon({
  path: (
    <g>
      <path
        d="M9.6875 15.3125H7.8125V13.7109C11.1328 13.2422 13.75 10.3516 13.75 6.875C13.75 3.08594 10.6641 0 6.875 0C3.04688 0 0 3.08594 0 6.875C0 10.3906 2.57812 13.2422 5.9375 13.7109V15.3125H4.0625C3.51562 15.3125 3.125 15.7422 3.125 16.25C3.125 16.7969 3.51562 17.1875 4.0625 17.1875H5.9375V19.0625C5.9375 19.6094 6.32812 20 6.875 20C7.38281 20 7.8125 19.6094 7.8125 19.0625V17.1875H9.6875C10.1953 17.1875 10.625 16.7969 10.625 16.25C10.625 15.7422 10.1953 15.3125 9.6875 15.3125ZM6.875 11.875C4.10156 11.875 1.875 9.64844 1.875 6.875C1.875 4.14062 4.10156 1.875 6.875 1.875C9.60938 1.875 11.875 4.14062 11.875 6.875C11.875 9.64844 9.60938 11.875 6.875 11.875ZM24.0625 0H19.6875C19.1406 0 18.75 0.429688 18.75 0.9375C18.75 1.48438 19.1406 1.875 19.6875 1.875H21.7969L19.7266 3.94531C18.5156 3.00781 17.0703 2.53906 15.5859 2.53906C15 2.53906 14.4141 2.61719 13.8672 2.77344C14.1797 3.32031 14.4141 3.86719 14.6094 4.49219C14.9219 4.41406 15.2734 4.375 15.625 4.375C16.9531 4.375 18.2031 4.92188 19.1406 5.85938C21.0938 7.8125 21.0938 10.9766 19.1406 12.9297C18.2031 13.8672 16.9531 14.375 15.625 14.375C14.3359 14.375 13.125 13.9062 12.1875 13.0078C11.7188 13.4375 11.1719 13.7891 10.625 14.1016C10.6641 14.1406 10.7031 14.2188 10.7422 14.2578C12.0703 15.5859 13.8281 16.25 15.625 16.25C17.3828 16.25 19.1406 15.5859 20.4688 14.2578C22.9297 11.7969 23.125 7.96875 21.0547 5.27344L23.125 3.20312V5.3125C23.125 5.85938 23.5156 6.25 24.0625 6.25C24.5703 6.25 25 5.85938 25 5.3125V0.9375C25 0.429688 24.5703 0 24.0625 0Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 25 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconVenusMars
