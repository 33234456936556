import { gql } from '@apollo/client'

const fullPublicBadgeFragment = gql`
  fragment fullPublicBadge on PublicProfileBadge {
    id
    tagTitle
    badgeType
    badgePosition
    color
    backgroundColor
    image {
      url
    }
  }
`

export default fullPublicBadgeFragment
