import React from 'react'
import Image from 'next/image'

import blackSpinner from './spinner-black.svg'
import redSpinner from './spinner-red.svg'
import whiteSpinner from './spinner-white.svg'

import styles from './Spinner.module.css'

export type SpinnerColors = 'white' | 'black' | 'red'

export type SpinnerProps = {
  color: SpinnerColors
  size?: number
}

const Spinner: React.FC<SpinnerProps> = ({ color, size = 24 }) => {
  const icons = {
    white: whiteSpinner,
    black: blackSpinner,
    red: redSpinner,
  }

  return (
    <Image
      src={icons[color]}
      alt="Spinner"
      className={styles.spinner}
      width={size}
      height={size}
    />
  )
}

export { Spinner }
