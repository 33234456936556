import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconHeart = createIcon({
  path: (
    <g>
      <path
        d="M9.53125 3.28125L9.96094 3.75L10.4297 3.32031C11.7188 2.03125 13.5547 1.44531 15.3125 1.75781C18.0078 2.1875 20 4.53125 20 7.26562V7.46094C20 9.10156 19.2969 10.6641 18.125 11.7578L11.0547 18.3594C10.7812 18.6328 10.3906 18.75 10 18.75C9.57031 18.75 9.17969 18.6328 8.90625 18.3594L1.83594 11.7578C0.664062 10.6641 0 9.10156 0 7.46094V7.26562C0 4.53125 1.95312 2.1875 4.64844 1.75781C6.40625 1.44531 8.24219 2.03125 9.53125 3.28125C9.53125 3.32031 9.49219 3.28125 9.53125 3.28125ZM9.96094 6.40625L8.20312 4.60938C7.34375 3.78906 6.13281 3.39844 4.96094 3.59375C3.16406 3.90625 1.875 5.42969 1.875 7.26562V7.46094C1.875 8.59375 2.30469 9.64844 3.125 10.3906L10 16.7969L16.8359 10.3906C17.6562 9.64844 18.125 8.59375 18.125 7.46094V7.26562C18.125 5.42969 16.7969 3.90625 15 3.59375C13.8281 3.39844 12.6172 3.78906 11.7578 4.60938L9.96094 6.40625Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconHeart
