import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconMobileScreenButton = createIcon({
  path: (
    <g>
      <path
        d="M11.875 0H3.125C1.71875 0 0.625 1.13281 0.625 2.5V17.5C0.625 18.9062 1.71875 20 3.125 20H11.875C13.2422 20 14.375 18.9062 14.375 17.5V2.5C14.375 1.13281 13.2422 0 11.875 0ZM12.5 17.5C12.5 17.8516 12.1875 18.125 11.875 18.125H3.125C2.77344 18.125 2.5 17.8516 2.5 17.5V14.375H12.5V17.5ZM12.5 12.5H2.5V2.5C2.5 2.1875 2.77344 1.875 3.125 1.875H11.875C12.1875 1.875 12.5 2.1875 12.5 2.5V12.5ZM7.5 17.1875C8.00781 17.1875 8.4375 16.7969 8.4375 16.25C8.4375 15.7422 8.00781 15.3125 7.5 15.3125C6.95312 15.3125 6.5625 15.7422 6.5625 16.25C6.5625 16.7969 6.95312 17.1875 7.5 17.1875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 15 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconMobileScreenButton
