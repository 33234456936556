import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import type { GetSideMenuQuery, GetSideMenuQueryVariables } from 'apollo-gql'
import fullCmsItemFragment from 'graphql/fragments/fullCmsItemFragment'
import { currentMarketIdVar } from 'graphql/reactive'

export const GET_SIDEMENU = gql`
  query GetSideMenu($marketId: ID!, $profilesPerCollection: Int! = 7) {
    market(id: $marketId) {
      id
      sideMenu {
        cmsItems {
          ...fullCmsItemFragment
        }
      }
    }
  }

  ${fullCmsItemFragment}
`

type UseSideMenu = (
  useQueryProps?: QueryHookOptions<GetSideMenuQuery, GetSideMenuQueryVariables>,
) => { sideMenu?: GetSideMenuQuery['market']['sideMenu'] } & Pick<
  QueryResult<GetSideMenuQuery, GetSideMenuQueryVariables>,
  'loading' | 'error'
>

const useSideMenu: UseSideMenu = (useQueryProps = {}) => {
  const marketId = currentMarketIdVar()
  const { loading, data, error } = useQuery<
    GetSideMenuQuery,
    GetSideMenuQueryVariables
  >(GET_SIDEMENU, {
    variables: {
      marketId,
    },
    ...useQueryProps,
  })

  return { sideMenu: data?.market?.sideMenu, loading, error }
}

export default useSideMenu
