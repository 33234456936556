import { useMemo } from 'react'
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'cross-fetch'
import { typeDefs, typePolicies } from 'graphql/cache'
import { currentLanguageIdVar, currentMarketIdVar } from 'graphql/reactive'
import { getItem } from 'lib/localStorage'
import config from 'lib/nextConfig'
import pathHelpers from 'lib/pathHelpers'
import selectMarket from 'lib/selectMarket'
import { isServer } from 'lib/utils'

import { DETECTED_MARKET_QUERY } from 'hooks/useDetectedMarket'

import possibleTypes from '../../possibleTypes.json'
import { logger } from './logger'

let apolloClient
const authLink = setContext((_, { headers: headersPayload = {} }) => {
  const headers = headersPayload
  if (isServer()) {
    return {
      headers,
    }
  }

  const token = getItem('token')

  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  return {
    headers,
  }
})

const contextLink = (ctx) =>
  setContext((_, { headers, noCache }) => {
    const mergedHeaders = {
      ...headers,
      'Accept-Language': currentLanguageIdVar(),
    }

    if (noCache) mergedHeaders['Cache-Control'] = 'no-cache'

    if (isServer() && ctx.asPath) {
      mergedHeaders['X-Client-Request-Path'] = ctx.asPath
    }

    return {
      headers: mergedHeaders,
    }
  })

const httpLink = new HttpLink({
  uri: `${config.API_URL}/graphql`, // Server URL (must be absolute)
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  fetch,
  headers: {
    'X-Client-Name': 'memmo-webapp',
    'X-Client-Version': process.env.VERSION,
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => logger.info(message))
  if (networkError && !isServer)
    logger.error('[Apollo NetworkError]', networkError)
})

const retryIf = (networkError, _operation) => {
  if (!!networkError && ![500, 400].includes(networkError.statusCode)) {
    logger.info('[Apollo NetworkError] Retrying...')
    return true
  }
  return false
}

const retryLink = new RetryLink({
  delay: { initial: 500, jitter: true },
  attempts: {
    max: 5,
    retryIf,
  },
})

const link = (ctx) =>
  ApolloLink.from([errorLink, authLink, contextLink(ctx), retryLink, httpLink])

export function createApolloClient(ctx) {
  const client = new ApolloClient({
    ssrMode: isServer(),
    link: link(ctx),
    typeDefs,
    resolvers: {}, // Resolvers needs to be passed
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies,
    }),
  })

  return client
}

export function initializeApollo(ctx = null, initialState = null) {
  const apollo = apolloClient ?? createApolloClient(ctx)

  if (ctx?.req) {
    const currentPath = pathHelpers(ctx?.req?.path)
    const { market, language } = currentPath

    const detectedMarket = {
      market: market || 'global',
      language: language || 'en',
      detectedBy: 'URL',
    }

    apollo.cache.writeQuery({
      query: DETECTED_MARKET_QUERY,
      data: {
        detectedMarket: {
          __typename: 'DetectedMarket',
          ...detectedMarket,
        },
      },
    })

    const { selectedLanguage } = selectMarket({
      marketId: detectedMarket.market,
      languageCode: detectedMarket.language,
    })

    currentMarketIdVar(detectedMarket.market)
    currentLanguageIdVar(selectedLanguage?.id)

    const { i18n } = ctx.req
    if (ctx?.req?.i18n) {
      i18n.changeLanguage(selectedLanguage?.id)
    }
  }

  // Rehydrate state from data fetching
  if (initialState) {
    const existingCache = apollo.extract()
    apollo.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (isServer()) return apollo

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = apollo

  return apollo
}

export function useApollo(initialState) {
  const store = useMemo(
    () => initializeApollo(null, initialState),
    [initialState],
  )
  return store
}
