import React from 'react'
import { AmplitudeExperimentProvider } from 'contexts/ExperimentContext/ExperimentProvider'
import config from 'lib/nextConfig'

const AmplitudeExperiment: React.FC = ({ children }) => {
  const debug = config.RELEASE_STAGE === 'dev'

  return (
    <AmplitudeExperimentProvider
      deploymentApiKey={config.AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY}
      debugMode={debug}
    >
      {children}
    </AmplitudeExperimentProvider>
  )
}

export default AmplitudeExperiment
