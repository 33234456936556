import { gql } from '@apollo/client'

import fullPublicBadge from './fullPublicBadgeFragment'
import fullPublicDiscountFragment from './fullPublicDiscountFragment'

const cardProfileProductFragment = gql`
  fragment cardProfileProductFragment on PublicProfileProduct {
    id
    acceptingOrders
    price {
      formattedValue
      valueInCent
      currency
    }
    priceWithParent {
      formattedValue
      valueInCent
      currency
    }
    product {
      id
      slug
      productType
      saleMode
    }
    profile {
      id
      name
      slug
      title
      description
      acceptingOrders
      typicallyRespondsInDays
      profilePicture {
        id
        cardUrl: url(format: { width: 265, height: 458, fit: crop })
        meanBackgroundColorHex
      }
      profileVideo {
        id
        url(preset: VIDEO_DEFAULT)
      }
      defaultProfileProduct {
        id
      }
      averageReviewRating
      hasB2cProduct
      hasB2bProduct
      defaultMarketId
    }
    badges {
      ...fullPublicBadge
    }
    discount {
      ...fullPublicDiscount
    }
  }
  ${fullPublicDiscountFragment}
  ${fullPublicBadge}
`

export default cardProfileProductFragment
