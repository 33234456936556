import React, { useContext } from 'react'

const defaultMarket = {
  detectedMarket: null,
  isMismatch: false,
  detectedBy: null,
  suggestedBy: null,
  showSuggester: null,
  setShowSuggester: () => {},
  handleMarketChange: () => {},
  suggestedMarket: null,
  userChangesMarket: false,
  market: {
    selectedMarket: {
      id: null,
      name: '',
      localName: '',
      defaultCurrency: null,
      config: {
        instagram: '',
        facebook: '',
        businessPage: {},
        blogPage: {},
      },
      dateFormat: 'YYY-MM-DD',
      dateTimeFormat: 'YYYY-MM-DD HH:mm',
      timeFormat: 'HH:mm',
      defaultLanguage: {
        code: '',
        dayjs: '',
        id: '',
        iso: '',
        name: '',
        native: '',
      },
      countries: [
        {
          capital: '',
          code: '',
          continent: '',
          emoji: '',
          id: '',
          name: '',
          native: '',
          phone: '',
        },
      ],
    },
    selectedLanguage: {
      id: null, // maybe not needed at all
      code: null,
    },
  },
  markets: [],
}

export const MarketContext = React.createContext(defaultMarket)
MarketContext.displayName = 'MarketContext'

export const useMarketContext = () => useContext(MarketContext)
