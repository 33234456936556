import { getItem, removeItem, setItem } from 'lib/localStorage'
import { isServer } from 'lib/utils'

type CartItem = {
  path: string
  talentName?: string
}

export default function useCart(): {
  saveToCart: (item: CartItem) => void
  clearCart: () => void
  getUnfinishedOrder: () => CartItem
} {
  const getUnfinishedOrder = (): CartItem => {
    if (!isServer()) {
      let unfinishedOrder: CartItem = { talentName: '', path: '' }
      try {
        unfinishedOrder = JSON.parse(getItem('cart'))
      } catch (error) {
        removeItem('cart')
      }

      return {
        path: unfinishedOrder?.path ?? '',
        talentName: unfinishedOrder?.talentName ?? '',
      }
    }
    return { path: '', talentName: '' }
  }

  const saveToCart = (item: CartItem) => {
    if (!isServer()) {
      const name = item?.talentName || getUnfinishedOrder()?.talentName

      setItem('cart', JSON.stringify({ ...item, talentName: name }))
    }
  }
  const clearCart = () => {
    if (!isServer()) {
      removeItem('cart')
    }
  }

  return { saveToCart, clearCart, getUnfinishedOrder }
}
