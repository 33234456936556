module.exports = [
  'en-LOCAL',
  'en-US',
  'en-CA',
  'en-AU',
  'en',
  'sv',
  'da',
  'fi',
  'fr',
  'no',
  'it',
  'es',
  'de',
  'pt-BR',
  'hi',
  'es-MX',
]
