const NextI18Next = require('next-i18next').default
const path = require('path')
const { isServer, withBasePath } = require('./utils')
const otherLanguages = require('../config/languages')

const localePath = isServer()
  ? path.resolve('./public/locales')
  : withBasePath('/locales')

const i18n = new NextI18Next({
  debug: false,
  serverLanguageDetection: false,
  browserLanguageDetection: false,
  defaultLanguage: 'en-LOCAL',
  otherLanguages, // List of supported languages
  shallowRender: true, // Don't run data feching methods on language change
  localePath,
  react: {
    wait: true,
    useSuspense: false,
  },
  backend: {
    loadPath: (lng, ns) => `${localePath}/${lng}/${ns}.json`,
  },
})

module.exports = i18n
