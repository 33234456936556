import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import type { ProductType } from 'apollo-gql'
import { pushToDataLayer } from 'lib/tracking'
import { isServer } from 'lib/utils'
import Router from 'next/router'

import useMarket from 'hooks/useMarket'
import useRouter from 'hooks/useRouter'

export type ImpressionItem = {
  name: string
  id: string
  price: number
  category: ProductType
  list: string
  position: number
  productId: string
  profileId: string
  profileSlug: string
  productSlug: string
}

type ImpressionContext = {
  pushImpression: (impressionItem: ImpressionItem) => void
}

const impressionBatchSize = 7
const sendBatchAfterTimeoutInMs = 60000

const ImpressionContext = createContext<ImpressionContext>(undefined)
const useImpressionContext: () => ImpressionContext = () =>
  useContext(ImpressionContext)

const ImpressionContextProvider: React.FC = ({ children }) => {
  const [trackedImpressions, setTrackedImpressions] = useState([])
  const market = useMarket()
  const router = useRouter()

  const impressionBatch = useRef<ImpressionItem[]>([])

  const sendImpressionBatch = useCallback(() => {
    if (impressionBatch.current.length > 0) {
      pushToDataLayer({
        event: 'productImpression',
        ecommerce: {
          currencyCode: market.defaultCurrency,
          impressions: impressionBatch.current,
        },
      })
      impressionBatch.current = []
    }
  }, [impressionBatch, market])

  useEffect(() => {
    setTrackedImpressions([])
  }, [router.pathname, router.query])

  useEffect(() => {
    Router.events.on('routeChangeStart', sendImpressionBatch)

    return () => {
      Router.events.off('routeChangeStart', sendImpressionBatch)
    }
  }, [sendImpressionBatch])

  useEffect(() => {
    window.addEventListener('beforeunload', sendImpressionBatch)
    return () => {
      window.removeEventListener('beforeunload', sendImpressionBatch)
    }
  }, [sendImpressionBatch])

  const isNewImpression = (item: ImpressionItem) => {
    return (
      trackedImpressions.filter(
        (trackedImpression) => trackedImpression.id === item.id,
      ).length === 0
    )
  }

  let impressionTimout: number | undefined

  const pushImpression = (impression: ImpressionItem) => {
    if (isNewImpression(impression)) {
      impressionBatch.current.push(impression)
      setTrackedImpressions([...trackedImpressions, impression])
      if (impressionBatch.current.length >= impressionBatchSize) {
        sendImpressionBatch()
      }
      if (!isServer()) {
        if (impressionTimout) {
          window.clearTimeout(impressionTimout)
        }
        impressionTimout = window.setTimeout(
          sendImpressionBatch,
          sendBatchAfterTimeoutInMs,
        )
      }
    }
  }

  return (
    <ImpressionContext.Provider value={{ pushImpression }}>
      {children}
    </ImpressionContext.Provider>
  )
}

export { ImpressionContextProvider, useImpressionContext }
