import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconLightbulb = createIcon({
  path: (
    <g>
      <path
        d="M4.375 17.7734C4.375 18.0078 4.41406 18.2422 4.57031 18.4375L5.23438 19.4531C5.42969 19.7656 5.89844 20 6.28906 20H8.67188C9.0625 20 9.53125 19.7656 9.72656 19.4531L10.3906 18.4375C10.5078 18.2812 10.5859 17.9688 10.5859 17.7734L10.625 16.25H4.375V17.7734ZM7.5 0C3.51562 0.0390625 0.625 3.24219 0.625 6.875C0.625 8.59375 1.25 10.1562 2.30469 11.3672C2.96875 12.1094 3.94531 13.6719 4.33594 14.9609C4.33594 14.9609 4.33594 14.9609 4.33594 15H10.625C10.625 14.9609 10.625 14.9609 10.625 14.9609C11.0156 13.6719 11.9922 12.1094 12.6562 11.3672C13.7109 10.1953 14.375 8.63281 14.375 6.875C14.375 3.08594 11.2891 0.0390625 7.5 0ZM11.25 10.1953C10.625 10.8594 9.88281 11.9922 9.33594 13.125H5.625C5.07812 11.9922 4.33594 10.8594 3.71094 10.1953C2.92969 9.25781 2.5 8.08594 2.5 6.875C2.5 4.45312 4.375 1.91406 7.46094 1.875C10.2344 1.875 12.5 4.14062 12.5 6.875C12.5 8.08594 12.0312 9.25781 11.25 10.1953ZM6.875 3.125C5.11719 3.125 3.75 4.53125 3.75 6.25C3.75 6.60156 4.02344 6.875 4.375 6.875C4.6875 6.875 5 6.60156 5 6.25C5 5.23438 5.82031 4.375 6.875 4.375C7.1875 4.375 7.5 4.10156 7.5 3.78906C7.5 3.4375 7.1875 3.125 6.875 3.125Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 15 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconLightbulb
