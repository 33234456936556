function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

// Works with our kindOfRelayStylePagination and cache-and-network fetchpolicy
// Nodes need to have id field
// eslint-disable-next-line import/prefer-default-export
export function paginate(keyArgs = false) {
  return {
    keyArgs,
    merge(existing = makeEmptyData(), incoming, { readField, mergeObjects }) {
      const merged = existing.edges.slice(0)
      const idToIndex = Object.create(null)

      if (existing) {
        existing.edges.forEach((edge, index) => {
          idToIndex[readField('id', edge.node)] = index
        })
      }

      incoming.edges.forEach((edge) => {
        const id = readField('id', edge.node)
        const index = idToIndex[id]
        if (typeof index === 'number') {
          merged[index] = mergeObjects(merged[index], edge)
        } else {
          idToIndex[id] = merged.length
          merged.push(edge)
        }
      })

      const pageInfo = {
        ...existing.pageInfo,
        ...incoming.pageInfo,
      }

      return {
        ...existing,
        ...incoming,
        edges: merged,
        pageInfo,
      }
    },
  }
}
