import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconPayPalSquare = createIcon({
  path: (
    <g>
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6145 18.4483L14.8352 16.9991L14.3435 16.9873H11.9956L13.6273 6.29367C13.6324 6.26129 13.6489 6.23123 13.6729 6.20984C13.697 6.18846 13.7277 6.17673 13.7599 6.17673H17.7188C19.0333 6.17673 19.9403 6.45936 20.4138 7.0173C20.6358 7.27905 20.7772 7.55264 20.8456 7.85361C20.9174 8.16948 20.9186 8.54682 20.8486 9.00712L20.8435 9.0406V9.33558L21.0655 9.4656C21.2525 9.56812 21.4011 9.68543 21.515 9.81972C21.7049 10.0436 21.8277 10.328 21.8796 10.6652C21.9333 11.012 21.9156 11.4247 21.8277 11.892C21.7264 12.4294 21.5627 12.8976 21.3415 13.2805C21.1381 13.6334 20.879 13.9262 20.5712 14.153C20.2775 14.3685 19.9285 14.5322 19.5338 14.6369C19.1514 14.7398 18.7154 14.7917 18.2371 14.7917H17.9291C17.7088 14.7917 17.4948 14.8737 17.3268 15.0207C17.1583 15.1707 17.047 15.3758 17.0128 15.6L16.9895 15.7305L16.5995 18.2848L16.5819 18.3785C16.5772 18.4082 16.5691 18.423 16.5573 18.433C16.5468 18.4421 16.5316 18.4483 16.5169 18.4483H14.6145Z"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2756 9.07468C21.2639 9.15276 21.2503 9.23255 21.2352 9.31454C20.7131 12.0852 18.9268 13.0423 16.6456 13.0423H15.484C15.205 13.0423 14.9698 13.2517 14.9265 13.5361L14.1633 18.5394C14.1351 18.7262 14.2743 18.8945 14.4566 18.8945H16.5168C16.7607 18.8945 16.968 18.7113 17.0064 18.4626L17.0266 18.3545L17.4145 15.8103L17.4394 15.6707C17.4774 15.4212 17.6851 15.2379 17.929 15.2379H18.2371C20.2331 15.2379 21.7957 14.4004 22.2524 11.9766C22.4431 10.9641 22.3443 10.1186 21.8395 9.52399C21.6868 9.34473 21.4973 9.19589 21.2756 9.07468Z"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7293 8.84962C20.6495 8.82555 20.5672 8.8038 20.4828 8.78413C20.3979 8.76494 20.311 8.74796 20.2215 8.73305C19.9084 8.68075 19.5652 8.65594 19.1976 8.65594H16.0946C16.0181 8.65594 15.9455 8.67379 15.8806 8.70604C15.7374 8.77716 15.6311 8.9172 15.6054 9.08864L14.9452 13.4102L14.9263 13.5362C14.9697 13.2517 15.2048 13.0424 15.4838 13.0424H16.6454C18.9266 13.0424 20.7129 12.0847 21.235 9.31457C21.2506 9.23258 21.2637 9.15279 21.2754 9.07471C21.1433 9.00224 21.0003 8.94029 20.8462 8.8875C20.8082 8.87443 20.7689 8.86184 20.7293 8.84962Z"
        fill="#22284F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6056 9.08865C15.6314 8.91721 15.7377 8.77718 15.8809 8.70655C15.9462 8.67417 16.0184 8.65633 16.0948 8.65633H19.1979C19.5654 8.65633 19.9086 8.68126 20.2218 8.73356C20.3113 8.74834 20.3982 8.76545 20.4831 8.78463C20.5675 8.80418 20.6498 8.82606 20.7296 8.85001C20.7692 8.86223 20.8085 8.87493 20.8469 8.88752C21.0009 8.94031 21.1441 9.00275 21.2761 9.07472C21.4315 8.05085 21.2748 7.35373 20.7393 6.72248C20.1488 6.02743 19.0831 5.73001 17.7194 5.73001H13.7603C13.4817 5.73001 13.2441 5.93933 13.2011 6.22429L11.5521 17.0279C11.5196 17.2416 11.679 17.4344 11.8876 17.4344H14.3318L15.6056 9.08865Z"
        fill="#28356A"
      />
    </g>
  ),
  viewBox: '0 0 34 24',
})

export default IconPayPalSquare
