import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconEditPen = createIcon({
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11 .669922c-.5983 0-1.19645.226791-1.64978.680338l-7.37826 7.37761c-.13999.13999-.24252.31408-.29687.5052l-.988935 3.46293c-.024389.0857-.025442.1763-.003051.2625.022391.0863.067414.1649.130411.2279.062996.063.14168.1081.227915.1305.08623.0223.17688.0213.26256-.0031l3.46355-.9889c.00021-.0002.00043-.0005.00065-.0007.18984-.0546.3635-.1558.5039-.2962l7.37761-7.37826c.907-.90704.907-2.39244 0-3.29948-.4533-.453547-1.0515-.680338-1.6497-.680338zm0 .994138c.3402 0 .68.13111.942.39323h.0007c.5249.52496.5249 1.36046 0 1.88542l-.6465.64648-1.88544-1.88541.64644-.64649c.262-.26212.6025-.39323.9428-.39323zm-2.29627 1.74675 1.88537 1.88541-6.02469 6.02478c-.01954.0194-.0443.0342-.07227.0423l-2.59765.7422.74219-2.59834c0-.00022 0-.00043 0-.00065.00764-.02688.02164-.05094.04231-.07161z"
      />
    </g>
  ),
  viewBox: '0 0 14 14',
  defaultProps: {
    height: 14,
    'aria-label': 'Edit',
    fill: '#95979f',
  },
})

export default IconEditPen
