import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconHandshakeAngle = createIcon({
  path: (
    <g>
      <path
        d="M25.6484 9.82812L20.8438 12.6406C20.6094 13.3047 20.0625 13.8516 19.3984 14.0469V14.6328C19.3984 15.9609 18.4609 17.0938 17.25 17.4453C16.8984 18.7734 15.6484 19.75 14.2031 19.75H9.75L7.71875 20.9219C7.40625 21.1172 7.05469 21 6.85938 20.7266L6.54688 20.1797C6.39062 19.8672 6.46875 19.4766 6.78125 19.3203L9.24219 17.875H14.2422C14.9453 17.875 15.5312 17.3281 15.5312 16.625V15.6875H16.4688C17.0547 15.6875 17.5625 15.2188 17.5625 14.5938V12.25H18.7734C18.9297 12.25 19.0469 12.1328 19.0469 11.9375V10.6875C19.0469 10.5312 18.8906 10.375 18.7734 10.375H14.75V11C14.75 12.7969 13.1484 14.2812 11.2734 14.0859C9.67188 13.9297 8.5 12.4453 8.5 10.8047V9.20312L7.21094 9.94531C6.82031 10.1797 6.625 10.5703 6.625 11.0391V13.4609L2.25 15.9609C1.97656 16.1562 1.58594 16.0391 1.42969 15.7266L1.11719 15.1797C0.960938 14.9062 1.07812 14.5156 1.35156 14.3594L4.78906 12.3672V11.0391C4.78906 9.90625 5.375 8.89062 6.3125 8.34375L8.65625 6.9375C8.8125 6.15625 9.28125 5.45312 10.0234 4.98438L11.2734 4.20312C11.9375 3.73438 12.7578 3.53906 13.5781 3.53906H17.1719L21.4297 1.15625C21.7422 0.960938 22.1328 1.07812 22.2891 1.39062L22.6016 1.89844C22.7578 2.21094 22.6797 2.60156 22.4062 2.75781L17.7188 5.41406H13.6172C13.1875 5.41406 12.6797 5.57031 12.2891 5.80469L10.9609 6.58594C10.5703 6.82031 10.375 7.21094 10.375 7.64062V11C10.375 11.7031 10.9219 12.25 11.5859 12.25C12.25 12.25 12.7969 11.7031 12.7969 11V8.5H18.6953C19.7891 8.5 20.6875 9.35938 20.8438 10.4141L24.6328 8.22656C24.9453 8.03125 25.2969 8.14844 25.4922 8.46094L25.7656 8.96875C26.0781 9.28125 25.9609 9.67188 25.6484 9.82812Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 27 22',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconHandshakeAngle
