import { isServer } from 'lib/utils'

import { logger } from './logger'

const CONTENT_SQUARE_PAGE_VIEW_DELAY_MS = 2500

// eslint-disable-next-line import/prefer-default-export
export function pushToDataLayer(obj) {
  if (isServer()) return

  // @todo validate the format of obj?
  logger.info('dataLayer - push: ', obj) // Let's leave this here during testing of v2 - nice to see what we track easily
  window.dataLayer?.push(obj)

  window.analytics?.track('GTM', obj)
}

export function trackPageview({ path }) {
  if (isServer()) return

  pushToDataLayer({
    event: 'pageview',
    page: {
      path,
    },
  })

  setTimeout(() => {
    pushToDataLayer({
      event: 'pageviewContentSquare',
      page: {
        path,
      },
    })
  }, CONTENT_SQUARE_PAGE_VIEW_DELAY_MS)
}

export function gtag() {
  if (!isServer()) {
    if (Array.isArray(window.dataLayer)) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
  }
}
