import { currentLanguageIdVar, currentMarketIdVar } from 'graphql/reactive'

import { MarketProperties } from '../interfaces/common'

const getMarketTrackingProperties = (): MarketProperties => {
  const marketId = currentMarketIdVar()
  const localeId = currentLanguageIdVar()
  return {
    marketId,
    localeId,
  }
}

export default getMarketTrackingProperties
