import { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import allFeatureFlagsFragment from 'graphql/fragments/allFeatureFlagsFragment'

import useRouter from 'hooks/useRouter'

import FeatureFlagsContext, { FeatureFlagValue } from '.'

const FEATURE_FLAGS_QUERY = gql`
  query AllFeatureFlags {
    ...allFeatureFlagsFragment
  }
  ${allFeatureFlagsFragment}
`

export type FeatureFlag = {
  key: string
  enabled: boolean
}

const FeatureFlagsProvider: FC = ({ children }) => {
  const { query } = useRouter()

  const { data } = useQuery(FEATURE_FLAGS_QUERY)
  const allFeatureFlags = data?.allFeatureFlags || []

  const fromQueryParams = (name: string): boolean | undefined =>
    ({ on: true, off: false }[query[name] as string])

  const featureFlags: FeatureFlagValue = allFeatureFlags.reduce(
    (prev: FeatureFlagValue | Record<string, unknown>, curr: FeatureFlag) => ({
      ...prev,
      [curr.key]: fromQueryParams(curr.key) ?? curr.enabled,
    }),
    {},
  )

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export { FeatureFlagsContext, FeatureFlagsProvider }
