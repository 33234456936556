import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconShoppingBag = createIcon({
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2924 3.35267C12.2301 2.44525 13.7177 2.25804 14.4951 2.25804C15.2724 2.25804 16.7591 2.44525 17.6968 3.35267C18.6345 4.2601 19.1613 5.49083 19.1613 6.77412H21.4951C21.4951 4.97751 20.7576 3.25449 19.4448 1.98409C18.132 0.7137 16.3516 0 14.4951 0C12.6385 0 10.8581 0.7137 9.54531 1.98409C8.23255 3.25449 7.49506 4.97751 7.49506 6.77412H9.82796C9.82796 5.49083 10.3547 4.2601 11.2924 3.35267ZM0.494629 26.1291V6.77443H28.4946V26.1291C28.4946 27.1557 28.0732 28.1403 27.3231 28.8662C26.5729 29.5922 25.5555 30 24.4946 30H4.49463C3.43376 30 2.41635 29.5922 1.6662 28.8662C0.916056 28.1403 0.494629 27.1557 0.494629 26.1291ZM26.1613 9.03217H2.828V25.9675C2.828 26.4665 3.03286 26.9452 3.39752 27.298C3.76217 27.6509 4.25675 27.8492 4.77245 27.8492H24.2169C24.7326 27.8492 25.2272 27.6509 25.5918 27.298C25.9565 26.9452 26.1613 26.4665 26.1613 25.9675V9.03217Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 29 30',
})

export default IconShoppingBag
