import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconFilter = createIcon({
  path: (
    <g>
      <path d="M16 3H0V5H16V3Z" />
      <path d="M0 10H16V12H0V10Z" />
      <circle cx="5" cy="11" r="3" />
      <circle cx="11" cy="4" r="3" />
    </g>
  ),
  viewBox: '0 0 16 15',
  defaultProps: { fill: 'none', height: 15 },
})

export default IconFilter
