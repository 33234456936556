import { gql } from '@apollo/client'
import publicCmsItemsFragment from 'graphql/fragments/publicCmsItemsFragment'

const fullCmsItemFragment = gql`
  fragment cmsItemPublicVideoRequest on PublicVideoRequest {
    shortId
    status
    fromName
    toName
    instructions
    isGift
    createdAt
    video {
      id
      thumbnailUrl(format: { height: 650 })
      url(preset: VIDEO_DEFAULT)
      downloadUrl: url(preset: VIDEO_DOWNLOAD)
      duration
    }
    publicProfile {
      id
      name
      slug
      title
      description
      averageReviewRating
    }
    requestOccasion {
      id
      internalName
      title
      emoji
      color
    }
    review {
      id
      messageToProfile
    }
    __typename
  }

  fragment fullCmsItemFragment on PublicCmsItem {
    __typename
    id
    type
    content # @todo - remove this and do not depend on unspecified json in webapp
    ...publicCmsItemsFragment

    ... on PublicCmsItemABTest {
      id
      type
      experimentId
      variants {
        id
        type
        content
        ...publicCmsItemsFragment
      }
      original {
        id
        type
        content
        ...publicCmsItemsFragment
      }
    }

    ... on PublicCmsItemMultiVariantTest {
      id
      type
      experimentId
      variants {
        variantId
        cmsItem {
          id
          type
          content
          ...publicCmsItemsFragment
        }
      }
      original {
        id
        type
        content
        ...publicCmsItemsFragment
      }
    }
  }

  ${publicCmsItemsFragment}
`

export default fullCmsItemFragment
