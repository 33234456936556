import { gql } from '@apollo/client'

import fullPublicBadge from './fullPublicBadgeFragment'
import fullPublicDiscount from './fullPublicDiscountFragment'

const fullPublicProfileProduct = gql`
  fragment fullPublicProfileProduct on PublicProfileProduct {
    id
    acceptingOrders
    showOnProfilePage
    price {
      ...amountFragment
    }
    product {
      ...productFragment
    }
    genericProductPublic {
      ...genericProductFragment
    }
    profile {
      id
      name
      slug
      title
      description
      profilePicture {
        id
        cardUrl: url(format: { width: 440, height: 814, fit: crop })
        meanBackgroundColorHex
      }
      profileVideo {
        id
        url(preset: VIDEO_DEFAULT)
      }
    }
    badges {
      ...fullPublicBadge
    }
    discount {
      ...fullPublicDiscount
    }

    productChildren {
      id
      acceptingOrders
      showOnProfilePage
      product {
        ...productFragment
      }
      price {
        ...amountFragment
      }
      priceWithParent {
        ...amountFragment
      }
      discount {
        ...fullPublicDiscount
      }
    }
  }

  fragment amountFragment on Amount {
    formattedValue
    currency
    valueInCent
    valueWithDecimals
  }

  fragment productFragment on PublicProduct {
    id
    name
    slug
    productType
    description
    expirationTime
    createdAt
    saleMode
  }

  fragment genericProductFragment on PublicGenericProduct {
    id
    schema
    uiSchema
  }

  ${fullPublicDiscount}
  ${fullPublicBadge}
`

export default fullPublicProfileProduct
