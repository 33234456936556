import { gql } from '@apollo/client'

const fullPublicDiscountFragment = gql`
  fragment fullPublicDiscount on PublicDiscount {
    id
    couponCode
    discountedPrice {
      currency
      formattedValue
      valueInCent
    }
    discountAmount {
      currency
      formattedValue
      valueInCent
    }
    discountedPriceWithParent {
      currency
      formattedValue
      valueInCent
    }
    discountAmountWithParent {
      currency
      formattedValue
      valueInCent
    }
    currency
    discountPercent
  }
`

export default fullPublicDiscountFragment
