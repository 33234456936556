import React from 'react'
import { useGeneralContext } from 'contexts/GeneralContext'
import { useMarketContext } from 'contexts/MarketContext'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'

import styles from './MarketSwitcher.module.css'

const MarketFlag = dynamic(() => import('components/MarketFlag/MarketFlag'), {
  ssr: false,
})

export default function MarketSwitcher({ showText = false }) {
  const router = useRouter()
  const { market, setUserChangesMarket } = useMarketContext()
  const { selectedMarket } = market
  const { setCloseToPath } = useGeneralContext()

  return (
    <Link href="/" prefetch={false}>
      <a
        onClick={() => {
          setUserChangesMarket(true)
          setCloseToPath(router.asPath)
        }}
        data-cy="switchMarket"
        className={styles.marketSwitcher}
        role="link"
        tabIndex={0}
      >
        <span className={styles.flag}>
          <MarketFlag market={selectedMarket} />
        </span>{' '}
        {showText && <span className={styles.name}>{selectedMarket.name}</span>}
      </a>
    </Link>
  )
}
