/* eslint-disable import/prefer-default-export */
import { logger } from 'lib/logger'
import config from 'lib/nextConfig'

import amplitudeDestination from './destinations/amplitudeDestination'
import gtmDestination from './destinations/gtmDestination'
import { AvoEnv } from './sdk'

const avoLogger = {
  logDebug: (env: AvoEnv, message: string): boolean => {
    if (env === AvoEnv.Dev) logger.debug(`[avo] ${message}`)
    return true
  },
  logWarn: (env: AvoEnv, message: string): boolean => {
    if (env === AvoEnv.Dev) logger.warn(`[avo] ${message}`)
    return true
  },
  logError: (env: AvoEnv, message: string): boolean => {
    if (env === AvoEnv.Dev) logger.error(`[avo] ${message}`)
    return true
  },
}

export async function initAvoSDK(): Promise<void> {
  const isLive = config.RELEASE_STAGE === 'live'
  const avoEnvironment = isLive ? AvoEnv.Prod : AvoEnv.Dev

  const { initAvo } = await import('./sdk')
  initAvo(
    // Avo options
    {
      env: avoEnvironment,
      webDebugger: false,
      noop: config.IS_CYPRESS_ENV,
      avoLogger,
    },
    // Avo SystemProperties (sent with all events)
    { platform: 'WebApp' },
    // Destination Options
    { client: 'WEB', version: '1.0' },
    amplitudeDestination,
    gtmDestination,
  )
  avoLogger.logDebug(avoEnvironment, 'initAvoSDK called')
}
