import React from 'react'
import cn from 'classnames'

import { Spinner, SpinnerColors } from '../Spinner/Spinner'
import { UIComponent } from '../types'

import styles from './Button.module.css'

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'inverted'
  | 'option'
  | 'black'
  | 'blue'
type ButtonSize = 'large' | 'small' | 'xsmall' | 'rounded' | 'tiny'

export interface ButtonProps extends UIComponent {
  onClick?: React.MouseEventHandler<HTMLElement>
  size?: ButtonSize
  type?: ButtonType
  disabled?: boolean
  loading?: boolean
  className?: string
  'data-cy'?: string
  htmlType?: 'submit' | 'button' | 'reset'
  selected?: boolean
  noMinWidth?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  size = 'large',
  type = 'primary',
  disabled = false,
  loading = false,
  className,
  'data-cy': dataCy,
  htmlType = 'button',
  selected = false,
  noMinWidth = false,
}) => {
  const spinnerColor: { [key in ButtonType]: SpinnerColors } = {
    primary: 'white',
    secondary: 'black',
    inverted: 'red',
    option: 'red',
    black: 'white',
    blue: 'white',
  }

  return (
    <button
      disabled={disabled}
      className={cn(styles.button, styles[size], styles[type], className, {
        [styles.selected]: selected,
        [styles.noMinWidth]: noMinWidth,
      })}
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      onClick={onClick}
      data-cy={dataCy}
    >
      <div className={styles.contentWrapper}>
        {loading && size !== 'rounded' && (
          <Spinner
            size={size === 'xsmall' ? 16 : 24}
            color={spinnerColor[type]}
          />
        )}
        <span className={styles.label}>{children}</span>
      </div>
    </button>
  )
}

export { Button as default, Button }
