import * as React from 'react'

import { createIcon } from 'components/UI/Icon/createIcon'

export const IconRingDiamond = createIcon({
  path: (
    <g>
      <path
        d="M9.375 0C9.6875 0 9.96094 0.195312 10.1172 0.429688L11.3672 2.30469C11.5625 2.61719 11.6016 2.96875 11.4062 3.28125L10.1953 5.50781C13.0078 6.60156 15 9.33594 15 12.5C15 16.6406 11.6406 20 7.5 20C3.32031 20 0 16.6406 0 12.5C0 9.33594 1.95312 6.60156 4.76562 5.50781L3.55469 3.28125C3.35938 2.96875 3.39844 2.61719 3.59375 2.30469L4.84375 0.429688C5 0.195312 5.27344 0 5.625 0H9.375ZM8.28125 5.07812L9.49219 2.89062L8.86719 1.875H6.09375L5.46875 2.89062L6.67969 5.07812C6.95312 5.03906 7.1875 5 7.5 5C7.77344 5 8.00781 5.03906 8.28125 5.07812ZM7.5 6.875C4.375 6.875 1.875 9.41406 1.875 12.5C1.875 15.625 4.375 18.125 7.5 18.125C10.5859 18.125 13.125 15.625 13.125 12.5C13.125 9.41406 10.5859 6.875 7.5 6.875Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 15 20',
  defaultProps: { fill: 'none', height: 16 },
})

export default IconRingDiamond
